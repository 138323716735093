/* eslint-disable */
const Logo = (props) => {
  const { size = 1, color = '#305FED' } = props
  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.40817 4.67102L3.40822 4.67107L10.7372 12.0001L3.40822 19.3291C3.23395 19.5034 3.14671 19.7323 3.14671 19.9604C3.14671 20.1886 3.23398 20.4174 3.40817 20.5917L3.40822 20.5918C3.75692 20.9405 4.32221 20.9405 4.67092 20.5918L11.9999 13.2628L19.3289 20.5918C19.6776 20.9404 20.2429 20.9404 20.5916 20.5917C20.9403 20.243 20.9402 19.6777 20.5916 19.329L13.2626 12.0001L20.5916 4.67107C20.9403 4.32237 20.9403 3.75708 20.5916 3.40838C20.2429 3.05967 19.6776 3.05967 19.3289 3.40838L11.9999 10.7374L4.67092 3.40838C4.32221 3.05967 3.75692 3.05967 3.40822 3.40838C3.23395 3.58265 3.14671 3.81163 3.14671 4.03972C3.14671 4.26786 3.23398 4.49673 3.40817 4.67102Z"
        fill={color}
        stroke="#305FED"
        strokeWidth="0.5"
      />
    </svg>
  )
}

// eslint-enable
export default Logo
