import React from 'react'
import { Link as RLink } from 'react-router-dom'
import styled from 'styled-components'

const LinkToAll = (props) => {
  const { to } = props

  return (
    <RLink to={to}>
      <Item>ดูทั้งหมด</Item>
    </RLink>
  )
}

const Item = styled.span`
  color: white;
  background-color: ${(p) => p.theme.color.blue};
  border-radius: 12px;
  padding: 0px 6px;
  font-weight: 400;
  font-size: 14px;
`

export default LinkToAll
