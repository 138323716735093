import styled from 'styled-components'

const DropdownList = (props) => {
  const { menu = [], value, onChange = () => {} } = props

  const onSelected = (evt) => {
    onChange(evt.target.value)
  }

  const choice = menu.map((item) => {
    return (
      <option key={item.value} value={item.value}>
        {item.name}
      </option>
    )
  })

  return (
    <PageView>
      <select value={value} onChange={onSelected}>
        {choice}
      </select>
    </PageView>
  )
}

const PageView = styled.div`
  color: ${(p) => p.theme.color_level.grey.high};
  font-size: 16px;
  width: 100%;
  select {
    height: 30px;
    border-radius: 8px;
    width: 100%;
    background-color: white;
    padding-left: 6px;
  }

  select > option {
    font-size: 16px;
    height: 30px;
    color: ${(p) => p.theme.color_level.grey.high};
    background-color: white;
  }
`

export default DropdownList
