import styled from 'styled-components'

import { PrimaryBtn, Btn } from 'components/button'

const Button = (props) => {
  const { loading, disabled, onClick, onGoto } = props

  const btn = onGoto ? (
    <Line>
      <Btn text="กลับไปที่รายการจอง" onClick={onGoto} />
    </Line>
  ) : undefined
  return (
    <ButtonSection>
      <Center>
        <PrimaryBtn
          loading={loading}
          disabled={disabled}
          text="เพิ่มร้านค้า"
          onClick={onClick}
        />

        {btn}
      </Center>
    </ButtonSection>
  )
}

const ButtonSection = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0px;
  left: 0px;
  background-color: ${(p) => p.theme.color.backgroundColor};
  padding: 16px 0px;
  z-index: 2;
`

const Center = styled.div`
  width: 100%;
  max-width: 213px;
  margin: 0 auto;
`

const Line = styled.div`
  padding-top: 16px;
`

export default Button
