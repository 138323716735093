import styled from 'styled-components'
import { Row } from 'antd'

import { Col } from 'components/display'

const Header = (props) => {
  const { doc = {}, available = 0 } = props

  const { name } = doc

  return (
    <PageView>
      <Row align="middle">
        <Col lg={18} md={16} xs={12}>
          <Name>{name}</Name>
        </Col>
        <Col lg={6} md={8} xs={12}>
          <Text>
            ล็อกว่าง
            <Counter>{available}</Counter>
          </Text>
        </Col>
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Name = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: ${(p) => p.theme.color.blue};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Text = styled.div`
  font-size: 18px;
  color: ${(p) => p.theme.color_level.grey.high};
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const Counter = styled.span`
  color: white;
  background-color: ${(p) => p.theme.color.green};
  font-size: 14px;
  border-radius: 12px;
  margin-left: 10px;
  width: 32px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default Header
