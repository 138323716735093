import styled from 'styled-components'
import { Row, Col } from 'antd'

import { DropdownList } from 'components/input'

const MarketHeader = (props) => {
  const { title, placeholder, selected, select_list = [], onChange } = props

  const isFilter = select_list.length !== 0
  const selector = isFilter ? (
    <Col lg={6} md={10} xs={12}>
      <DropdownList
        placeholder={placeholder}
        menu={select_list}
        value={selected}
        clear={false}
        onChange={onChange}
      />
    </Col>
  ) : undefined

  const col = isFilter ? { lg: 18, md: 14, xs: 12 } : { lg: 24, md: 24, xs: 24 }

  return (
    <PageView>
      <Row align="middle">
        <Col {...col}>
          <Title>{title}</Title>
        </Col>

        {selector}
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  margin-top: 60px;
`

const Title = styled.div`
  text-align: left;
  font-size: 24px;
  font-weight: bold;
  color: ${(p) => p.theme.color.blue};
`

export default MarketHeader
