import styled from 'styled-components'
import { Row, Col } from 'antd'

import { Card } from 'components/display'
import { Checker } from 'components/input'
import { format, product } from 'utils'

const Product = (props) => {
  const { item = {}, isCheck, index, onChange } = props
  const { start_at, finish_at, total_price, deposit, deposit_type } = item

  const getText = (label, text) => {
    return (
      <Text>
        <span>{label}</span>
        {text}
      </Text>
    )
  }

  const onCheck = (val) => onChange(val, index, item)
  const getDeposit = () => {
    if (deposit_type !== 'yes') return

    return (
      <>
        {getText('ค่าเช่า:', `${format.toDigi(total_price)} บาท`)}
        {getText('มัดจำ/ประกัน:', `${format.toDigi(deposit)} บาท`)}
      </>
    )
  }

  const bookingType = tag[item.booking_type] || tag.daily
  const date = product.getDate(start_at, finish_at)
  return (
    <PageView>
      <Card>
        <Body>
          <Row>
            <Col span={16}>
              <Info>
                <NameLine>
                  <Name>{item.name}</Name>
                  <Round style={{ backgroundColor: bookingType.color }}>
                    {bookingType.text}
                  </Round>
                </NameLine>
                {getText('วันที่ขาย:', date)}
                {getText('รอบ:', item.round)}
                {getDeposit()}
                {getText('โซน:', item.detail)}
              </Info>
            </Col>

            <Col span={8}>
              <Price>
                {format.toDigi(total_price + deposit)}
                <span>บาท</span>
              </Price>
              <Selected>
                <Checker label="เลือก" value={isCheck} onChange={onCheck} />
              </Selected>
            </Col>
          </Row>
        </Body>
      </Card>
    </PageView>
  )
}

const tag = {
  daily: { text: 'รายวัน', color: '#F55759' },
  monthly: { text: 'รายเดือน', color: '#577AF5' },
  round: { text: 'บูธอีเวนท์', color: '#00C767' },
}

const PageView = styled.div`
  width: 100%;
  margin-bottom: 16px;
`

const NameLine = styled.div`
  margin-bottom: 8px;
  display: flex;
  column-gap: 8px;
`

const Round = styled.div`
  font-size: 14px;
  color: white;
  background-color: #f55759;
  border-radius: 4px;
  padding: 2px 8px;
  height: fit-content;
`

const Price = styled.div`
  color: ${(p) => p.theme.color.red};
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: right;
  height: 25px;

  span {
    font-size: 14px;
    font-weight: 400;
    margin-left: 6px;
  }
`

const Body = styled.div``

const Name = styled.div`
  font-size: 16px;
  width: fit-content;
  color: white;
  background-color: ${(p) => p.theme.color.green};
  border-radius: 4px;
  font-weight: bold;
  padding: 0px 8px;
`

const Info = styled.div`
  width: 100%;
`

const Text = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.color_level.grey.high};

  span {
    font-weight: bold;
    margin-right: 8px;
  }
`

const Selected = styled.div`
  display: flex;
  align-items: end;
  justify-content: end;
  height: calc(100% - 25px);
`

export default Product
