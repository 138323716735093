const config = {
  mode: 'localhost',
  firebase: {
    apiKey: 'AIzaSyBu_lo_DhJ9T5_sjnUaTb45TtHLWyOufdk',
    authDomain: 'marget-dev.firebaseapp.com',
    projectId: 'marget-dev',
    storageBucket: 'marget-dev.appspot.com',
    messagingSenderId: '215000958445',
    appId: '1:215000958445:web:86e9849724069674ee53e4',
    measurementId: 'G-XEN8S0488B',
  },
  market_url: 'http://localhost:2240',
  line: {
    liffId: '1657001566-95jALZjO',
  },
  limit: {
    file: 1600000,
  },
  account: {
    logo_url:
      'https://hlo-drive.sgp1.digitaloceanspaces.com/marget/web/Krungsri%20Logo.png',
    name: 'นายธนโชติ เกียรติบรรจง',
    no: '0019627789',
    img_url: undefined,
  },
  img_url: 'https://hlo-drive.sgp1.digitaloceanspaces.com/marget/web/Frame.svg',
  api: 'http://localhost:3000/api',
  // api: 'https://api-test.marget.app/api',
  version: '0.1',
}

module.exports = config
