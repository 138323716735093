import styled from 'styled-components'

const MarketType = (props) => {
  const { item = {} } = props
  const { market_type_list = [] } = item

  const type_list = market_type_list.map((it, i) => {
    return <ImgType key={i} src={it.img_url} />
  })

  return <TypeList>{type_list}</TypeList>
}

const TypeList = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const ImgType = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 10px;
`

export default MarketType
