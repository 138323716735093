import styled from 'styled-components'

import { format } from 'utils'

const Price = (props) => {
  const { price, label, unit = '' } = props

  const text = label ? <Label>{label}</Label> : undefined
  return (
    <PageView>
      {text}
      <PriceText>
        {format.toDigi(price)}
        <span>{unit}</span>
      </PriceText>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const PriceText = styled.div`
  font-size: 24px;
  color: ${(p) => p.theme.color.red};
  line-height: 26px;

  span {
    font-size: 16px;
    margin-left: 6px;
  }
`

const Label = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.color_level.grey.high};
  line-height: 18px;
`

export default Price
