import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { bank_list } from 'definition'
import { ErrorDialog } from 'dialog'
import Button from 'pages/account/Button'
import { file } from 'utils'
import { config } from 'config'

import Header from './Header'
import Bank from './Bank'

const { account } = config

const OrderPayment = (props) => {
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState('')

  const { offer = {}, onBack = () => {} } = props

  const { sale_setting } = props.market_setting.toJS()
  const isOwnerBank = sale_setting.bank_type === 'owner'
  console.log('sale_setting:', sale_setting)
  const { order } = props.my_order.toJS()
  const payment_url = order.payment_url || ''
  const disabled = payment_url === ''

  const onSelect = async (value) => {
    const { text } = await file.compress({ value, limit: config.limit.file })
    order.payment_url = text
    props.my_order.setOrder(order)
  }

  const onCloseError = () => setErr('')
  const onSave = async () => {
    try {
      setLoading(true)
      const { status } = order
      if (status === 'offer') {
        await props.my_order.updateOfferPayment(offer)
      } else {
        await props.my_order.updatePayment()
      }

      onBack()
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }

  let logo_url = account.logo_url
  let account_name = account.name
  let account_number = account.no
  let bank_name
  let pattern = 'xxx-x-xxxxx-x'
  if (isOwnerBank) {
    const { market_bank } = props.market.toJS()
    const bank = bank_list.find((it) => it.value === market_bank.bank_code)

    logo_url = undefined
    bank_name = bank ? bank.name : undefined
    pattern = bank ? bank.pattern : 'xxx-x-xxxxx-x'
    account_name = market_bank.account_name
    account_number = market_bank.account_no
  }

  return (
    <PageView>
      <Header order={order} total={offer.total} />

      <Bank
        logo_url={logo_url}
        bank_name={bank_name}
        account_name={account_name}
        account_number={account_number}
        pattern={pattern}
        order={order}
        onSelect={onSelect}
      />

      <Button
        loading={loading}
        disabled={disabled}
        text="ยืนยันการชำระเงิน"
        onClick={onSave}
      />
      <ErrorDialog error={err} onClose={onCloseError} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 16px;
`

export default inject(
  'my_order',
  'market',
  'market_setting'
)(observer(OrderPayment))
