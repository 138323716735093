import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { BaseDrawer } from 'drawer'

import ShopList from './ShopList'
// import ProductType from './ProductType'

const ProductMenu = (props) => {
  const { visible, value, onSelect, onClose } = props

  const onClear = () => onSelect({})
  const { shop_list } = props.shop.toJS()
  const noShop = shop_list.length === 0
  const shop = noShop ? undefined : (
    <ShopList list={shop_list} value={value} onSelect={onSelect} />
  )
  const height = noShop ? '300px' : '80%'

  return (
    <BaseDrawer
      visible={visible}
      height={height}
      title="คุณต้องการขายสินค้าอะไร"
      onClose={onClose}
    >
      <PageView>
        {shop}
        <Button onClick={onClear}>ยังไม่ระบุตอนนี้</Button>
      </PageView>
    </BaseDrawer>
  )
}

const PageView = styled.div`
  width: 100%;
  background: #ffffff;
  padding: 16px 0px;
`

const Button = styled.div`
  width: 100%;
  text-align: center;
  cursor: pointer;
  margin-top: 24px;
  text-decoration: underline;
`

export default inject('shop')(observer(ProductMenu))
