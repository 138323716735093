import styled from 'styled-components'

const AreaItem = (props) => {
  const { item = {} } = props
  const { name, img_url } = item
  const backgroundImage = img_url === '' ? undefined : `url(${img_url})`

  return (
    <Card style={{ backgroundImage }}>
      <Name>{name}</Name>
    </Card>
  )
}

const Card = styled.div`
  width: 140px;
  height: 140px;
  font-weight: 200;
  font-size: 16px;

  border-radius: 12px;

  color: white;
  background-color: grey;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Name = styled.div`
  padding: 0px 4px;
  text-shadow: 1px 0px 1px ${(p) => p.theme.color_level.grey.medium};
`

export default AreaItem
