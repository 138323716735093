import styled from 'styled-components'

import { DropdownList } from 'components/labelInput'

const Filter = (props) => {
  const {
    filter = {},
    bookingType,
    bookingMenu,
    roundMenu,
    onBookingType,
    onFilter = () => {},
    disabled,
  } = props

  const onRoundType = (val, name, tag) => {
    filter.round = val
    filter.round_start_at = tag.start_at
    filter.round_finish_at = tag.finish_at
    onFilter(filter)
  }

  return (
    <PageView>
      <DropdownList
        placeholder="ประเภทการจอง"
        value={bookingType}
        menu={bookingMenu}
        onChange={onBookingType}
        clear={false}
        disabled={disabled}
      />
      <Space />

      <DropdownList
        placeholder="รอบขาย"
        value={filter.round}
        menu={roundMenu}
        onChange={onRoundType}
        clear={false}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Space = styled.div`
  width: 100%;
  padding-top: 16px;
`

export default Filter
