import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  name: '',
}

let state
export class Product extends BaseStore {
  constructor() {
    super()
    this.observable({
      display: {
        booking_type: '',
        unit: undefined,
        available_total: 0,
        list: [],
      },
      unit_source: {
        daily: {
          available: 0,
          list: [],
        },
        monthly: {
          available: 0,
          list: [],
        },
        round: {
          available: 0,
          list: [],
        },
      },
      unit: {
        params: undefined,
        list: [],
      },

      floorplan: {
        market_id: undefined,
        selected: cloneDeep(original),
        list: [],
      },
    })
    state = this
  }

  async getFloorplanList({ market_id, floorplan_id }) {
    const floorplan = this.toJS().floorplan
    let floorplan_list = []
    if (floorplan.market_id === market_id) {
      floorplan_list = floorplan.list
    } else {
      const url = `${config.api}/v1/public/market/floorplan/${market_id}`
      const resp = await http.get(url)

      const data = resp.body || {}
      floorplan_list = data.floorplan_list || []
    }

    const id = +floorplan_id || undefined
    const i = floorplan_list.findIndex((it) => it.floorplan_id === id)

    let doc = {}
    if (i > -1) {
      doc = floorplan_list[i]
    } else if (floorplan_list.length > 0) {
      doc = floorplan_list[0]
    }

    runInAction(() => {
      state.floorplan_list = floorplan_list
      state.floorplan = {
        market_id,
        selected: doc,
        list: floorplan_list,
      }
    })
  }

  setFloorplan(data) {
    runInAction(() => {
      state.floorplan.selected = data
    })
  }

  cloneUnit(unit = {}, product_list = [], selected) {
    const len = product_list.length
    if (len === 0) return undefined

    const newItem = { ...unit }
    const i = product_list.findIndex((it) => it.status === 'active')
    const isActive = i !== -1
    newItem.color = isActive ? color.available : color.unavailable
    newItem.status = isActive ? 'active' : 'inactive'

    newItem.product_list = product_list.sort((a, b) => {
      if (a.status === b.status) return 0
      if (a.status !== 'active') return 1
      return -1
    })

    return newItem
  }

  manageUnit(it = {}) {
    it.x = it.axis_x
    it.y = it.axis_y

    const daily_list = []
    const monthly_list = []
    const round_list = []
    const product_list = it.product_list || []
    for (const item of product_list) {
      switch (item.booking_type) {
        case 'daily':
          daily_list.push(item)
          break
        case 'monthly':
          monthly_list.push(item)
          break
        default:
          round_list.push(item)
      }
    }

    const daily = this.cloneUnit(it, daily_list)
    const monthly = this.cloneUnit(it, monthly_list)
    const round = this.cloneUnit(it, round_list)

    return { daily, monthly, round }
  }

  filterUnitList(unit_list = []) {
    const daily_list = []
    const monthly_list = []
    const round_list = []

    let available_daily = 0
    let available_monthly = 0
    let available_round = 0
    for (const it of unit_list) {
      const product_list = it.product_list || []
      const { daily, monthly, round } = this.manageUnit(it, product_list)
      if (daily) {
        daily_list.push(daily)
        if (daily.status === 'active') available_daily++
      }

      if (monthly) {
        monthly_list.push(monthly)
        if (monthly.status === 'active') available_monthly++
      }

      if (round) {
        round_list.push(round)
        if (round.status === 'active') available_round++
      }
    }

    return {
      daily: {
        available: available_daily,
        list: daily_list,
      },
      monthly: {
        available: available_monthly,
        list: monthly_list,
      },
      round: {
        available: available_round,
        list: round_list,
      },
    }
  }

  setBookingType(booking_type) {
    const { display, unit_source } = this.toJS()
    const { daily, monthly, round } = unit_source
    const selected = this.getDisplayList(booking_type, {
      daily,
      monthly,
      round,
    })

    const { unit } = display
    let data
    if (unit) {
      const { unit_id } = unit
      const i = selected.list.findIndex((it) => it.unit_id === unit_id)
      data = i === -1 ? undefined : selected.list[i]
    }

    runInAction(() => {
      state.display = {
        booking_type,
        unit: data,
        available: selected.available,
        list: selected.list,
      }
    })
  }

  setDisplay(booking_type, data) {
    const { unit_source } = this.toJS()
    const { daily, monthly, round } = unit_source
    const selected = this.getDisplayList(booking_type, {
      daily,
      monthly,
      round,
    })

    runInAction(() => {
      state.display = {
        booking_type,
        unit: data,
        available: selected.available,
        list: selected.list,
      }
    })
  }

  async getUnitList(query = {}) {
    const { market_id, floorplan_id, start_at, finish_at } = query
    const { unit } = this.toJS()

    const t1 = 'DDMMYYYY'
    const start = start_at ? start_at.format(t1) : ''
    const finish = finish_at ? finish_at.format(t1) : ''
    const params = `${market_id}/${floorplan_id}/floorplan/${start}/${finish}`

    query.params = params
    if (unit.params === params) {
      return
    }

    this.setUnitList(query, [])

    if (!floorplan_id || start === '' || finish === '') {
      return
    }

    const url = `${config.api}/v1/public/market/unit/${params}`
    const res = await http.get(url)

    const { unit_list } = res.body || {}
    this.setUnitList(query, unit_list)
  }

  getDisplayList(booking_type, { daily, monthly, round }) {
    switch (booking_type) {
      case 'daily':
        return daily
      case 'monthly':
        return monthly
      default:
        return round
    }
  }

  setUnitList({ params }, list) {
    const { display } = this.toJS()
    const { booking_type } = display
    const { daily, monthly, round } = this.filterUnitList(list)
    const selected = this.getDisplayList(booking_type, {
      daily,
      monthly,
      round,
    })

    runInAction(() => {
      state.display = {
        booking_type,
        unit: undefined,
        available: selected.available,
        list: selected.list,
      }
      state.unit_source = {
        daily,
        monthly,
        round,
      }
      state.unit = {
        params,
        list: list || [],
      }
    })
  }
}

const color = {
  selected: '#305FED',
  available: '#00C767',
  unavailable: '#8A8A8A',
}

export default new Product()
