/* eslint-disable */

const Logo = (props) => {
  const { size = 1, color = '#494949' } = props

  const width = `${Math.ceil(20 * size)}`
  const height = `${Math.ceil(24 * size)}`
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.81818 13.0909C5.60074 13.0909 2.18182 16.5098 2.18182 20.7273V22.9091C2.18182 23.5116 1.6934 24 1.09091 24C0.488422 24 0 23.5116 0 22.9091V20.7273C0 15.3048 4.39575 10.9091 9.81818 10.9091C15.2407 10.9091 19.6364 15.3048 19.6364 20.7273V22.9091C19.6364 23.5116 19.148 24 18.5455 24C17.9429 24 17.4545 23.5116 17.4545 22.9091V20.7273C17.4545 16.5098 14.0356 13.0909 9.81818 13.0909Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.81819 10.9091C12.2281 10.9091 14.1818 8.95543 14.1818 6.54545C14.1818 4.13548 12.2281 2.18182 9.81819 2.18182C7.40822 2.18182 5.45455 4.13548 5.45455 6.54545C5.45455 8.95543 7.40822 10.9091 9.81819 10.9091ZM9.81819 13.0909C13.4331 13.0909 16.3636 10.1604 16.3636 6.54545C16.3636 2.9305 13.4331 0 9.81819 0C6.20323 0 3.27274 2.9305 3.27274 6.54545C3.27274 10.1604 6.20323 13.0909 9.81819 13.0909Z"
        fill={color}
      />
    </svg>
  )
}
// eslint-enable
export default Logo
