import Joi from 'joi'

import { validator } from 'utils'

export const getRule = (isReceive) => {
  const tax_id = validator.getRuleString(
    'เลขบัตรประชาชน / เลขประจำตัวผู้เสียภาษี'
  )
  const tax_name = validator.getRuleString('ชื่อบริษัท / ชื่อบุคคล')
  const company_mobile = validator.getRuleString('เบอร์โทรศัพท์')
  const tax_address = validator.getRuleString('ที่อยู่ในการออกใบกำกับภาษี')

  const receive_name = validator.getRuleString('ชื่อผู้รับ')
  const receive_mobile = validator.getRuleString('เบอร์ติดต่อ')
  const receive_address = validator.getRuleString('ที่อยู่จัดส่งใบกำกับภาษี')

  const allowUnknown = true
  const rule = isReceive
    ? Joi.object({
        tax_id,
        tax_name,
        company_mobile,
        tax_address,
        receive_name,
        receive_mobile,
        receive_address,
      }).options({ allowUnknown })
    : Joi.object({
        tax_id,
        tax_name,
        company_mobile,
        tax_address,
      }).options({ allowUnknown })

  return rule
}
