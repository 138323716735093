import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { BaseDrawer } from 'drawer'

import ShopList from './ShopList'

const ProductMenu = (props) => {
  const { visible, onSelect, onClose } = props

  const { shop, order } = props.order.toJS()
  const { shop_list } = props.shop.toJS()

  return (
    <BaseDrawer
      visible={visible}
      height="80%"
      title="เลือกร้านค้าของคุณ"
      onClose={onClose}
    >
      <PageView>
        <ShopList
          order={order}
          list={shop_list}
          value={shop.shop_id}
          onSelect={onSelect}
        />
      </PageView>
    </BaseDrawer>
  )
}

const PageView = styled.div`
  width: 100%;
  background: #ffffff;
  padding: 16px 0px;
`

export default inject('shop', 'order')(observer(ProductMenu))
