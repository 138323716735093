import styled from 'styled-components'

import { DropdownList } from 'components/labelInput'
import { DatePicker } from 'components/date'
import { timer, format } from 'utils'

const Filter = (props) => {
  const {
    filter = {},
    bookingType,
    bookingMenu,
    disabled,
    onBookingType,
    onFilter = () => {},
  } = props

  const onDate = (val) => {
    filter.start_at = val
    filter.finish_at = timer.getFinishMonth(val)

    onFilter(filter)
  }

  const onRenderMonth = (value) => {
    const t1 = 'MM/DD/YYYY'
    const start = timer.get(value, t1)
    const finish = timer.getFinishMonth(value, t1)
    const s1 = format.toThaiDate(start)
    const s2 = format.toThaiDate(finish)
    return `${s1} - ${s2}`
  }

  const { start_at, limit = {} } = filter
  return (
    <PageView>
      <DropdownList
        placeholder="ประเภทการจอง"
        value={bookingType}
        menu={bookingMenu}
        onChange={onBookingType}
        clear={false}
        disabled={disabled}
      />

      <Space />
      <DatePicker
        placeholder="วันที่เปิดขาย"
        value={start_at}
        onChange={onDate}
        onRender={onRenderMonth}
        disabled={start_at === undefined}
        min={limit.min}
        max={limit.max}
        days_list={limit.days_list}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Space = styled.div`
  width: 100%;
  padding-top: 16px;
`

export default Filter
