import Joi from 'joi'

import { validator } from 'utils'

const user = Joi.object({
  name: validator.getRuleString('ชื่อ'),
  surname: validator.getRuleString('นานสกุล'),
  gender: Joi.valid('male', 'female')
    .messages({ 'any.only': `กรุณาเลือกเพศให้ถูกต้อง` })
    .label('เพศ'),
  citizen_id: validator.getRuleSizeString('เลขบัตรประชาชน', 13, 14),
  mobile: validator.getRuleSizeString('เบอร์มือถือ', 10, 11),
  //birthday_at: validator.getRuleDate('วันเกิด'),
  //province_name: validator.getRuleString('จังหวัดที่อาศัย'),
}).options({ allowUnknown: true })

export default user
