import { runInAction } from 'mobx'

import { http, timer } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

let state
export class Promotion extends BaseStore {
  constructor() {
    super()
    this.observable({
      promotion: {
        market_id: undefined,
        list: [],
      },
    })
    state = this
  }

  async getPromotionList({ market_id }) {
    const pro = this.toJS()

    if (pro.market_id === market_id) {
      return
    }

    const url = `${config.api}/v1/public/market/promotion/${market_id}/market`
    const resp = await http.get(url)
    const promotion_list = resp.body || []

    const list = promotion_list.map((it) => {
      it.start_at = timer.get(it.start_at)
      it.finish_at = timer.get(it.finish_at)

      return it
    })

    runInAction(() => {
      state.promotion = {
        market_id,
        list,
      }
    })
  }
}

export default new Promotion()
