import styled from 'styled-components'

import Loading from 'components/loading'

const Content = (props) => {
  const { children, loading, isHeader, background } = props
  const css = {}

  if (isHeader) {
    css.paddingTop = '65px'
  }

  if (background) {
    css.backgroundColor = background
  }
  return (
    <PageView style={css}>
      <Loading loading={loading}>{children}</Loading>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  max-width: ${(p) => p.theme.max};
  margin: 0 auto;
`

export default Content
