import styled from 'styled-components'

const Carousel = (props) => {
  const { children } = props
  return <ContentView>{children}</ContentView>
}

const ContentView = styled.div`
  width: 100%;
  padding: 12px 24px 24px 24px;
  display: flex;
  overflow: auto;
  background-color: transparent;
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`

export default Carousel
