/* eslint-disable */

const Logo = (props) => {
  const { size = 1, color = '#305FED' } = props

  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill={color} />
      <path
        d="M17.8805 6.03125L9.72827 13.5941L6.28064 9.91492L4 12.052L9.56982 17.996L20 8.32874L17.8805 6.03125Z"
        fill="white"
      />
    </svg>
  )
}

/* eslint-enable */
export default Logo
