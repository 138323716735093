import styled from 'styled-components'
import { Row, Col } from 'antd'

import { format } from 'utils'

const Total = (props) => {
  const { total } = props

  return (
    <PageView>
      <Row align="middle">
        <Col span={12}>
          <Text>ราคารวมทั้งสิ้น</Text>
        </Col>
        <Col span={12}>
          <Info>
            <Price>
              {format.toDigi(total)}
              <span>บาท</span>
            </Price>
          </Info>
        </Col>
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  margin: 14px 0px;
`

const Text = styled.div`
  width: 100%;
  font-size: 18px;
  color: ${(p) => p.theme.color_level.grey.high};
`

const Info = styled.div`
  width: 100%;
  font-size: 14px;
  color: ${(p) => p.theme.color_level.grey.high};
`

const Price = styled.div`
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  text-align: right;
  color: ${(p) => p.theme.color.red};

  span {
    margin-left: 4px;
    font-size: 14px;
    font-weight: 400;
  }
`

export default Total
