import styled from 'styled-components'

import { Next } from 'icon'

const SelectShopBtn = (props) => {
  const { inactive, onClick } = props

  const css = inactive ? 'inactive' : undefined
  const click = inactive ? undefined : onClick
  return (
    <PageView className={css} onClick={click}>
      <Info>เลือกร้านค้า</Info>
      <Next />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  color: ${(p) => p.theme.color_level.grey.high};
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: center;
  column-gap: 16px;
  cursor: pointer;

  &.inactive {
    background-color: ${(p) => p.theme.color.disable};
    cursor: default;
  }
`

const Info = styled.div`
  flex: auto;
`

export default SelectShopBtn
