import styled from 'styled-components'

import Shop from './Shop'

const ShopList = (props) => {
  const { order, list = [], value, onSelect } = props

  const content = list.map((it, i) => {
    return (
      <Shop key={i} order={order} item={it} value={value} onSelect={onSelect} />
    )
  })

  return <PageView>{content}</PageView>
}

const PageView = styled.div`
  width: 100%;
  margin-bottom: 24px;
`

export default ShopList
