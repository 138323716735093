import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useParams, useNavigate } from 'react-router-dom'

import Loading from 'components/loading'
import { ErrorDialog, ConfirmDialog } from 'dialog'
import { BackHeader, Content, Row, Col } from 'components/display'
import { PrimaryBtn, Btn } from 'components/button'

import ShopEditor from '../editor'
import Information from './Information'

const Profile = (props) => {
  const [loading, setLoading] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [err, setErr] = useState('')
  const [edit, setEdit] = useState(undefined)
  const { shop_id } = useParams()
  const navigate = useNavigate()

  const { doc = {} } = props.shop.toJS()

  const onLoad = useCallback(
    async (id) => {
      try {
        setLoading(true)
        await props.shop.getShopInfo(id)
      } catch (e) {
        setErr(e.message)
      }
      setLoading(false)
    },
    [props.shop]
  )

  useEffect(() => {
    onLoad(shop_id)
  }, [onLoad, shop_id])

  const onCloseError = () => setErr('')
  const onBack = () => navigate('/account/shop')
  const onClose = () => setEdit(undefined)
  const onEdit = () => setEdit({ ...doc })

  const onChange = (val) => setEdit({ ...val })

  const onSave = async () => {
    try {
      setProcessing(true)
      await props.shop.updateShop(doc.shop_id, edit)
      await props.order.updateShop(doc.shop_id, edit)
      onClose()
    } catch (e) {
      setErr(e.message)
    }
    setProcessing(false)
  }

  const onConfirmClose = () => setConfirm(false)
  const onDelete = async () => {
    try {
      onConfirmClose()
      setProcessing(true)
      await props.shop.deleteShop({ shop_id })
      await props.order.deleteShop({ shop_id })

      setProcessing(false)
      onBack()
    } catch (e) {
      setErr(e.message)
      setProcessing(false)
    }
  }

  const img_list = doc.img_list || []
  const content = img_list.map((it, i) => {
    const position = i % 2 === 0 ? 'left' : 'right'
    return (
      <Col lg={12} md={12} xs={24} key={i}>
        <ProductImg className={position} src={it} />
      </Col>
    )
  })
  return (
    <PageView>
      <BackHeader onClick={onBack} color="white" isShort={true} />
      <Content isHeader={true}>
        <Loading loading={loading}>
          <ContentView>
            <Information doc={doc} />
          </ContentView>

          <Row>{content}</Row>

          <Bottom>
            <Button>
              <PrimaryBtn loading={processing} text="แก้ไข" onClick={onEdit} />
              <Btn
                loading={processing}
                text="ลบร้านค้า"
                onClick={() => setConfirm(true)}
              />
            </Button>
          </Bottom>
        </Loading>
      </Content>
      <ShopEditor
        title="แก้ไขร้านค้า"
        visible={edit !== undefined}
        loading={processing}
        doc={edit}
        onChange={onChange}
        onClose={onClose}
        onSave={onSave}
      />
      <ConfirmDialog
        visible={confirm}
        title="ยืนยันการลบร้านค้า"
        message="คุณต้องการที่จะลบร้านค้า ใช่หรือไม่?"
        onClose={onConfirmClose}
        onConfirm={onDelete}
      />
      <ErrorDialog error={err} onClose={onCloseError} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  max-width: ${(p) => p.theme.max};
  min-height: 100vh;
  margin: 0 auto;
  background-color: white;
  padding-bottom: 140px;
`

const ContentView = styled.div`
  width: 100%;
  padding-bottom: 24px;
`

const ProductImg = styled.img`
  width: 100%;
  background-color: #f5f6f9;
  border-radius: 4px;

  margin-bottom: 16px;

  &.left {
    margin-right: 16px;
  }

  &.right {
    margin-left: 16px;
  }

  ${(p) => p.theme.media.mobile} {
    &.left {
      margin-right: 0px;
    }

    &.right {
      margin-left: 0px;
    }
  }
`

const Bottom = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0px;
  left: 0px;
  padding: 16px 0px;
  background-color: white;
`

const Button = styled.div`
  width: 214px;
  margin: 0 auto;

  button {
    margin: 6px 0px;
  }
`

export default inject('shop', 'order')(observer(Profile))
