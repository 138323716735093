import styled from 'styled-components'

import { Link } from 'components/link'

const Name = (props) => {
  const { item = {}, link } = props
  const { name } = item

  return (
    <MarketName>
      <Link to={link}>{name}</Link>
    </MarketName>
  )
}

const MarketName = styled.div`
  font-size: 24px;
  color: ${(p) => p.theme.color_level.grey.high};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export default Name
