import styled from 'styled-components'

import FindMarket from 'components/FindMarket'

const HomeFindMarket = (props) => {
  return (
    <PageView>
      <FindMarket />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 30px 24px;
`

export default HomeFindMarket
