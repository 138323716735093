import styled from 'styled-components'

const CloseBooking = (props) => {
  const { children, message } = props
  if (!message) {
    return children
  }

  return <PageView>{message}</PageView>
}

const PageView = styled.div`
  width: 100%;
  font-size: 26px;
  text-align: center;
  padding-top: 60px;
  color: ${(p) => p.theme.color_level.grey.low};
`

export default CloseBooking
