import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { ConfirmDialog, ErrorDialog } from 'dialog'
import { BackHeader, Layout } from 'components/display'
import OrderInfo from 'pages/account/booking/info'
import OrderPayment from 'pages/account/booking/payment'
import OfferDrawer from 'pages/account/booking//offer'

const OrderPage = (props) => {
  const [loading, setLoading] = useState(false)
  const [cancelling, setCancelling] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [visibleOffer, setVisibleOffer] = useState(false)
  const [err, setErr] = useState('')
  const [page, setPage] = useState('info')
  const [offer, setOffer] = useState({})

  const { code, payment } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const onLoad = useCallback(
    async (c) => {
      try {
        setLoading(true)
        const booking = await props.my_order.getOrderInfo(c)
        const { status, market_id } = booking || {}

        if (status === 'payment') {
          await Promise.all([
            props.market.getMarketBank(market_id),
            props.market_setting.getSaleSetting(market_id),
          ])
        }
      } catch (e) {
        setErr(e.message)
      }
      setLoading(false)
    },
    [props.my_order]
  )

  useEffect(() => {
    onLoad(code)
  }, [onLoad, code])

  useEffect(() => {
    const val = payment ? 'payment' : 'info'
    setPage(val)
  }, [payment])

  const onCloseError = () => setErr('')
  const onBack = () => {
    if (page === 'info') {
      const { category } = queryString.parse(location.search)
      const q = category ? `?status=${category}` : ''
      navigate(`/account/booking${q}`)
    } else {
      setPage('info')
    }
  }

  const onNext = () => setPage('payment')
  const onConfirmCancel = () => setConfirm(true)
  const onCloseConfirm = () => setConfirm(false)
  const onCancel = async () => {
    try {
      setCancelling(true)
      onCloseConfirm()
      await props.my_order.cancelOrder()
    } catch (e) {
      setErr(e.message)
    }
    setCancelling(false)
  }

  const onOpenOffer = () => setVisibleOffer(true)
  const onCloseOffer = () => setVisibleOffer(false)
  const onSaveOffer = (selected) => {
    setPage('payment')
    onCloseOffer()
    setOffer(selected)
  }

  const content =
    page === 'info' ? (
      <OrderInfo
        cancelling={cancelling}
        onNext={onNext}
        onCancel={onConfirmCancel}
        onOffer={onOpenOffer}
      />
    ) : (
      <OrderPayment offer={offer} onBack={onBack} />
    )

  return (
    <PageView>
      <BackHeader onClick={onBack} title="รายละเอียดการจอง" />
      <Layout loading={loading} isHeader={true}>
        {content}
      </Layout>
      <ErrorDialog error={err} onClose={onCloseError} />
      <ConfirmDialog
        visible={confirm}
        title="ยกเลิกการจอง"
        message="ยืนยันการยกเลิกการจอง"
        onClose={onCloseConfirm}
        onConfirm={onCancel}
      />
      <OfferDrawer
        visible={visibleOffer}
        onClose={onCloseOffer}
        onSave={onSaveOffer}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding-bottom: 100px;
`

export default inject(
  'my_order',
  'market',
  'market_setting'
)(observer(OrderPage))
