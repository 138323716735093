import styled from 'styled-components'

const Tab = (props) => {
  const { menu = [], selected, onChange = () => {} } = props

  const content = menu.map((it, i) => {
    const isSelected = it.value === selected
    const className = isSelected ? 'selected' : undefined
    const click = isSelected ? undefined : () => onChange(it.value)
    return (
      <Btn key={i} className={className} onClick={click}>
        {it.label}
      </Btn>
    )
  })

  return <PageView>{content}</PageView>
}

const PageView = styled.div`
  display: flex;
  justify-content: space-between;
`

const Btn = styled.div`
  width: 100%;
  text-align: center;
  font-size: 14px;
  padding: 4px 0px 8px 0px;
  color: ${(p) => p.theme.color_level.grey.dark};
  cursor: pointer;

  &.selected {
    color: ${(p) => p.theme.color.blue};
    border: 0px solid ${(p) => p.theme.color.blue};
    border-bottom-width: 4px;
  }
`

export default Tab
