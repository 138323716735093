import styled from 'styled-components'

import { Next, Back } from 'icon'
import { format } from 'utils'

export const renderHeader = (props) => {
  const {
    date,
    // changeYear,
    // changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  } = props

  const txt = format.toThaiMonth(date)
  return (
    <HeaderView>
      <Icon onClick={decreaseMonth}>
        <Back
          size={size}
          color={prevMonthButtonDisabled ? color.inactive : color.active}
        />
      </Icon>
      <Title>{txt}</Title>
      <Icon onClick={increaseMonth}>
        <Next
          size={size}
          color={nextMonthButtonDisabled ? color.inactive : color.active}
        />
      </Icon>
    </HeaderView>
  )
}

const size = 0.8
const color = {
  active: '#494949',
  inactive: '#8A8A8A',
}

const HeaderView = styled.div`
  font-size: 16px;
  color: ${(p) => p.theme.color_level.grey.high};
  width: 100%;
  height: 44px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled.div`
  z-index: 1;
`

const Icon = styled.div`
  z-index: 1;
  display: flex;
  align-items: center;
`
