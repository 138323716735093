import styled from 'styled-components'
import { Row, Col } from 'antd'

import { format } from 'utils'
import { Dot } from 'components/display'

const Header = (props) => {
  const { order = {} } = props
  const { code, market_name, name } = order
  const getText = (label, text) => {
    return (
      <div>
        <Label>{label}</Label>
        <Text>{text}</Text>
      </div>
    )
  }

  const shop_info = order.shop_info || {}
  const mobile = format.toMobile(order.mobile)

  return (
    <PageView>
      <Row>
        <Col span={12}>{getText('ตลาด', market_name)}</Col>

        <Col span={12}>
          <Right>{getText('รหัสการจอง', code.toUpperCase())}</Right>
        </Col>
      </Row>
      <Line />
      <Row>
        <Col span={12}>{getText('ชื่อผู้จอง', `${name} (${mobile})`)}</Col>

        <Col span={12}>
          <Right>{getText('ชื่อสินค้า', shop_info.name || '')}</Right>
        </Col>
      </Row>
      <Line />
      <Dot />
      <ContentView>
        <Row>
          <Col span={16}>รายละเอียด</Col>

          <Col span={8}>
            <Right>จำนวน</Right>
          </Col>
        </Row>
      </ContentView>
      <Dot />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  margin-bottom: 16px;
`

const ContentView = styled.div`
  width: 100%;
  margin: 8px 0px;
`

const Right = styled.div`
  text-align: right;
`

const Line = styled.div`
  padding-bottom: 8px;
`

const Label = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${(p) => p.theme.color_level.grey.high};
`

const Text = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${(p) => p.theme.color_level.grey.high};
`

export default Header
