import styled from 'styled-components'

const Card = (props) => {
  const { code = '', color } = props
  return (
    <PageView>
      <Text>หมายเลขการจอง</Text>
      <Code style={{ backgroundColor: color }}>{code.toUpperCase()}</Code>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`

const Text = styled.div`
  color: ${(p) => p.theme.color_level.grey.medium};
`

const Code = styled.div`
  color: white;
  border-radius: 12px;
  padding: 1px 10px;
`

export default Card
