import { useState } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

import { ErrorDialog } from 'dialog'
import { Payment, Personal, ShopView } from 'pages/order/components'

const OrderPayment = (props) => {
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState('')

  const { isNew } = props
  const { account, new_shop, shop, order } = props.order.toJS()
  const market_info = props.market.toJS().short_info

  const onOrder = ({ list, note }) => {
    props.order.setOrder(list, note)
  }

  const onCloseError = () => setErr('')
  const onAccount = async (data, isSave = false) => {
    if (isSave === false) {
      props.order.setAccount(data)
      return
    }

    try {
      setLoading(true)

      await props.order.updateUserAddress(data)
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }

  return (
    <PageView>
      <Payment
        loading={loading}
        account={account}
        order={order}
        market_info={market_info}
        onOrder={onOrder}
        onAccount={onAccount}
      />
      <Personal account={account} />
      <ShopView shop={isNew ? new_shop : shop} />
      <ErrorDialog error={err} onClose={onCloseError} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

export default inject('order', 'market')(observer(OrderPayment))
