import { useState, useEffect, useCallback, useRef } from 'react'
import { inject, observer } from 'mobx-react'
import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Row, Col } from 'antd'

import { PrimaryBtn, CancelBtn } from 'components/button'
import { Close } from 'icon'

const Cookie = (props) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const ref = useRef(true)
  const navigate = useNavigate()
  const location = useLocation()

  const onLoad = useCallback(async () => {
    try {
      setLoading(true)

      await props.consent.loadCookie()
      await props.consent.getCookie()
    } catch (e) {
      console.log('e', e.message)
    }

    if (ref.current) {
      setLoading(false)
    }
  }, [props.consent])

  useEffect(() => {
    onLoad()
    return () => {
      ref.current = false
    }
  }, [onLoad])

  useEffect(() => {
    const url = location.pathname || ''
    const isConsent = url.includes('consent')

    const v = isConsent === false
    setVisible(v)
  }, [location.pathname])

  const consent = props.consent.toJS()
  const cookie = consent.cookie
  const current = consent.current.cookie || {}

  if (
    visible === false ||
    loading ||
    cookie.consent_id === current.consent_id
  ) {
    return <div />
  }

  const update = async (s) => {
    try {
      await props.consent.acceptCookie(cookie.consent_id, s)
    } catch (e) {
      console.log('update cookie:', e.message)
    }
  }

  const onAccept = () => update('accept')
  const onReject = () => update('reject')
  const onClose = () => update('close')
  const onCookie = () => navigate('/consent/cookie')

  return (
    <PageBottom>
      <PageView>
        <Line>
          <Button onClick={onClose}>
            <Close color="#494949" />
          </Button>
        </Line>

        <Row>
          <Col xl={18} lg={17} md={16} xs={24}>
            <Info>
              {cookie.short_content}
              <Link onClick={onCookie}>{cookie.title}</Link>
            </Info>
          </Col>

          <Col xl={6} lg={7} md={8} xs={24}>
            <LineBtn>
              <Row>
                <Col xl={12} lg={12} md={12} xs={24}>
                  <PrimaryBtn onClick={onAccept} text="ยอมรับ" />
                </Col>

                <Col xl={12} lg={12} md={12} xs={24}>
                  <CancelBtn onClick={onReject} text="ปฏิเสธ" />
                </Col>
              </Row>
            </LineBtn>
          </Col>
        </Row>
      </PageView>
    </PageBottom>
  )
}

const PageBottom = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 0px;
  z-index: 100;
  color: ${(p) => p.theme.color_level.grey.dark};
  background-color: ${(p) => p.theme.color_level.yellow.low};
`

const PageView = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 24px;
  min-height: 50px;

  ${(p) => p.theme.media.mobile} {
    min-height: 100px;
  }
`

const Line = styled.div`
  height: 45px;
`

const Link = styled.span`
  margin-left: 8px;
  text-decoration: underline;
  cursor: pointer;
`

const Button = styled.span`
  float: right;
  cursor: pointer;
`

const Info = styled.div`
  font-size: 14px;
  padding-right: 20px;

  ${(p) => p.theme.media.mobile} {
    padding-right: 0px;
    text-align: center;
  }
`

const LineBtn = styled.div`
  width: 100%;
  button {
    margin: 0px 8px;
    width: 95%;
  }

  ${(p) => p.theme.media.mobile} {
    padding-top: 20px;
    width: 214px;
    display: block;
    margin: 0 auto;

    button {
      margin: 8px 0px;
      width: 100%;
    }
  }
`

export default inject('consent')(observer(Cookie))
