import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { Checker } from 'components/input'
import { Information, ShopPicker, ShopInfo } from 'pages/order/components'

const OrderInfo = (props) => {
  const { isNew, isNoShop, validate, onSelectShop, onChange, onValidate } =
    props
  const { order, account, new_shop, shop } = props.order.toJS()

  const onAccount = (data) => {
    props.order.setAccount(data)
  }

  const onValidAccount = (data) => {
    validate.account = data
    onValidate(validate)
  }

  const onNewShop = (data, valid) => {
    props.order.setNewShop(data)
    validate.newShop = valid
    onValidate(validate)
  }

  const checker = isNoShop ? undefined : (
    <Checker label="เพิ่มสินค้าใหม่" value={isNew} onChange={onChange} />
  )
  const content =
    isNew || isNoShop ? (
      <ShopInfo
        order={order}
        doc={new_shop}
        validate={validate.newShop}
        onChange={onNewShop}
      />
    ) : undefined

  return (
    <PageView>
      <Information
        account={account}
        validate={validate.account}
        onChange={onAccount}
        onValidate={onValidAccount}
      />
      <ShopPicker
        isNoShop={isNoShop}
        shop={shop}
        inactive={isNew}
        validate={validate.shop}
        onClick={onSelectShop}
      />
      {checker}
      {content}
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

export default inject('order')(observer(OrderInfo))
