import { useState, useEffect, useCallback, useRef } from 'react'
import { inject, observer } from 'mobx-react'

import Loading from 'components/loading'
import { Link } from 'components/link'

import Title from '../Title'
import Content from '../Content'
import Carousel from '../Carousel'
import Card from '../Card'
import AreaItem from './AreaItem'

const MarketArea = (props) => {
  const [loading, setLoading] = useState(false)
  const ref = useRef(true)

  const onLoad = useCallback(async () => {
    try {
      setLoading(true)
      await props.market_area.getMarketArea()
    } catch (e) {
      console.log('e', e.message)
    }
    if (ref.current) {
      setLoading(false)
    }
  }, [props.market_area])

  useEffect(() => {
    onLoad()
    return () => {
      ref.current = false
    }
  }, [onLoad])

  const market_area = props.market_area.toJS()
  const market_area_list = market_area.market_area_list || []

  const content = market_area_list.slice(0, 10).map((it, i) => {
    const link = `/market-area/${it.market_area_id}`
    return (
      <Card key={i}>
        <Link to={link}>
          <AreaItem item={it} />
        </Link>
      </Card>
    )
  })

  return (
    <Content>
      <Title title="ทำเลมาแรง" to="/market-area" />
      <Loading loading={loading}>
        <Carousel>{content}</Carousel>
      </Loading>
    </Content>
  )
}

export default inject('market_area')(observer(MarketArea))
