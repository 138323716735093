import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Meta, Content } from 'components/display'
import { PrimaryBtn, Btn } from 'components/button'
import { Marget } from 'icon'

const Welcome = (props) => {
  const navigate = useNavigate()

  const gotoShop = () => navigate('/account/shop')
  const gotoHome = () => navigate('/')

  return (
    <PageView>
      <Meta title="ติดต่อเรา" description="welcome to marget member" />
      <Content>
        <Marget size={1.4} />

        <Title>
          <div>อีกเพียงขั้นตอนเดียว</div>
          <div>คุณก็จะทำการจองได้สะดวกยิ่งขึ้น</div>
        </Title>
        <Text>
          <div>เพิ่มข้อมูลสินค้าของคุณ</div>
          <div>เพื่อการจองตลาดที่สมบูรณ์</div>
        </Text>

        <Button>
          <PrimaryBtn text="เพิ่มข้อมูลสินค้า" onClick={gotoShop} />
          <Btn text="ไว้ทีหลัง" onClick={gotoHome} />
        </Button>
        <Note>
          <div>คุณสามารถเพิ่มข้อมูลสินค้าในภายหลัง</div>
          <div>ได้ที่หน้าบัญชีของฉัน</div>
        </Note>
      </Content>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  max-width: ${(p) => p.theme.max};
  min-height: 100vh;
  margin: 0 auto;
  text-align: center;
  color: ${(p) => p.theme.color_level.grey.high};
`

const Title = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-top: 50%;
  margin-bottom: 16px;
`

const Text = styled.div`
  font-size: 18px;
`

const Note = styled.div`
  font-size: 14px;
`

const Button = styled.div`
  width: 214px;
  margin: 0 auto;
  padding: 30px 0px;

  button {
    margin: 8px 0px;
  }
`

export default Welcome
