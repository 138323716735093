import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { Dot } from 'components/display'
import ViewFloorplan from 'components/floorplan'

import Pagination from './Pagination'

const Floorplan = (props) => {
  const { isProductType = true, onProductType } = props

  const { floorplan, display } = props.product.toJS()
  const selected = floorplan.selected || {}
  const floorplan_list = floorplan.list || []
  const unit = display.unit || {}

  const onFloorplan = (val) => {
    props.product.setFloorplan(val)
  }

  const onSelected = (val) => {
    props.product.setDisplay(display.booking_type, val)
  }

  const getPinList = () => {
    return display.list
  }

  const content = isProductType ? (
    <Button onClick={onProductType}>เลือกสินค้า</Button>
  ) : undefined

  const pin_list = getPinList()
  return (
    <PageView>
      <ImgView>
        <ViewFloorplan
          img_url={selected.img_url}
          pin_list={pin_list}
          height={295}
          selected={unit.unit_id}
          onSelected={onSelected}
        />
      </ImgView>
      <Dot />
      <Note>คลิ๊กที่ล็อกเพื่อจอง</Note>
      <Pagination
        selected={selected.floorplan_id}
        list={floorplan_list}
        onChange={onFloorplan}
      />
      <Dot />
      <Line>
        <Text>
          <Selected />
          <span>เลือก</span>
        </Text>
        <Text>
          <Available />
          <span>ว่าง</span>
        </Text>
        <Text>
          <Unavailable />
          <span>ไม่ว่าง</span>
        </Text>
      </Line>
      {content}
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  margin-top: 16px;
  padding: 16px;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
`

const ImgView = styled.div`
  width: 100%;
  margin-bottom: 16px;
`

const Note = styled.div`
  width: 100%;
  color: ${(p) => p.theme.color_level.grey.high};
  font-size: 12px;
  padding: 6px 0px;
  text-align: center;
`

const Button = styled.div`
  width: 100%;
  color: ${(p) => p.theme.color_level.grey.high};
  font-size: 12px;
  padding: 6px 0px;
  text-align: center;
  border: 1px solid ${(p) => p.theme.color_level.grey.low};
  width: 180px;
  margin: 0 auto;
  border-radius: 6px;
  margin-top: 16px;
  cursor: pointer;
`

const Line = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;
  row-gap: 12px;
  flex-wrap: wrap;
  padding-top: 16px;
`

const Text = styled.div`
  min-width: 80px;
  max-width: 160px;
  font-size: 14px;
  color: ${(p) => p.theme.color_level.grey.high};
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${(p) => p.theme.media.mobile} {
    min-width: 68px;
    max-width: 100px;
  }

  ${(p) => p.theme.media.mobile_xs} {
    min-width: 100px;
    max-width: 100%;
  }
`

const Circle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 8px;
`

const Selected = styled(Circle)`
  background-color: ${(p) => p.theme.color.blue};
`

const Available = styled(Circle)`
  background-color: ${(p) => p.theme.color.green};
`

const Unavailable = styled(Circle)`
  background-color: ${(p) => p.theme.color.grey};
`

export default inject('order', 'product')(observer(Floorplan))
