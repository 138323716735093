import styled from 'styled-components'

import { product, helper } from 'utils'

const Product = (props) => {
  const { item = {}, value, onSelect } = props

  const onClick = () => onSelect(item)
  const img_url = helper.getUrl(item.img_list)
  let shop_name = item.shop_name || ''
  shop_name = shop_name !== '' ? shop_name : 'ไม่ระบุ'

  const isActive = value === item.shop_id
  const css = isActive ? 'active' : ''
  return (
    <PageView className={css} onClick={onClick}>
      <Image src={img_url} />
      <Info>
        <Name>{item.name}</Name>
        <Text>
          หมวดหมู่:
          <span>{product.toProductType(item)}</span>
        </Text>
        <Text>
          ร้าน:
          <span>{shop_name}</span>
        </Text>
      </Info>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 20px;
  border: 1px solid ${(p) => p.theme.color_level.grey.low};
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  &.active {
    border: 1px solid ${(p) => p.theme.color.blue};
  }
`

const Image = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 4px;
`

const Name = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 24px;
  line-height: 22px;
  color: ${(p) => p.theme.color.blue};
`

const Info = styled.div`
  width: calc(100% - 120px);
`

const Text = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  color: ${(p) => p.theme.color_level.grey.high};
  span {
    font-weight: 400;
    margin-left: 4px;
  }
`

export default Product
