import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { checkGoogle } from 'app/firebaseApp'
import { error, firebase, http, line } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  uid: '',
  provider: {},
  img_url: undefined,
  email: '',
  name: '',
  surname: '',
  role: '',
}

const localLineId = 'Ua500db59cc5d6a4195b49dc87acbba66'
let state
let checkingLine = false
export class Member extends BaseStore {
  constructor() {
    super()
    this.observable({
      verifying: false,
      user: cloneDeep(original),
    })

    state = this
    http.setMember(this)
  }

  async reset() {
    await firebase.logout()
    runInAction(() => {
      state.user = {}
    })
  }

  async getCurrentUser() {
    return await firebase.onAuthStateChanged()
  }

  async checkLoginUser() {
    try {
      const user = await this.getCurrentUser()
      console.log('user:', user)
      if (user) {
        return await this.getUserProfile({ user })
      } else {
        const resp = await line.getProfile()
        if (resp) {
          const { profile = {} } = resp || {}
          await this.login(profile.userId)
        }
      }
    } catch (e) {
      console.log('err check user:', e.message)
    }
  }

  getProvider({ user }) {
    const list = user.providerData || []
    return list.map((item) => item.providerId)
  }

  setProfile(doc) {
    runInAction(() => {
      state.user = doc
    })
  }

  async getUserProfile(params = {}) {
    const { user } = params

    const token = await user.getIdToken()
    await http.setToken(token)

    const url = `${config.api}/v1/user/profile`
    const resp = await http.get(url)

    const data = resp.body
    await this.setProfile(data)

    return data
  }

  isLogin() {
    const user = this.toJS().user
    return user.user_id !== undefined
  }

  async login(id) {
    if (checkingLine) {
      return {}
    }
    checkingLine = true
    error.isNull(id, 'เกิดข้อผิดพลาด account id ของคุณ')
    const url = `${config.api}/v1/public/account/login-line/${id}`

    const json = {}
    const res = await http.post(url, { json })
    const { user, token } = res.body || {}
    error.isNull(user, 'เกิดข้อผิดพลาด account ของคุณ')
    error.isNull(token, 'เกิดข้อผิดพลาด account ของคุณ')

    const resToken = await firebase.signInWithToken(token)
    error.isError(resToken.error, 'เกิดข้อผิดพลาด account ของคุณ')

    const id_token = await resToken.user.getIdToken()
    http.setToken(id_token)

    checkingLine = false
    runInAction(() => {
      state.user = user
    })

    return user
  }

  async lineLogin({ redirect, type, option, navigate } = {}) {
    const { origin = '' } = window.location
    await firebase.logout()

    if (origin === 'http://localhost:2210') {
      const user = await this.login(localLineId)
      const userId = +user.user_id || -1
      const name = user.name || ''
      const isRegister = userId !== -1 && name === ''
      if (isRegister) {
        navigate('/account/register')
      } else if (redirect) {
        navigate(redirect || '/')
      }
    } else {
      line.login({ redirect, type, option })
    }
  }

  async checkLineProfile() {
    if (origin === 'http://localhost:2210') {
      const user = await this.login(localLineId)
      return { user }
    } else {
      const resp = await line.getProfile()
      const { profile = {}, setting = {} } = resp || {}
      const user = await this.login(profile.userId)

      return { user, setting }
    }
  }

  async logout() {
    await http.setToken()
    await this.reset()
  }

  async loginByGoogle() {
    const result = await checkGoogle()

    const { user } = result
    error.isNull(user, { message: 'user not found' })
    return await this.getUserProfile({ user })
  }
}

export default new Member()
