import queryString from 'query-string'

export class Helper {
  sleep(ms = 1000) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  isNull(val) {
    return val === null || val === undefined
  }

  notNull(val) {
    return val !== null && val !== undefined
  }

  isValue(val) {
    return val !== null && val !== undefined && val !== ''
  }

  notValue(val) {
    return val === null || val === undefined || val === ''
  }

  isEmpty(val) {
    let list = Object.keys(val)
    return (
      val === null ||
      val === undefined ||
      val === '' ||
      list === 0 ||
      list.length === 0
    )
  }

  toPercent(a, b) {
    if (a === 0 || b === 0) return 0

    return a / b
  }

  isTextError(val, text) {
    return val === '' || val === null ? text : undefined
  }

  random({
    len,
    prefix = '',
    postfix = '',
    character = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
  }) {
    let need = len - (prefix.length + postfix.length)
    let max = character.length
    if (need < 0) return
    if (max === 0) return
    let resp = ''

    for (let i = 0; i < need; i++) {
      resp += character.charAt(Math.floor(Math.random() * max))
    }
    return `${prefix}${resp}${postfix}`
  }

  isUrl(val) {
    if (this.isValue(val)) {
      let prefix = val.substring(0, 4)
      return prefix === 'http'
    }

    return false
  }

  trim(data, list = []) {
    list.forEach((name) => {
      let temp = data[name]
      if (temp) data[name] = temp.trim()
    })

    return data
  }

  upItem(index, list = []) {
    const current = list[index]
    const next = list[index - 1]

    list[index] = next
    list[index - 1] = current
    return list
  }

  downItem(index, list = []) {
    const current = list[index]
    const next = list[index + 1]

    list[index] = next
    list[index + 1] = current
    return list
  }

  compareString(src, target) {
    return (
      String(src).toLocaleLowerCase() === String(target).toLocaleLowerCase()
    )
  }

  getBackUrl(location) {
    const { source, code } = queryString.parse(location)
    const path = source ? source : '/'
    const index = path.indexOf('?')
    const ch = index === -1 ? '?' : '&'
    const c = code ? `${ch}code=${code}` : ''

    return `${path}${c}`
  }

  async gotoCode(location) {
    try {
      const { code } = queryString.parse(location)
      if (code) {
        await helper.sleep(250)
        const ele = document.getElementById(code)
        ele.scrollIntoView()
      }
    } catch (e) {
      console.log('e', e.message)
    }
  }

  getUrl(list = []) {
    const img_url = list.length > 0 ? list[0] : undefined
    return img_url
  }

  getPriceUnit(doc = {}) {
    const { booking_daily, booking_monthly, booking_round } = doc

    if (booking_daily === 'yes' || booking_round === 'yes') {
      return 'วัน'
    } else if (booking_monthly === 'yes') {
      return 'เดือน'
    }

    return 'วัน'
  }

  getDateList(open_list = []) {
    const define_list = [
      { name: 'monday', value: 1 },
      { name: 'tuesday', value: 2 },
      { name: 'wednesday', value: 3 },
      { name: 'thursday', value: 4 },
      { name: 'friday', value: 5 },
      { name: 'saturday', value: 6 },
      { name: 'sunday', value: 0 },
    ]

    const list = []
    for (const item of define_list) {
      const i = open_list.find((it) => it.name === item.name)
      if (i) {
        list.push(item.value)
      }
    }

    return list
  }
}

export const helper = new Helper()
export default helper
