import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

import Footer from 'footer'
import { helper } from 'utils'
import { Meta } from 'components/display'
import MarketList from 'pages/market/MarketList'

const HotMarketList = (props) => {
  const [loading, setLoading] = useState(false)
  const location = useLocation()

  const onLoad = useCallback(async () => {
    try {
      setLoading(true)
      await props.market.getHotMarket()
    } catch (e) {
      console.log('e', e.message)
    }
    setLoading(false)
  }, [props.market])

  useEffect(() => {
    onLoad()
  }, [onLoad])

  useEffect(() => {
    helper.gotoCode(location.search)
  }, [])

  const { market_list = [] } = props.market.toJS()

  return (
    <PageView>
      <Meta title="ตลาดยอดนิยม" description="ตลาดยอดนิยม" />
      <MarketList
        loading={loading}
        source="/hot-market"
        title="ตลาดยอดนิยม"
        market_list={market_list}
      />
      <Footer />
    </PageView>
  )
}

const PageView = styled.div``

export default inject('market')(observer(HotMarketList))
