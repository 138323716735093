import styled from 'styled-components'

import { Invalid } from 'components/input'

import Label from './Label'
import SelectShopBtn from './SelectShopBtn'
import Shop from './Shop'

const ShopPicker = (props) => {
  const { isNoShop, inactive, shop = {}, validate = {}, onClick } = props

  const contentShop = shop.shop_id ? (
    <Shop doc={shop} inactive={inactive} onClick={onClick} />
  ) : (
    <SelectShopBtn inactive={inactive} onClick={onClick} />
  )

  const content = isNoShop ? undefined : <ShopInfo>{contentShop}</ShopInfo>
  return (
    <PageView>
      <Label text="ข้อมูลร้านค้า" />
      {content}
      <Invalid invalid={validate.shop_id} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  margin-bottom: 16px;
`

const ShopInfo = styled.div`
  width: 100%;
  margin: 16px 0px;
`

export default ShopPicker
