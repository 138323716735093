import styled from 'styled-components'

import { CheckCircle } from 'icon'

const Step = (props) => {
  const { menu = [], value, onChange = () => {} } = props

  const content = menu.map((it, i) => {
    const css = i === value ? 'active' : value > i ? 'pass' : ''
    const icon = css === 'pass' ? <CheckCircle /> : i + 1
    const click = css === 'pass' ? () => onChange(i) : undefined
    return (
      <Btn key={i}>
        <No className={css} onClick={click}>
          {icon}
        </No>
        <Text className={css} onClick={click}>
          {it.name}
        </Text>
      </Btn>
    )
  })

  return (
    <PageView>
      <NoLine>{content}</NoLine>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  height: 50px;
`

const Btn = styled.div`
  position: relative;
`

const NoLine = styled.div`
  width: 80%;
  height: 24px;
  display: flex;
  justify-content: space-between;
  background-color: #dcdcdc;
  border-radius: 12px;
  margin: 0 auto;
`

const No = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: bold;
  font-size: 14px;
  color: white;
  background-color: ${(p) => p.theme.color_level.grey.medium};

  &.active {
    background-color: ${(p) => p.theme.color.blue};
  }

  &.pass {
    background-color: ${(p) => p.theme.color.green};
    cursor: pointer;
  }
`

const Text = styled.span`
  color: ${(p) => p.theme.color_level.grey.low};
  position: absolute;
  width: max-content;
  bottom: -40px;
  left: 0px;
  transform: translate(-40%, -40%);

  &.active {
    color: ${(p) => p.theme.color.blue};
  }

  &.pass {
    color: ${(p) => p.theme.color.green};
    cursor: pointer;
  }
`

export default Step
