/* eslint-disable */

const Logo = (props) => {
  const { size = 1, color = '#494949' } = props

  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_499_2126)">
        <path
          d="M20.4844 3.51562C18.2203 1.24687 15.2063 0 12 0C9.69377 0 7.45315 0.65625 5.5219 1.89844C3.64221 3.10781 2.13752 4.80469 1.1719 6.81562C0.890648 7.39687 1.13909 8.1 1.72034 8.38125C2.30158 8.6625 3.00471 8.41406 3.28596 7.83281C4.06408 6.21563 5.27346 4.84687 6.78752 3.87187C8.34377 2.87344 10.1438 2.34375 12 2.34375C17.325 2.34375 21.6563 6.675 21.6563 12C21.6563 17.325 17.325 21.6562 12 21.6562C10.1438 21.6562 8.34377 21.1266 6.78752 20.1328C5.27346 19.1625 4.06408 17.7891 3.28596 16.1719C3.00471 15.5906 2.30627 15.3422 1.72034 15.6234C1.13909 15.9047 0.890648 16.6031 1.1719 17.1891C2.13752 19.2 3.63752 20.9016 5.5219 22.1063C7.45315 23.3438 9.69377 24 12 24C15.2063 24 18.2203 22.7531 20.4844 20.4844C22.7531 18.2156 24 15.2063 24 12C24 8.79375 22.7531 5.77969 20.4844 3.51562Z"
          fill={color}
        />
        <path
          d="M6.53442 12C6.53442 12.6469 7.05942 13.1719 7.7063 13.1719H14.3719L12.6422 14.9016C12.1829 15.3609 12.1829 16.1016 12.6422 16.5609C12.8719 16.7906 13.1719 16.9031 13.4719 16.9031C13.7719 16.9031 14.0719 16.7906 14.3016 16.5609L17.25 13.6125C17.6813 13.1813 17.9157 12.6094 17.9157 12.0047C17.9157 11.4 17.6766 10.8234 17.25 10.3969L14.3016 7.44844C13.8422 6.98907 13.1016 6.98907 12.6422 7.44844C12.1829 7.90782 12.1829 8.64844 12.6422 9.10782L14.3719 10.8375H7.7063C7.05942 10.8281 6.53442 11.3531 6.53442 12Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_499_2126">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
/* eslint-enable */
export default Logo
