import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

import { format, helper } from 'utils'

import MarketType from './MarketType'

const MarketPrice = (props) => {
  const { item = {}, display_type = false } = props
  const { price } = item

  let size = 24
  let content
  if (display_type) {
    size = 14
    content = (
      <Col span={10}>
        <MarketType item={item} />
      </Col>
    )
  }

  const unit = helper.getPriceUnit(item)
  return (
    <PriceDetail>
      <Row align="middle">
        <Col span={size}>
          ราคาเริ่มต้น
          <Price>
            {format.toDigi(price)}
            <span>บาท/{unit}</span>
          </Price>
        </Col>
        {content}
      </Row>
    </PriceDetail>
  )
}

const PriceDetail = styled.div`
  margin-top: 20px;
  font-size: 12px;
`

const Price = styled.div`
  font-size: 24px;
  line-height: 16px;
  margin-top: 4px;
  display: flex;
  align-items: baseline;
  color: ${(p) => p.theme.color_level.red.medium};

  span {
    font-size: 14px;
    margin-left: 4px;
  }
`

export default MarketPrice
