import styled from 'styled-components'

import { format } from 'utils'

import Product from './Product'

const Booking = (props) => {
  const { isTotal, list = [], total = 0, onRemove } = props
  const content = list.map((it, i) => {
    return <Product key={i} index={i} item={it} onRemove={onRemove} />
  })

  const contentTotal = isTotal ? (
    <Total>
      <Text>ราคารวมทั้งสิ้น</Text>
      <Price>
        {format.toDigi(total)}
        <span>บาท</span>
      </Price>
    </Total>
  ) : undefined

  return (
    <PageView>
      {content}
      {contentTotal}
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  margin-bottom: 16px;
`

const Total = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`

const Text = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  color: ${(p) => p.theme.color_level.grey.high};
`

const Price = styled.div`
  color: ${(p) => p.theme.color.red};
  font-weight: 700;
  font-size: 24px;

  span {
    margin-left: 8px;
    font-weight: 400;
    font-size: 14px;
  }
`

export default Booking
