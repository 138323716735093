import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { ErrorDialog } from 'dialog'
import { helper, validator } from 'utils'
import { Meta, BackHeader, Layout, Step, SaveBtn } from 'components/display'
import { Booking, ProductMenu } from 'pages/order/components'
import BookingMenu from 'pages/order/components/BookingMenu'

import OrderInfo from './OrderInfo'
import OrderPayment from './OrderPayment'
import rules from './rules'

const SubmitOrder = (props) => {
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [page, setPage] = useState(0)
  const [visible, setVisible] = useState(false)
  const [isNew, setIsNew] = useState(false)
  const [err, setErr] = useState('')
  const [validate, setValidate] = useState({
    account: {},
    shop: {},
    newShop: {},
  })

  const navigate = useNavigate()
  const location = useLocation()

  const { account, shop, new_shop, order } = props.order.toJS()

  const { short_info } = props.market.toJS()
  const { shop_list } = props.shop.toJS()
  const isNoShop = shop_list.length === 0

  const onLoad = useCallback(
    async (market_id, shop_id) => {
      try {
        setLoading(true)

        const { user } = props.member.toJS()
        await Promise.all([
          props.market.getMarketShortInfo(market_id),
          props.order.getUserAddress({ user }),
          props.order.getShopInfo(shop_id),
          props.shop.getMyShopList(),
        ])
      } catch (e) {
        setErr(e.message)
      }
      setLoading(false)
    },
    [props.market, props.order, props.shop]
  )

  useEffect(() => {
    onLoad(order.market_id, shop.shop_id)
  }, [onLoad, order.market_id, shop.shop_id])

  const onBack = () => {
    if (page === 0) {
      const path = helper.getBackUrl(location.search)
      navigate(path)
    } else {
      setPage(0)
    }
  }

  const onSelectStep = (p) => setPage(p)
  const onCloseError = () => setErr('')
  const onSelectShop = () => setVisible(true)
  const closeSelectShop = () => setVisible(false)
  const selectShop = (data) => {
    closeSelectShop()
    delete validate.shop.shop_id
    setValidate({ ...validate })
    props.order.setShop(data)
  }

  const onCh = (val) => setIsNew(val)
  const onValidate = (val) => setValidate({ ...val })
  const onNext = () => {
    const result = {
      account: {},
      shop: {},
      newShop: {},
    }

    const checker = validator.process(rules.account, account)
    if (checker.invalid) {
      result.account = checker.errors
    }

    let shopNewChecker = { invalid: false }
    let shopChecker = { invalid: false }
    if (isNew || isNoShop) {
      shopNewChecker = validator.process(rules.shop, new_shop)
      if (shopNewChecker.invalid) {
        result.newShop = shopNewChecker.errors
      }
    } else {
      const invalid = shop.shop_id ? false : true
      result.shop.shop_id = invalid ? 'กรุณาเลือกร้านค้า' : undefined
      shopChecker = { invalid }
    }

    if (checker.invalid || shopChecker.invalid || shopNewChecker.invalid) {
      setValidate(result)
      validator.focus(result)
      return
    }
    setPage(1)
  }

  const onRemove = (index) => {
    props.order.removeProduct(index, order.list, order.total)
  }

  const onSave = async () => {
    try {
      setSaving(true)

      const is_create_shop = isNew || isNoShop
      const code = await props.order.createOrder({
        is_create_shop,
        shop: is_create_shop ? new_shop : shop,
        account,
        order,
      })
      setSaving(false)

      if (isNew) {
        props.shop.clearShopList()
      }

      navigate(`/account/booking/${code}`)
    } catch (e) {
      setErr(e.message)
      setSaving(false)
    }
  }

  const content =
    page === 0 ? (
      <OrderInfo
        isNew={isNew}
        isNoShop={isNoShop}
        validate={validate}
        onSelectShop={onSelectShop}
        onChange={onCh}
        onValidate={onValidate}
      />
    ) : (
      <OrderPayment isNew={isNew || isNoShop} />
    )

  const list = order.list || []
  const disabled = list.length === 0
  const menu =
    page === 0 ? (
      <BookingMenu onClick={onNext} />
    ) : (
      <SaveBtn
        text={{ save: 'ยืนยันการจอง' }}
        disabled={disabled}
        loading={saving}
        onClick={onSave}
      />
    )

  return (
    <PageView>
      <Meta loading={loading} title="รายละเอียดการจอง" />
      <BackHeader onClick={onBack} title="รายละเอียดการจอง" />
      {menu}
      <ProductMenu
        visible={visible}
        onSelect={selectShop}
        onClose={closeSelectShop}
      />
      <Layout loading={loading} isHeader={true}>
        <Body>
          <DisplayStep>
            <Step menu={step_menu} value={page} onChange={onSelectStep} />
          </DisplayStep>
          <Name>{short_info.name}</Name>
          <Booking
            isTotal={page === 1}
            list={order.list}
            total={order.total}
            onRemove={onRemove}
          />
          {content}
        </Body>
      </Layout>
      <ErrorDialog error={err} onClose={onCloseError} />
    </PageView>
  )
}

const step_menu = [
  { name: 'ข้อมูลผู้เช่า', value: 'information' },
  { name: 'ยืนยันและชำระเงิน', value: 'payment' },
]

const PageView = styled.div`
  width: 100%;
  min-height: 100vh;
  padding-bottom: 60px;
`

const Body = styled.div`
  padding: 0px 24px 40px 24px;
`

const DisplayStep = styled.div`
  width: 100%;
  max-width: 260px;
  margin: 0 auto;
  padding-top: 16px;
`

const Name = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 24px;
  padding: 16px 0px;
`

export default inject(
  'member',
  'market',
  'order',
  'shop'
)(observer(SubmitOrder))
