import Joi from 'joi'

export class Validator {
  isEmail(email) {
    /* eslint-disable */
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  isPassword(val = '') {
    const re = new RegExp('^(?=.*[a-z, A-Z])(?=.*[0-9])')
    return re.test(val)
  }

  process(validate, values) {
    const result = validate.validate(values, { abortEarly: false })
    let errors = {}
    if (result.error) {
      const details = result.error.details || []
      errors = details
        .map((err) => ({ [err.context.key]: err.message }))
        .reduce((acc, cur) => ({ ...acc, ...cur }), {})
    }

    const invalid = Object.keys(errors).length > 0

    return {
      invalid,
      values,
      errors,
    }
  }

  isError(doc = {}) {
    return Object.keys(doc).length > 0
  }

  getRuleString(name = '', typ) {
    const text = typ === 'choose' ? `กรุณาเลือก${name}` : `กรุณากรอก${name}`
    const role = Joi.string()
      .trim()
      .required()
      .messages({
        'any.required': text,
        'string.empty': text,
        'string.base': text,
      })
      .label(name)

    return role
  }

  getRuleSizeString(name = '', min, max, typ) {
    const text = typ === 'choose' ? `กรุณาเลือก${name}` : `กรุณากรอก${name}`
    const role = Joi.string()
      .trim()
      .min(min)
      .max(max)
      .required()
      .messages({
        'any.required': text,
        'string.empty': text,
        'string.base': text,
        'string.min': `${text} จำนวน ${min} ตัว`,
        'string.max': `${text} จำนวน ${max} ตัว`,
      })
      .label(name)

    return role
  }

  getRuleDate(name = '') {
    const text = `กรุณาเลือก${name}`
    const role = Joi.any().required().invalid(null).messages({
      'any.required': text,
      'any.invalid': text,
    })

    return role
  }

  getRuleNumber(name = '', typ) {
    const text = typ === 'choose' ? `กรุณาเลือก${name}` : `กรุณากรอก${name}`
    const role = Joi.number()
      .required()
      .messages({
        'any.required': text,
      })
      .label(name)

    return role
  }

  getRuleLimitNumber(name = '', min, max) {
    const text = `กรุณาเลือก${name}`
    const role = Joi.number()
      .min(min)
      .max(max)
      .required()
      .messages({
        'any.required': text,
        'number.min': text,
        'number.max': text,
      })
      .label(name)

    return role
  }

  getRuleList(name = '', min = 1) {
    const text = `กรุณาเพิ่ม${name}`
    const role = Joi.array()
      .min(min)
      .required()
      .messages({
        'any.required': text,
        'array.min': text,
        'array.base': text
      })
      .label(name)

    return role
  }

  focus(err = {}) {
    const list = Object.keys(err)
    if (list.length > 0) {
      const name = list[0]
      const input = document.getElementById(name)
      if (input) input.focus()
    }
  }
}

export const validator = new Validator()
export default validator
