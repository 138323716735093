import styled from 'styled-components'
import parse from 'html-react-parser'

const HtmlContent = (props) => {
  const { content } = props

  return <Body>{parse(content || '')}</Body>
}

const Body = styled.div`
  text-align: left;

  img {
    max-width: 600px;
  }

  @media screen and (max-width: 768px) {
  }

  @media screen and (max-width: 465px) {
    width: 100%;
    img {
      max-width: 100%;
    }
  }
`

export default HtmlContent
