import styled from 'styled-components'
import { Drawer } from 'antd'

import { HtmlContent } from 'components/display'
import { Close } from 'icon'

const Content = (props) => {
  const { text, visible, onClose } = props
  return (
    <PageView>
      <Drawer
        title=""
        className="drawer-content"
        height="80%"
        placement={'bottom'}
        closable={false}
        onClose={onClose}
        visible={visible}
      >
        <Body>
          <Line>
            <Btn onClick={onClose}>
              <Close />
            </Btn>
          </Line>
          <Html>
            <HtmlContent content={text} />
          </Html>
        </Body>
      </Drawer>
    </PageView>
  )
}

const PageView = styled.div``

const Body = styled.div`
  padding: 24px 14px;
`

const Html = styled.div`
  width: 100%;
  padding: 10px 15px 10px 10px;
  overflow: auto;
`

const Line = styled.div`
  height: 30px;
  padding: 0px 10px;
`

const Btn = styled.span`
  float: right;
  cursor: pointer;
`

export default Content
