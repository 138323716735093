import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import queryString from 'query-string'

import { ErrorDialog } from 'dialog'
import { Meta, BackHeader, Layout } from 'components/display'
import { LineBtn } from 'components/button'

const Authentication = (props) => {
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState('')
  const navigate = useNavigate()
  const location = useLocation()

  const onBack = () => {
    const { source } = queryString.parse(location.search)
    const path = source ? source : '/'
    navigate(path)
  }

  const onCloseError = () => setErr('')

  const onLogin = async () => {
    try {
      setLoading(true)
      setErr('')
      const pathname = window.location.pathname
      const search = window.location.search || ''
      const redirect = `${pathname}${search}`

      await props.member.lineLogin({ redirect, navigate })
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }

  return (
    <PageView>
      <Meta title="กรุณาเข้าสู่ระบบ" description="กรุณาเข้าสู่ระบบ" />
      <BackHeader onClick={onBack} />
      <Layout isHeader={true}>
        <Body>
          <Title>เข้าสู่ระบบ /สมัครสมาชิก</Title>
          <Button>
            <LineBtn
              text="ดำเนินการต่อด้วย LINE"
              loading={loading}
              onClick={onLogin}
            />
          </Button>
        </Body>
      </Layout>
      <ErrorDialog
        message_list={['ไม่สามารถเข้าสู่ระบบได้', 'กรุณาลองใหม่อีกครั้ง']}
        error={err}
        onClose={onCloseError}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  min-height: 85vh;
  color: ${(p) => p.theme.color_level.grey.high};
  background-color: ${(p) => p.theme.color.backgroundColor};
`

const Body = styled.div`
  width: 100%;
  padding-top: 20px;
  text-align: center;
`

const Title = styled.div`
  color: ${(props) => props.theme.color.blue};
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
`

const Button = styled.div`
  width: 220px;
  margin: 0 auto;
  margin-top: 23px;
`

export default inject('member')(observer(Authentication))
