import { useEffect } from 'react'
import styled from 'styled-components'

import BaseDialog from './BaseDialog'

const ImgDialog = (props) => {
  const { id = 'display-img', visible, img, onClose } = props

  useEffect(() => {
    if (visible) {
      const element = document.getElementById(id)
      element.scroll({
        top: element.scrollHeight / 3,
        left: element.scrollWidth / 3,
        behavior: 'smooth',
      })
    }
  }, [visible])

  return (
    <BaseDialog visible={visible} onClose={onClose}>
      <Content id={id}>
        <Image src={img} />
      </Content>
    </BaseDialog>
  )
}

const Content = styled.div`
  width: 80vw;
  height: 70vh;
  display: flex;
`

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`

/*
const Content = styled.div`
  width: 80vw;
  height: 70vh;
  padding: 10px;
  overflow: scroll;
`

const Body = styled.div`
  width: max-content;
  height: auto;
`

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: none;
`
*/

export default ImgDialog
