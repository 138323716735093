import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { ErrorDialog } from 'dialog'
import Loading from 'components/loading'
import { BackHeader, Layout, Tab } from 'components/display'

import Card from './Card'

const OrderList = (props) => {
  const [loading, setLoading] = useState(false)
  const [category, setCategory] = useState('')
  const [err, setErr] = useState('')

  const navigate = useNavigate()
  const location = useLocation()

  const { status } = queryString.parse(location.search)

  useEffect(() => {
    const s = status ? status : 'waiting'
    setCategory(s)
  }, [status])

  const onLoad = useCallback(async () => {
    if (category === '') {
      return
    }

    try {
      setLoading(true)

      await props.my_order.getOrderList({ category })
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }, [props.my_order, category])

  useEffect(() => {
    onLoad()
  }, [onLoad])

  const onCloseError = () => setErr('')
  const onBack = () => navigate('/account')
  const onNext = (url) => navigate(url)
  const onChange = (val) => setCategory(val)

  const { display = {} } = props.my_order.toJS()
  const { list = [] } = display

  const content = list.map((it, i) => {
    return <Card key={i} category={category} item={it} onNext={onNext} />
  })
  return (
    <PageView>
      <BackHeader onClick={onBack} title="การจองของฉัน" />
      <Layout isHeader={true}>
        <Tab menu={menu} selected={category} onChange={onChange} />
        <Loading loading={loading}>
          <Body>{content}</Body>
        </Loading>
      </Layout>
      <ErrorDialog error={err} onClose={onCloseError} />
    </PageView>
  )
}

const menu = [
  { label: 'กำลังจะมาถึง', value: 'waiting' },
  { label: 'ที่ผ่านไปแล้ว', value: 'past' },
  { label: 'ยกเลิก/เสนอใหม่', value: 'cancel' },
]

const PageView = styled.div`
  width: 100%;
  min-height: 90vh;
  padding: 0px 16px;
`

const Body = styled.div`
  width: 100%;
  padding-top: 24px;
`

export default inject('my_order')(observer(OrderList))
