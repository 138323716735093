import Joi from 'joi'

import { validator } from 'utils'

const account = Joi.object({
  name: validator.getRuleString('ชื่อ'),
  mobile: validator.getRuleSizeString('เบอร์ติดต่อ', 10, 11),
}).options({ allowUnknown: true })

const shop = Joi.object({
  name: validator.getRuleString('ชื่อสินค้า'),
  product_type: Joi.any()
    .required()
    .valid('food', 'drink', 'clothes', 'things', 'services')
    .messages({ 'any.required': `กรุณาเลือกหมวดหมู่สินค้า` })
    .messages({ 'any.only': `กรุณาเลือกหมวดหมู่สินค้า` })
    .label('หมวดหมู่สินค้า'),
  // img_list: validator.getRuleList('รูปสินค้า'),
}).options({ allowUnknown: true })

const rule = { account, shop }
export default rule
