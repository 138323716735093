import styled from 'styled-components'

import { Row, Col, Line, SaveBtn } from 'components/display'
import { Radio, Input, InputArea } from 'components/input'

import Label from '../Label'

const TaxInvoice = (props) => {
  const {
    visible,
    loading,
    account = {},
    market_info = {},
    validate = {},
    onAccount,
    onSave,
  } = props

  const disabled = account.receive_type === 'onsite'

  const onCh = (val, n) => {
    account[n] = val
    delete validate[n]
    onAccount(account, validate)
  }

  if (!visible) return <div />

  const menu = []
  if (market_info.delivery_market === 'yes') {
    menu.push({ name: 'รับด้วยตัวเองที่ตลาด', value: 'onsite' })
  }

  if (market_info.delivery_post_office === 'yes') {
    menu.push({ name: 'จัดส่งทางไปรษณีย์', value: 'address' })
  }

  return (
    <PageView>
      <Row>
        <Col {...col} position="left-responsive">
          <Input
            label="เลขบัตรประชาชน / เลขประจำตัวผู้เสียภาษี"
            name="tax_id"
            value={account.tax_id}
            onChange={onCh}
            isNumber={true}
            pattern="x-xxxx-xxxxx-xx-x"
            invalid={validate.tax_id}
          />
        </Col>
        <Col {...col} position="right-responsive">
          <Input
            label="ชื่อบริษัท / ชื่อบุคคล"
            name="tax_name"
            value={account.tax_name}
            onChange={onCh}
            invalid={validate.tax_name}
          />
        </Col>
      </Row>

      <Row>
        <Col {...col} position="left-responsive">
          <Input
            label="สำนักงานใหญ่ / รหัสสาขา (สำหรับบริษัท)"
            name="branch_code"
            value={account.branch_code}
            onChange={onCh}
            isNumber={true}
          />
        </Col>
        <Col {...col} position="right-responsive">
          <Input
            label="เบอร์โทรศัพท์"
            name="company_mobile"
            value={account.company_mobile}
            onChange={onCh}
            isNumber={true}
            pattern="xxx-xxx-xxxx"
            invalid={validate.company_mobile}
          />
        </Col>
      </Row>
      <Line>
        <InputArea
          label="ที่อยู่ในการออกใบกำกับภาษี"
          rows={2}
          name="tax_address"
          value={account.tax_address}
          onChange={onCh}
          invalid={validate.tax_address}
        />
      </Line>

      <Line>
        <Label text="เลือกวิธีรับใบกำกับภาษี" />
      </Line>

      <Line>
        <Radio
          name="receive_type"
          value={account.receive_type}
          menu={menu}
          onChange={onCh}
        />
      </Line>
      <Row>
        <Col {...col} position="left-responsive">
          <Input
            label="ชื่อผู้รับ"
            name="receive_name"
            value={account.receive_name}
            onChange={onCh}
            disabled={disabled}
            invalid={validate.receive_name}
          />
        </Col>
        <Col {...col} position="right-responsive">
          <Input
            label="เบอร์ติดต่อ"
            name="receive_mobile"
            value={account.receive_mobile}
            onChange={onCh}
            isNumber={true}
            pattern="xxx-xxx-xxxx"
            disabled={disabled}
            invalid={validate.receive_mobile}
          />
        </Col>
      </Row>
      <Line>
        <InputArea
          label="ที่อยู่จัดส่งใบกำกับภาษี"
          rows={2}
          name="receive_address"
          value={account.receive_address}
          onChange={onCh}
          disabled={disabled}
          invalid={validate.receive_address}
        />
      </Line>
      <SaveBtn loading={loading} onClick={onSave} />
    </PageView>
  )
}

const col = { lg: 12, md: 12, xs: 24 }

const PageView = styled.div`
  width: 100%;
  padding: 24px 0px;
`

export default TaxInvoice
