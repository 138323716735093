import styled from 'styled-components'

import { format } from 'utils'

const Price = (props) => {
  const { css, price, promotion, unit = '' } = props

  let content = price
  if (promotion) {
    content = (
      <>
        {promotion}
        <PromotionPrice className={css}>{price}</PromotionPrice>
      </>
    )
  }
  return (
    <PriceText className={css}>
      {content}
      <Label className={css}>{unit}</Label>
    </PriceText>
  )
}

const PriceText = styled.div`
  width: 100%;
  font-size: 24px;
  color: ${(p) => p.theme.color.red};
  line-height: 26px;

  &.inactive {
    color: ${(p) => p.theme.color_level.grey.low};
  }
`

const PromotionPrice = styled.span`
  width: 100%;
  font-size: 20px;
  color: ${(p) => p.theme.color_level.grey.medium};
  line-height: 20px;
  text-decoration: line-through;
  margin-left: 8px;
  &.inactive {
    color: ${(p) => p.theme.color_level.grey.low};
  }
`

const Label = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.color.red};
  line-height: 18px;

  &.inactive {
    color: ${(p) => p.theme.color_level.grey.low};
  }
`

export default Price
