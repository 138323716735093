import styled from 'styled-components'

const Card = (props) => {
  const { children } = props

  return <PageView>{children}</PageView>
}

const PageView = styled.div`
  width: 100%;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
`

export default Card
