import styled from 'styled-components'

import { Link } from 'components/link'
import { product } from 'utils'

import Header from './Header'
import Information from './Information'
import Transfer from './Transfer'

const Card = (props) => {
  const { item = {}, category, onNext } = props
  const { code } = item
  const { text, color } = product.getStatus(item)
  return (
    <PageView>
      <Link to={`/account/booking/${code}?category=${category}`}>
        <Header code={code} color={color} />
        <Information item={item} text={text} color={color} />
      </Link>
      <Transfer item={item} onNext={onNext} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  background-color: #ffffff;

  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 15px 19px;
`

export default Card
