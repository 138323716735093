import styled from 'styled-components'

import Header from './Header'
import Product from './Product'
import Total from './Total'

const Information = (props) => {
  const { order = {} } = props

  const list = order.list || []
  const total = order.total || 0
  const content = list.map((it, i) => {
    return <Product key={i} index={i} item={it} />
  })

  return (
    <PageView>
      <Header order={order} />
      {content}
      <Total total={total} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  margin-bottom: 16px;
`

export default Information
