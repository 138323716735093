import { useState } from 'react'
import styled from 'styled-components'

import { ContentDrawer } from 'drawer'
import { Bell } from 'icon'

const Announce = (props) => {
  const [visible, setVisible] = useState(false)
  const { doc = {} } = props
  const { status = '', title = '', detail = '' } = doc

  if (status !== 'active' || title === '') {
    return <div />
  }

  return (
    <>
      <PageView onClick={() => setVisible(true)}>
        <ContentView>
          <Icon>
            <Bell />
          </Icon>
          <Text>{title || ''}</Text>
        </ContentView>
      </PageView>
      <ContentDrawer
        visible={visible}
        text={detail}
        onClose={() => setVisible(false)}
      />
    </>
  )
}

const PageView = styled.div`
  width: 100%;
  color: white;
  background-color: ${(p) => p.theme.color.red};
  padding: 12px 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  top: -26px;
  position: relative;
`

const ContentView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 8px;
`

const Icon = styled.span`
  flex: 0 0 24px;
  display: flex;
  align-items: center;
`

const Text = styled.div`
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 18px;
  max-height: 36px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  flex: 0 0 calc(100% - 24px);
`

export default Announce
