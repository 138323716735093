import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http, timer } from 'utils'
import { config } from 'config'

import market from './Market'
import BaseStore from './BaseStore'

const original = {
  setting: {
    open_type: 'month',
    next_value: 0.5,
    approve_type: 'auto',
  },
}
let state
export class MarketSetting extends BaseStore {
  constructor() {
    super()
    this.observable({
      announce: {},
      setting: cloneDeep(original.setting),
      sale_setting: cloneDeep(original.setting),
    })
    state = this
  }

  async getAnnounce(market_id) {
    const url = `${config.api}/v1/public/market/announce/${market_id}`
    const res = await http.get(url)

    const data = res.body || {}
    runInAction(() => {
      state.announce = data
    })
  }

  async getSaleSetting(market_id) {
    const { sale_setting } = this.toJS()
    if (sale_setting.market_id === +market_id) {
      return
    }

    let data = {}
    if (market_id) {
      const url = `${config.api}/v1/public/market/setting/sale/${market_id}`
      const res = await http.get(url)

      data = res.body || {}
    }

    runInAction(() => {
      state.sale_setting = data
    })
  }

  async getSetting(market_id) {
    const { setting } = this.toJS()
    if (setting.market_id === +market_id) {
      return
    }
    const url = `${config.api}/v1/public/market/setting/full/${market_id}`
    const res = await http.get(url)

    const data = res.body || {}
    const saleSetting = data.setting || {}
    const round_list = saleSetting.round_list || []
    saleSetting.start_at = timer.get(saleSetting.start_at)
    saleSetting.original_start_at = timer.get(saleSetting.original_start_at)
    saleSetting.finish_at = timer.get(saleSetting.finish_at)
    saleSetting.set_finish_at = timer.get(saleSetting.set_finish_at)
    saleSetting.round_list = round_list.map((it) => {
      const tag = it.tag || {}
      it.tag = {
        start_at: timer.get(tag.start_at),
        finish_at: timer.get(tag.finish_at),
      }
      return it
    })

    market.setMarketShortInfo(data.market_info)
    runInAction(() => {
      state.setting = saleSetting
    })
  }
}

export default new MarketSetting()
