import styled from 'styled-components'

const Pin = (props) => {
  const { text, size = 20, color, tag, style = {}, onClick } = props
  const s = `${size}px`
  style.width = s
  style.height = s
  style.backgroundColor = color

  const click = () => {
    onClick(tag)
  }

  const css = onClick ? 'click' : ''

  return (
    <PinView
      style={style}
      onClick={onClick ? click : undefined}
      className={css}
    >
      {text}
    </PinView>
  )
}

const PinView = styled.span`
  position: absolute;
  color: white;
  background-color: ${(p) => p.theme.color.green};
  border-radius: 50%;
  opacity: 0.7;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  &.click {
    cursor: pointer;
  }
`

export default Pin
