import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

import OpenTag from './OpenTag'
import MarketArea from './MarketArea'

const MarketDetail = (props) => {
  const { item = {} } = props

  return (
    <Detail>
      <Row align="middle">
        <Col span={13}>
          <OpenTag item={item} />
        </Col>
        <Col span={11}>
          <Info>
            <MarketArea item={item} />
          </Info>
        </Col>
      </Row>
    </Detail>
  )
}

const Detail = styled.div``

const Info = styled.div`
  text-align: right;
`

export default MarketDetail
