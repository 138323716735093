import styled from 'styled-components'
import { Row, Col } from 'antd'

import { format } from 'utils'

import Product from './Product'

const UnitInfo = (props) => {
  const {
    bookingType,
    doc = {},
    order = {},
    shop = {},
    filter,
    promotion_list,
  } = props

  const getText = (label, text) => {
    return (
      <Col lg={8} md={8} xs={8}>
        <Label>{label}</Label>
        <Value>{text}</Value>
      </Col>
    )
  }

  const toText = (val) => {
    return val || ''
  }

  const product_list = doc.product_list || []
  const content = product_list.map((it, i) => {
    return (
      <Product
        key={i}
        unit={doc}
        shop={shop}
        order_list={order.list}
        doc={it}
        filter={filter}
        promotion_list={promotion_list}
      />
    )
  })

  const text = format.toBookingType(bookingType)

  const be_tall = toText(doc.be_tall)
  const tall = be_tall === '' ? '-' : `${be_tall} ม.`

  const be_wide = toText(doc.be_wide)
  const be_long = toText(doc.be_long)
  const size = `${be_wide}x${be_long} ม.`
  return (
    <div>
      <Title>{doc.name || ''}</Title>
      <PageView>
        <Row align="middle">
          {getText('ประเภท', text)}
          {getText('ขนาด ก x ย', size)}
          {getText('ความสูงจำกัด', tall)}
        </Row>
      </PageView>
      {content}
    </div>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 45px 20px 20px 20px;
  margin-bottom: 16px;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
`

const Title = styled.div`
  min-width: 64px;
  max-width: 145px;
  height: 64px;
  font-size: 32px;
  font-weight: bold;
  color: white;
  background-color: ${(p) => p.theme.color.green};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 32px;
  margin: 0 auto;
`

const Label = styled.div`
  width: 100%;
  font-size: 12px;
  color: ${(p) => p.theme.color_level.grey.high};
  text-align: center;
`

const Value = styled.div`
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  color: ${(p) => p.theme.color.green};
  text-align: center;
`

export default UnitInfo
