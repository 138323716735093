import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import queryString from 'query-string'

import Footer from 'footer'
import { Meta, BackHeader, Title, Content } from 'components/display'

const AboutUs = (props) => {
  const navigate = useNavigate()
  const location = useLocation()

  const onBack = () => {
    const { source } = queryString.parse(location.search)
    const path = source ? source : '/'
    navigate(path)
  }
  const description = `
  MARGET(มาร์เก็ต) คือ ทีมสตาร์ทอัพที่อยากจะทำให้เรื่องตลาด เป็นเรื่องง่าย
  ช่วยให้พ่อค้าแม่ค้าจองล็อกในตลาดนัดและพื้นที่เช่า
  ทั้งรายวัน รายเดือน บูธในห้าง คอมมูนิตี้มอลล์ และงานอีเวนท์
  ได้ง่ายสะดวกรวดเร็วผ่านมือถือ สามารถทำได้ทุกที่ทุกเวลา เรามุ่งมั่นที่จะเป็นศูนย์รวมตลาดนัด
  พื้นที่เช่าทั้งหมด
  เพื่ออำนวยความสะดวกแก่พ่อค้าแม่ค้าทุกคนจะได้เข้ามาเลือกจับจองหาพื้นที่ทำมาหากินที่ถูกใจ
  เพิ่มโอกาสพบเจอลูกค้ากลุ่มใหม่ เพิ่มรายได้ต่อยอดในอนาคต และแน่นอนราคาจริงใจ
  ราคาเท่าหน้างาน เพราะคุณจะได้ติดต่อโดยตรงกับเจ้าของตลาด
  เจ้าของพื้นที่ เจ้าของงาน หรือผู้รับผิดชอบอย่างเป็นทางการ
  `
  return (
    <PageView>
      <Meta title="เกี่ยวกับเรา" description={description} />
      <BackHeader onClick={onBack} isShort={true} />
      <Content isHeader={true}>
        <ContentView>
          <Title text="เกี่ยวกับเรา" />
          <Label>MARGET จองล็อกออนไลน์</Label>
          <Text>
            เรามุ่งมั่นที่จะสร้างนวัตกรรมการจองพื้นที่ให้เป็นเรื่องง่าย
            สะดวกทั้งผู้ปล่อยเช่าและผู้มาเช่า
          </Text>

          <Label>เราคือใคร</Label>
          <Text>{description}</Text>

          <Label>จองล็อกกับเราดียังไง</Label>
          <List>
            <li>ง่าย ได้หลายที่ ในคราเดียว</li>
            <li>ราคา เท่ากับหน้างาน ที่ตลาด</li>
            <li>แอดมินใจดี ตอบเร็ว</li>
            <li>วางแผนลงยาว บูธ บริษัทก็ลงง่าย</li>
          </List>

          <Label>ติตต่อเรา</Label>
          <Text>
            Email: <a href="mailto: Bymarget@gmail.com">Bymarget@gmail.com</a>
          </Text>
          <Text>
            คุณเดือน: <a href="tel:086-990-0387">086-990-0387</a>
          </Text>
          <Text>
            คุณจิม: <a href="tel:081-441-9492">081-441-9492</a>
          </Text>
          <Text>
            คุณออย: <a href="tel:087-704-8445">087-704-8445</a>
          </Text>
        </ContentView>
      </Content>
      <Footer />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  max-width: ${(p) => p.theme.max};
  margin: 0 auto;
  color: ${(p) => p.theme.color_level.grey.high};
  background-color: white;
`

const ContentView = styled.div`
  width: 100%;
  min-height: 80vh;
`

const Label = styled.div`
  font-weight: bold;
  margin-top: 20px;
`

const Text = styled.div`
  a {
    color: ${(p) => p.theme.color.blue};
  }
`

const List = styled.ul``

export default AboutUs
