import styled from 'styled-components'

import Daily from './Daily'
import Monthly from './Monthly'
import Round from './Round'

const Filter = (props) => {
  const {
    filter = {},
    bookingType,
    bookingMenu,
    roundMenu,
    onBookingType,
    onFilter = () => {},
  } = props

  const disabled = bookingMenu.length < 2

  const getChoice = (val) => {
    switch (bookingType) {
      case 'daily':
        return (
          <Daily
            filter={filter}
            bookingType={bookingType}
            bookingMenu={bookingMenu}
            disabled={disabled}
            onBookingType={onBookingType}
            onFilter={onFilter}
          />
        )
      case 'round':
        return (
          <Round
            filter={filter}
            bookingType={bookingType}
            bookingMenu={bookingMenu}
            roundMenu={roundMenu}
            disabled={disabled}
            onBookingType={onBookingType}
            onFilter={onFilter}
          />
        )
      default:
        return (
          <Monthly
            filter={filter}
            bookingType={bookingType}
            bookingMenu={bookingMenu}
            disabled={disabled}
            onBookingType={onBookingType}
            onFilter={onFilter}
          />
        )
    }
  }

  return <PageView>{getChoice()}</PageView>
}

const PageView = styled.div`
  width: 100%;
  padding-top: 8px;
`

export default Filter
