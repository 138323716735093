import { useState, useEffect } from 'react'
import styled from 'styled-components'

import { PrimaryBtn } from 'components/button'
import { BaseDrawer } from 'drawer'
import { validator } from 'utils'

import ShopInfo from './ShopInfo'
import ShopType from './ShopType'
import ShopImage from './ShopImage'
import rules from './rules'

const ShopEditor = (props) => {
  const [validate, setValidate] = useState({})
  const { title, visible, loading, doc, onClose, onSave, onChange } = props

  useEffect(() => {
    if (visible) setValidate({})
  }, [visible])

  const onValidate = () => {
    const checker = validator.process(rules, doc)
    if (checker.invalid) {
      setValidate(checker.errors)
      return
    }

    onSave()
  }

  const onCh = (data, valid) => {
    onChange(data)
    setValidate({ ...valid })
  }

  if (!visible) return <div />

  return (
    <BaseDrawer title={title} visible={true} onClose={onClose}>
      <PageView>
        <ShopInfo doc={doc} validate={validate} onChange={onCh} />
        <ShopType doc={doc} validate={validate} onChange={onCh} />
        <ShopImage doc={doc} validate={validate} onChange={onCh} />

        <Button>
          <Btn>
            <PrimaryBtn loading={loading} text="บันทึก" onClick={onValidate} />
          </Btn>
        </Button>
      </PageView>
    </BaseDrawer>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 24px 16px 100px 16px;
`

const Button = styled.div`
  width: 100%;
  padding: 24px 0px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  background-color: white;
`

const Btn = styled.div`
  width: 100%;
  max-width: 214px;
  margin: 0 auto;
`

export default ShopEditor
