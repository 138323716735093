import styled from 'styled-components'
import { Row } from 'antd'

import { PrimaryBtn } from 'components/button'
import { Col } from 'components/display'

const NewOffer = (props) => {
  const { order = {}, onClick } = props
  const { status } = order

  if (status !== 'offer') {
    return <div />
  }

  const list = order.offer_list || []

  return (
    <PageView>
      <Row align="middle">
        <Col span={12} position="left">
          <Text>คุณได้รับ {list.length} ข้อเสนอใหม่ จากเรา</Text>
        </Col>
        <Col span={12} position="right">
          <PrimaryBtn onClick={onClick} text="ดูข้อเสนอ" />
        </Col>
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  margin: 14px 0px;
  padding: 16px;
  border-radius: 16px;
  background-color: white;
`

const Text = styled.div`
  width: 100%;
  font-size: 14px;
  color: ${(p) => p.theme.color_level.grey.high};
`

export default NewOffer
