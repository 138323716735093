import { useState, useCallback } from 'react'
import styled from 'styled-components'

import { file } from 'utils'
import { config } from 'config'
import { PrimaryBtn } from 'components/button'

import BaseDrawer from './BaseDrawer'
import Layout from './Layout'
import { toNumber } from './location'
// aspect = height / width
const CropperImage = (props) => {
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState({ width: 0, height: 0 })
  const [imgLayout, setImgLayout] = useState({ width: 0, height: 0 })
  const [size, setSize] = useState({ width: 0, height: 0 })
  const [position, setPosition] = useState({ x: 0, y: 0 })

  const { visible, img_url, aspect, onClose, onConfirm } = props

  const onSave = async () => {
    const { width, height } = image
    const src = { value: img_url, width, height }
    const ratioW = toNumber(width / imgLayout.width)
    const ratioH = toNumber(height / imgLayout.height)
    const sizeW = size.width * ratioW
    const sizeH = size.height * ratioH
    const x = position.x * ratioW
    const y = position.y * ratioH

    const newImg = {
      x,
      y,
      width: sizeW,
      height: sizeH,
    }

    setLoading(true)
    const value = await file.crop(src, newImg)
    const { text } = await file.compress({ value, limit: config.limit.file })
    setLoading(false)
    onConfirm(text)
  }

  const onImage = useCallback((data) => setImage(data), [])
  const onLayout = useCallback((data) => setImgLayout(data), [])
  const onSize = useCallback((data) => setSize(data), [])
  const onPosition = useCallback((data) => setPosition(data), [])

  return (
    <BaseDrawer
      title="เลือกพื้นที่ในรูป"
      visible={visible}
      height="95%"
      onClose={onClose}
    >
      <PageView>
        <PageArea>
          <Layout
            visible={visible}
            img_url={img_url}
            aspect={aspect}
            onImage={onImage}
            onLayout={onLayout}
            onSize={onSize}
            onPosition={onPosition}
          />
        </PageArea>

        <Button>
          <PrimaryBtn loading={loading} text="ยืนยัน" onClick={onSave} />
        </Button>
      </PageView>
    </BaseDrawer>
  )
}

const PageView = styled.div`
  width: 100%;
  background-color: white;
`

const PageArea = styled.div``

const Button = styled.div`
  padding: 0px 0px 24px 0px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  text-align: center;

  button {
    width: 85%;
    max-width: 320px;
    margin: 0 auto;
  }
`

export default CropperImage
