export const toPosition = (x1, y1, size) => {
  const ratio = toNumber(size / 2)
  const newX = x1 + ratio
  const newY = y1 + ratio

  const x = newX < 0 ? 0 : newX
  const y = newY < 0 ? 0 : newY

  return { x, y }
}

export const toLocalPosition = (x1, y1, size) => {
  const ratio = toNumber(size / 2)
  const newX = x1 - ratio
  const newY = y1 - ratio

  const x = newX < 0 ? 0 : newX
  const y = newY < 0 ? 0 : newY
  return { x, y }
}

export const toNumber = (val) => {
  return Math.floor(val * 1000) / 1000
}
