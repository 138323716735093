import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { Checker } from 'components/input'

import ConsentDialog from './ConsentDialog'

const Consent = (props) => {
  const [consent, setConsent] = useState('')
  const { isConsent, onChange } = props

  const { privacy, terms } = props.consent.toJS()
  const title = consent === 'privacy' ? privacy.title : terms.title
  const content = consent === 'privacy' ? privacy.content : terms.content
  return (
    <View>
      <Checker value={isConsent} onChange={onChange} />
      <ConsentText>
        ฉันยอมรับ
        <ConsentLink onClick={() => setConsent('terms')}>
          เงื่อนไขการให้บริการ
        </ConsentLink>
        และรับทราบ
        <ConsentLink onClick={() => setConsent('privacy')}>
          นโยบายความเป็นส่วนตัว
        </ConsentLink>
      </ConsentText>

      <ConsentDialog
        visible={consent !== ''}
        title={title}
        content={content}
        onClose={() => setConsent('')}
      />
    </View>
  )
}

const View = styled.div`
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  color: ${(props) => props.theme.color_level.grey.high};
  text-align: left;
  margin-top: 10px;
`

const ConsentText = styled.div`
  margin-left: 18px;
`

const ConsentLink = styled.span`
  text-decoration: underline;
  margin: 0px 4px;
  cursor: pointer;
`

export default inject('consent')(observer(Consent))
