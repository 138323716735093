import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { useParams, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import Footer from 'footer'
import { helper } from 'utils'
import { Meta } from 'components/display'
import MarketList from 'pages/market/MarketList'

const MarketTypeList = (props) => {
  const [loading, setLoading] = useState(false)
  const { market_type_id } = useParams()
  const location = useLocation()

  const onLoad = useCallback(
    async (id) => {
      try {
        setLoading(true)
        await Promise.all([
          props.market.getMarketType(id),
          props.market_type.getMarketType(),
        ])
      } catch (e) {
        console.log('e', e.message)
      }
      setLoading(false)
    },
    [props.market]
  )

  useEffect(() => {
    onLoad(market_type_id)
  }, [onLoad, market_type_id])

  useEffect(() => {
    helper.gotoCode(location.search)
  }, [])

  const { market_list = [] } = props.market.toJS()
  const { market_type_list = [] } = props.market_type.toJS()

  const getMarketTypeName = () => {
    const id = +market_type_id
    const market_type = market_type_list.find((it) => it.market_type_id === id)

    return market_type ? market_type.name : 'ประเภทตลาดmทั้งหมด'
  }

  const name = getMarketTypeName()
  return (
    <PageView>
      <Meta
        loading={loading}
        title={`ประเภทตลาด - ${name}`}
        description="ประเภทตลาด"
      />
      <MarketList
        loading={loading}
        source={`/market-type/${market_type_id}`}
        title={name}
        market_list={market_list}
      />
      <Footer />
    </PageView>
  )
}

const PageView = styled.div``

export default inject('market', 'market_type')(observer(MarketTypeList))
