import styled from 'styled-components'

import FullDialog from 'dialog/FullDialog'
import { HtmlContent } from 'components/display'

const ConsentDialog = (props) => {
  const { visible, title, content, onClose } = props

  const header = <Title>{title}</Title>
  return (
    <FullDialog title={header} visible={visible} onClose={onClose}>
      <Content id="consent">
        <HtmlContent content={content} />
      </Content>
    </FullDialog>
  )
}

const Content = styled.div`
  width: 100%;
`

const Title = styled.div`
  width: 100%;
  text-align: center;
  color: ${(props) => props.theme.color.blue};
  font-weight: bold;
  font-size: 18px;
`

export default ConsentDialog
