import { useState, useEffect, useCallback } from 'react'
import { observer, inject } from 'mobx-react'
import { Routes, Route } from 'react-router-dom'

import Loading from 'components/loading'
import Home from 'pages/home'
import HotMarketList from 'pages/market/hot'
import MarketTypeList from 'pages/market/type'
import MarketAreaList from 'pages/market/area'
import SearchMarket from 'pages/market/search'
import MarketInfo from 'pages/marketInfo'
import ProductPicker from 'pages/productPicker'
import ContactUs from 'pages/contact'
import AboutUs from 'pages/about'
import Faq from 'pages/faq'
import ConsentCookie from 'pages/consent/cookie'
import LineLoginChecker from 'pages/checker/line'
import MainLayout from 'menu'

import AccountApp from './AccountApp'

const App = (props) => {
  const [loading, setLoading] = useState(false)

  const isLogin = props.member.isLogin()

  const onCheck = useCallback(async () => {
    if (isLogin) return
    try {
      setLoading(true)
      const user = await props.member.checkLoginUser()
      if (user) {
        const { user_id } = user
        props.order.checkUserShop({ user_id })
      }
    } catch (e) {
      console.log('check user:', e.message)
    }
    setLoading(false)
  }, [props.member, isLogin])

  useEffect(() => {
    onCheck()
  }, [onCheck])

  if (loading) {
    return (
      <Routes>
        <Route path="/*" element={<Loading />} />
      </Routes>
    )
  }

  return (
    <MainLayout {...props}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/line-login" element={<LineLoginChecker />} />
        <Route path="/hot-market" element={<HotMarketList />} />
        <Route
          path="/market-type/:market_type_id"
          element={<MarketTypeList />}
        />
        <Route path="/market-area" element={<MarketAreaList />} />
        <Route
          path="/market-area/:market_area_id"
          element={<MarketAreaList />}
        />
        <Route path="/market-search" element={<SearchMarket />} />
        <Route path="/market/:market_id" element={<MarketInfo />} />
        <Route path="/market/:market_id/product" element={<ProductPicker />} />
        <Route path="/consent/cookie" element={<ConsentCookie />} />

        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/faq" element={<Faq />} />

        <Route path="/account/*" element={<AccountApp />} />
      </Routes>
    </MainLayout>
  )
}

export default inject('member', 'order')(observer(App))
