import styled from 'styled-components'

import { NotFound } from 'icon'

const ContentView = (props) => {
  const { text } = props
  return (
    <PageView>
      <NotFound />
      <Text>{text}</Text>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding-top: 8%;
  text-align: center;
`

const Text = styled.div`
  width: 100%;
  margin-top: 26px;
  font-size: 24px;
  font-weight: bold;
  color: ${(p) => p.theme.color_level.grey.low};
`

export default ContentView
