import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  market_id: undefined,
  list: [],
  total: 0,
  note: '',
  market_note: '',

  name: '',
  mobile: '',
  tax_required: false,
  tax_id: '',
  tax_name: '',
  branch_code: '',
  company_mobile: '',
  tax_address: '',
  receive_type: 'onsite',
  receive_name: '',
  receive_mobile: '',
  receive_address: '',
}

let state
export class MyOrder extends BaseStore {
  constructor() {
    super()
    this.observable({
      order: cloneDeep(original),
      display: {
        category: '',
        page: 1,
        per_page: 20,
        list: [],
      },
    })
    state = this
  }

  async getOrderInfo(code) {
    const { order = {} } = this.toJS()
    if (order.code === code) {
      return order
    }

    const url = `${config.api}/v1/user/market/order/${code}/code`
    const resp = await http.get(url)
    const doc = resp.body || {}

    this.setOrder(doc)
    return doc
  }

  async updatePayment() {
    const { order = {} } = this.toJS()
    const json = { image: order.payment_url }

    const url = `${config.api}/v1/user/market/order/payment/${order.order_id}`
    const resp = await http.put(url, { json })
    const { payment_url, status } = resp.body || {}

    order.payment_url = payment_url
    order.status = status
    this.setOrder(order)
  }

  async updateOfferPayment({ list, total }) {
    const { order = {} } = this.toJS()
    const json = { image: order.payment_url, list, total }

    const url = `${config.api}/v1/user/market/order/offer/${order.order_id}`
    const resp = await http.put(url, { json })
    const data = resp.body || {}

    order.payment_url = data.payment_url
    order.status = data.status
    order.list = data.list
    order.total = data.total
    this.setOrder(order)
  }

  async cancelOrder() {
    const { order = {} } = this.toJS()

    const url = `${config.api}/v1/user/market/order/cancel/${order.order_id}`
    const resp = await http.put(url, {})
    const { status } = resp.body || {}

    order.status = status
    this.setOrder(order)
  }

  async getOrderList({ category, per_page = 20, page = 1 }) {
    const q = `?per_page=${per_page}&&page=${page}`
    const url = `${config.api}/v1/user/market/order/list/${category}${q}`
    const resp = await http.get(url)
    const list = resp.body || []

    runInAction(() => {
      state.order = cloneDeep(original)
      state.display = {
        category,
        page,
        per_page,
        list,
      }
    })
  }

  setOrder(data) {
    runInAction(() => {
      state.order = data
    })
  }
}

export default new MyOrder()
