import React from 'react'
/* eslint-disable */

const Logo = (props) => {
  const { size = 1, color = '#ED3034' } = props

  const width = `${Math.ceil(12 * size)}`
  const height = `${Math.ceil(17 * size)}`
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 0.962646C2.69158 0.962646 0 3.6797 0 7.01944C0 8.08846 0.279796 9.13947 0.809112 10.0588C0.93251 10.2731 1.07045 10.482 1.21916 10.6796L5.73931 16.7845H6.26066L10.7808 10.6797C10.9295 10.482 11.0675 10.2732 11.1909 10.0588C11.7202 9.13947 12 8.08846 12 7.01944C12 3.6797 9.30842 0.962646 6 0.962646ZM6 9.02807C4.90283 9.02807 4.0102 8.127 4.0102 7.01944C4.0102 5.91188 4.90283 5.01081 6 5.01081C7.09717 5.01081 7.9898 5.91188 7.9898 7.01944C7.9898 8.127 7.09717 9.02807 6 9.02807Z"
        fill={color}
      />
    </svg>
  )
}
// eslint-enable
export default Logo
