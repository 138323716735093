import React from 'react'
import styled from 'styled-components'

const MarketType = (props) => {
  const { item = {} } = props
  const { market_type_list = [] } = item

  const type_list = market_type_list.map((it, i) => {
    return <ImgType key={i} src={it.img_url} />
  })

  return <ReverseTypeList>{type_list}</ReverseTypeList>
}

const ReverseTypeList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
`

const ImgType = styled.img`
  width: 32px;
  height: 32px;
  margin-left: 4px;
`

export default MarketType
