import styled from 'styled-components'

import { Marget, Back } from 'icon'

const BackHeader = (props) => {
  const { onClick, children, title, color, isShort = false } = props

  const backgroundColor = color
  const content = children ? <Component>{children}</Component> : undefined
  const css = isShort ? { maxWidth: '800px' } : undefined
  const titleContent = title ? <Title>{title}</Title> : <Marget size={0.8} />
  return (
    <PageView style={{ backgroundColor }}>
      <ContentBody style={css}>
        <ContentView>
          <Btn onClick={onClick}>
            <Back />
          </Btn>

          <Content>{titleContent}</Content>
        </ContentView>

        {content}
      </ContentBody>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  min-height: 65px;
  padding: 10px 24px;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;
  display: flex;
  align-items: center;
  background-color: ${(p) => p.theme.color.backgroundColor};
`

const ContentBody = styled.div`
  width: 100%;
  max-width: ${(p) => p.theme.max};
  margin: 0 auto;
`

const ContentView = styled.div`
  width: 100%;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.color_level.grey.high};
`

const Btn = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: absolute;
  padding: 5px 5px 5px 0px;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Component = styled.div`
  width: 100%;
  margin-top: 16px;
`

const Title = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
`

export default BackHeader

/*
<Row justify="space-between" align="middle">
  <Col lg={1} md={2} xs={3}>
    <Btn onClick={onClick}>
      <Back />
    </Btn>
  </Col>
  <Col lg={23} md={22} xs={21}>
    {children || text}
  </Col>
</Row>
*/
