import styled from 'styled-components'

import { product } from 'utils'

import Label from './Label'

const ShopView = (props) => {
  const { shop = {} } = props

  const getText = (label, text) => {
    return (
      <Text>
        {label}:<span>{text}</span>
      </Text>
    )
  }

  const productType = product.toProductType(shop)
  const shopType = product.toShopType(shop)
  const name = shop.shop_name || ''

  const shopName = name === '' ? 'ไม่ระบุ' : name
  const shopTypeText = shopType === '' ? 'ไม่ระบุ' : shopType
  const content = shop.img_list.map((it, i) => {
    return <Image key={i} src={it} />
  })
  return (
    <PageView>
      <Label text="ข้อมูลร้านค้า" />
      <Info>
        <Name>{shop.name}</Name>
        {getText('หมวดหมู่', productType)}
        {getText('ร้าน', shopName)}
        {getText('อุปกรณ์หรือสิ่งของภายในร้าน', shopTypeText)}
        <ImageList>{content}</ImageList>
      </Info>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  margin-top: 16px;
`

const Info = styled.div`
  width: 100%;
  margin-top: 16px;
  background-color: white;
  padding: 16px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`

const Name = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 16px;
  color: ${(p) => p.theme.color.blue};
`

const Text = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  color: ${(p) => p.theme.color_level.grey.high};
  span {
    margin-left: 4px;
    font-weight: 400;
  }
`

const ImageList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  margin-top: 20px;
`

const Image = styled.img`
  width: 88px;
  border-radius: 4px;
  margin-bottom: 16px;
`

export default ShopView
