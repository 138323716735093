import styled from 'styled-components'

import { PrimaryBtn } from 'components/button'

const Button = (props) => {
  const { loading, disabled, text, onClick } = props

  return (
    <ButtonSection>
      <Center>
        <PrimaryBtn
          loading={loading}
          disabled={disabled}
          text={text}
          onClick={onClick}
        />
      </Center>
    </ButtonSection>
  )
}

const ButtonSection = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0px;
  left: 0px;
  background-color: ${(p) => p.theme.color.backgroundColor};
  padding: 16px 0px;
  z-index: 2;
`

const Center = styled.div`
  width: 213px;
  margin: 0 auto;
`

export default Button
