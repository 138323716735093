import { observer, inject } from 'mobx-react'
import { Routes, Route } from 'react-router-dom'

import Register from 'pages/register'
import Welcome from 'pages/welcome'
import Profile from 'pages/account/profile'
import Personal from 'pages/account/information'
import Shop from 'pages/account/shop/display'
import ShopList from 'pages/account/shop/list'
import SubmitOrder from 'pages/order/submit'
import BookingList from 'pages/account/bookingList'
import BookingPage from 'pages/account/booking'
import ConsentPage from 'pages/account/consent'
import Authentication from 'pages/authentication'

const App = (props) => {
  const isLogin = props.member.isLogin()
  if (isLogin === false) {
    return (
      <Routes>
        <Route path="/*" element={<Authentication />} />
      </Routes>
    )
  }

  return (
    <Routes>
      <Route path="/" element={<Profile />} />
      <Route path="/register" element={<Register />} />
      <Route path="/welcome" element={<Welcome />} />
      <Route path="/personal" element={<Personal />} />
      <Route path="/shop" element={<ShopList />} />
      <Route path="/shop/:shop_id" element={<Shop />} />
      <Route path="/order" element={<SubmitOrder />} />
      <Route path="/booking" element={<BookingList />} />
      <Route path="/booking/:code" element={<BookingPage />} />
      <Route path="/booking/:code/:payment" element={<BookingPage />} />
      <Route path="/consent/:typ" element={<ConsentPage />} />
    </Routes>
  )
}

export default inject('member')(observer(App))
