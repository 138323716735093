import styled from 'styled-components'

import { format } from 'utils'

const Total = (props) => {
  const { total } = props

  return (
    <PageView>
      <Price>
        รวม <span>{format.toDigi(total)}</span> บาท
      </Price>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Price = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: right;

  span {
    margin: 0px 8px;
  }
`

export default Total
