import { useState } from 'react'
import styled from 'styled-components'

import { Row, Col, Picture } from 'components/display'
import { ImgPicker } from 'components/picker'
import CropperImage from 'components/cropper'

import ContentView from './ContentView'

const MarketImage = (props) => {
  const [selected, setSelected] = useState(undefined)

  const { doc = {}, validate = {}, onChange } = props

  const img_list = doc.img_list || []

  const onRemove = (i) => {
    img_list.splice(i, 1)

    doc.img_list = img_list
    onChange(doc)
  }

  const onCompleteImg = (value) => {
    img_list.push(value)
    doc.img_list = img_list
    delete validate.img_list
    onChange(doc, validate)
    setSelected(undefined)
  }

  const onSelectImg = async (img, name, data) => {
    setSelected(img)
  }

  const onSelect = (i) => {
    const len = img_list.length
    if (len === 1) {
      return
    }

    if (i !== len - 1) {
      const current = img_list[i]
      const next = img_list[i + 1]
      img_list[i] = next
      img_list[i + 1] = current
    } else {
      const current = img_list[i]
      img_list.splice(i, 1)
      img_list.splice(0, 0, current)
    }

    doc.img_list = img_list
    onChange(doc, validate)
  }

  const content = img_list.map((img, i) => {
    return (
      <Col key={i} lg={4} md={6} xs={8} position="space">
        <Picture img={img} tag={i} onRemove={onRemove} onClick={onSelect} />
      </Col>
    )
  })

  const err = validate.img_list ? <Error>{validate.img_list}</Error> : undefined

  return (
    <PageView>
      <ContentView id="img_list">
        <Row>
          <Col span={12}>
            <ImgPicker
              text="เพิ่มรูปสินค้า"
              onSelect={onSelectImg}
              disabled={img_list.length >= 5}
            />
            {err}
          </Col>
          <Col span={12}>
            <Note>
              จำกัดไม่เกิน 5 รูป และต้อง เป็นไฟล์ .jpg, .jpeg, .png เท่านั้น
            </Note>
          </Col>
        </Row>

        <ImageList>
          <Row>{content}</Row>
        </ImageList>
      </ContentView>
      <CropperImage
        visible={selected !== undefined}
        img_url={selected}
        aspect={1}
        onClose={() => setSelected(undefined)}
        onConfirm={onCompleteImg}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const ImageList = styled.div`
  width: 100%;
  padding-top: 20px;
`

const Note = styled.div`
  width: 100%;
  color: ${(p) => p.theme.color_level.grey.high};
  padding-left: 16px;
  line-height: 1.3;
`

const Error = styled.div`
  width: 100%;
  font-size: 14px;
  color: ${(p) => p.theme.color.error};
  line-height: 1.3;
  padding-top: 5px;
`

export default MarketImage
