import { window } from 'global/window'

import { config } from 'config'

import { storage } from './storage'

const { line = {} } = config

let init
let isLoggedIn
let getProfile
let login

if (!window.isBackend) {
  const liff = window.liff

  init = async () => {
    await liff.init({ liffId: line.liffId })
  }

  isLoggedIn = () => {
    return liff.isLoggedIn()
  }

  getProfile = async () => {
    if (liff.isLoggedIn()) {
      const setting = storage.load('line')
      const profile = await liff.getProfile()

      return { profile, setting }
    }

    return undefined
  }

  login = ({ redirect = '/', type = 'login', option = {} } = {}) => {
    storage.save('line', { redirect, type, option })

    const { origin = '' } = window.location
    const redirectUri = `${origin}/line-login`

    liff.login({ redirectUri })
  }

  init()
}

export { init, isLoggedIn, getProfile, login }
