import numeral from 'numeral'
import moment from 'moment'

export class Format {
  isNull(val) {
    if (val === undefined || val === null || val === '') return true
    else return false
  }

  toInt(val) {
    if (this.isNull(val) || isNaN(+val)) return '0'
    else return numeral(val).format('0')
  }

  toDigi(val) {
    if (this.isNull(val) || isNaN(+val)) return '0'
    else return numeral(val).format('0,0')
  }

  precision(val) {
    return Math.floor(val * 100) / 100
  }

  toMoney(val) {
    if (this.isNull(val) || isNaN(+val)) return '0'
    else return numeral(val).format('0,0.00')
  }

  toText(val) {
    if (this.isNull(val)) return '-'
    else return val
  }

  toDatetime(val) {
    if (this.isNull(val)) return '-'

    const mm = moment(val)
    const year = mm.year() + 543
    const date = mm.format('DD/MM')
    const time = mm.format('HH:mm')
    return `${date}/${year} เวลา ${time} น.`
  }

  toShortDay(val) {
    if (this.isNull(val)) return '-'
    else if (typeof val === 'string') return val.slice(0, 3)
    else return moment(val).format('DD')
  }

  toShortMonth(val) {
    if (this.isNull(val)) return '-'
    else if (typeof val === 'string') return val.slice(0, 3)
    else return moment(val).format('MM')
  }

  toDate(val) {
    if (this.isNull(val)) return '-'
    else return moment(val).format('DD/MM/YYYY')
  }

  toNameDate(val) {
    if (this.isNull(val)) return '-'
    else return moment(val).format('DD MMM YYYY')
  }

  toWeekDate(val) {
    if (this.isNull(val)) return '-'
    else return moment(val).format('dddd')
  }

  toTime(val) {
    if (this.isNull(val)) return '-'
    else return moment(val).format('HH:mm')
  }

  toUsername(user = {}) {
    const name = user.name || ''
    const surname = user.surname || ''
    return `${name} ${surname}`
  }

  limitText(text, size = 100) {
    text = text ? text : ''
    if (text.length <= size) return text

    return text.substr(0, size - 3) + '..'
  }

  upperText(text = '') {
    if (text.length === 0) return text
    return text[0].toUpperCase() + text.substring(1)
  }

  toThaiDate(val, txt) {
    if (this.isNull(val)) return ''

    const date = moment(val, txt)
    const m = date.month()
    const y = date.year() + 543
    const vYear = y % 100
    const year = vYear < 10 ? '0' + vYear : vYear
    return `${date.date()} ${SHORT_MONTH[m]} ${year}`
  }

  toThaiDatetime(val, txt) {
    if (this.isNull(val)) return ''

    const date = moment(val, txt)
    const m = date.month()
    const y = date.year() + 543
    const vYear = y % 100
    const year = vYear < 10 ? '0' + vYear : vYear

    const time = date.format('HH:mm')
    return `${date.date()} ${SHORT_MONTH[m]} ${year}, ${time}`
  }

  toThaiMonth(val, txt) {
    if (this.isNull(val)) return ''

    const date = moment(val, txt)
    const m = date.month()
    const y = date.year() + 543
    return `${MONTH[m]} ${y}`
  }

  toBookingType(val) {
    switch (val) {
      case 'daily':
        return 'รายวัน'
      case 'monthly':
        return 'รายเดือน'
      default:
        return 'รายอีเวนท์'
    }
  }

  toPattern(value, pattern) {
    if (!value) return ''

    let valueIndex = 0
    const list = String(pattern).split('')
    const newList = []

    for (let i = 0; i < pattern.length && valueIndex < value.length; i++) {
      if (list[i] === 'x') {
        newList.push(value[valueIndex++])
      } else {
        newList.push(list[i])
      }
    }

    return newList.join('')
  }

  clearPattern(text = '', ch = '-') {
    const value = (text || '').replaceAll(ch, '')

    return value
  }

  toMobile(mobile) {
    return this.toPattern(mobile, 'xxx-xxx-xxxx')
  }

  replaceAll(text, ch, to = '') {
    const val = `${text || ''}`
    const c = `${ch || ''}`

    return val.replaceAll(c, to)
  }
}

const SHORT_MONTH = {
  0: 'ม.ค.',
  1: 'ก.พ.',
  2: 'มี.ค.',
  3: 'เม.ย.',
  4: 'พ.ค.',
  5: 'มิ.ย.',
  6: 'ก.ค.',
  7: 'ส.ค.',
  8: 'ก.ย.',
  9: 'ต.ค.',
  10: 'พ.ค.',
  11: 'ธ.ค.',
}

const MONTH = {
  0: 'มกราคม',
  1: 'กุมภาพันธ์',
  2: 'มีนาคม',
  3: 'เมษายน',
  4: 'พฤษภาคม',
  5: 'มิถุนายน',
  6: 'กรกฎาคม',
  7: 'สิงหาคม',
  8: 'กันยายน',
  9: 'ตุลาคม',
  10: 'พฤศจิกายน',
  11: 'ธันวาคม',
}

export const format = new Format()
export default format
