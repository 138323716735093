import { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'

import { file } from 'utils'

import { toPosition, toNumber } from './location'

const def = () => {}

const CropLayout = (props) => {
  const [imgSize, setImgSize] = useState(undefined)
  const [imgLayout, setImgLayout] = useState(undefined)
  const [pin, setPin] = useState({ offsetWidth: 0, offsetHeight: 0 })
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [size, setSize] = useState({ width: 50, height: 50 })
  const [limitPosition, setLimitPosition] = useState({
    left: 0,
    top: 0,
    bottom: 1000,
    right: 1000,
  })

  let pointX = 0,
    pointY = 0,
    lastX = 0,
    lastY = 0
  let mode

  const {
    visible,
    img_url,
    aspect = 1,
    onImage = def,
    onLayout = def,
    onPosition = def,
    onSize = def,
  } = props

  const calcImgSize = useCallback(
    async (data) => {
      const img = await file.base64ToImage(data, true)

      setImgSize(img)

      const height = img.height || 0
      const width = img.width || 0
      onImage({ width, height })
    },
    [onLayout]
  )

  const updatePosition = useCallback(
    (xVal, yVal) => {
      const x = toNumber(xVal || 0)
      const y = toNumber(yVal || 0)
      setPosition({ x, y })
      onPosition({ x, y })
    },
    [onPosition]
  )

  const onInit = useCallback((e, node) => {
    const elem = node || imgLayout
    if (!elem) {
      return
    }
    // size of layout image
    const height = elem.height || 0
    const width = elem.width || 0

    const limit = {
      left: 0,
      top: 0,
      bottom: height,
      right: width,
    }
    setLimitPosition(limit)

    let w = width
    let h = toNumber(width * aspect)

    if (h > height) {
      h = height
      w = toNumber(h / aspect)
    }

    const x1 = width - w
    const y1 = height - h
    const centerX = x1 > 0 ? x1 / 2 : 0
    const centerY = y1 > 0 ? y1 / 2 : 0
    const x = toNumber(centerX)
    const y = toNumber(centerY)

    // update size of croper
    setSize({ width: w, height: h })
    // update position of croper
    setPosition({ x, y })

    onPosition({ x, y })
    onSize({ width: w, height: h })
    onLayout({ width, height })
  }, [])

  const onRef = useCallback((node) => {
    if (node) {
      setImgLayout(node)
    }
  }, [])

  const onRefPin = useCallback((node) => {
    if (node) setPin(node)
  }, [])

  useEffect(() => {
    if (visible) {
      calcImgSize(img_url)
    }
  }, [calcImgSize, visible, img_url])

  useEffect(() => {
    if (!imgSize || !imgLayout || !visible) {
      return
    }
    onInit(undefined, imgLayout)
  }, [onInit, visible, imgSize, imgLayout])

  const calcPosition = (evt) => {
    pointX = lastX - evt.clientX
    pointY = lastY - evt.clientY
    lastX = evt.clientX
    lastY = evt.clientY

    let x = pin.offsetLeft - pointX
    let y = pin.offsetTop - pointY

    if (x < limitPosition.left) x = limitPosition.left
    else if (x + size.width > limitPosition.right) {
      x = limitPosition.right - size.width
    }

    if (y < limitPosition.top) y = limitPosition.top
    else if (y + size.height > limitPosition.bottom) {
      y = limitPosition.bottom - size.height
    }

    const location = toPosition(x, y)

    return location
  }

  const onPinMove = (evt) => {
    const { x, y } = calcPosition(evt)

    updatePosition(x, y)
  }

  const onTouchStart = (evt) => {
    const obj = evt.changedTouches[0]
    lastX = obj.clientX
    lastY = obj.clientY
    document.ontouchmove = onTouchMove
  }

  const onTouchMove = (evt) => {
    const obj = evt.changedTouches[0]
    onPinMove(obj)
  }

  const onTouchEnd = () => {
    document.ontouchmove = null
    mode = undefined
  }

  const onMoveUp = (evt) => {
    document.onmouseup = null
    document.onmousemove = null
    mode = undefined
  }

  const onMove = (evt) => {
    if (mode !== 'moving') return
    evt.preventDefault()

    onPinMove(evt)
  }

  const onMoveDown = (evt) => {
    if (mode !== undefined) return

    mode = 'moving'
    lastX = evt.clientX
    lastY = evt.clientY

    document.onmouseup = onMoveUp
    document.onmousemove = onMove
  }

  // Resize
  const resizePinMove = (evt) => {
    const diffX = lastX - evt.clientX
    const diffY = lastY - evt.clientY
    lastX = evt.clientX
    lastY = evt.clientY

    const diff = diffX === 0 ? diffY : diffX

    if (diff === 0) return
    let width = diffX === 0 ? size.width + diff : size.width - diff
    let height = toNumber(width * aspect)

    const limitWidth = position.x + width
    const limitHeight = position.y + height

    if (width < 50) {
      width = 50
    } else if (height < 50) {
      width = toNumber(50 / aspect)
    }

    if (limitWidth > limitPosition.right) {
      width = limitPosition.right - position.x
    } else if (limitHeight > limitPosition.bottom) {
      height = limitPosition.bottom - position.y
      width = toNumber(height / aspect)
    }

    size.width = width
    size.height = toNumber(width * aspect)
    setSize({ ...size })
    onSize({ width, height })
  }

  const resizeMove = (evt) => {
    if (mode !== 'resizing') return
    evt.preventDefault()
    resizePinMove(evt)
  }

  const resizeMoveDown = (evt) => {
    if (mode !== undefined) return
    evt.preventDefault()

    mode = 'resizing'
    lastX = evt.clientX
    lastY = evt.clientY

    document.onmouseup = onMoveUp
    document.onmousemove = resizeMove
  }

  const resizeTouchMove = (evt) => {
    const obj = evt.changedTouches[0]
    resizePinMove(obj)
  }

  const resizeTouchStart = (evt) => {
    const obj = evt.changedTouches[0]
    lastX = obj.clientX
    lastY = obj.clientY
    document.ontouchmove = resizeTouchMove
  }

  const resizeTouchEnd = () => {
    document.ontouchmove = null
    mode = undefined
  }
  // End of resize

  const posX = `${position.x}px`
  const posY = `${position.y}px`
  const imgW = `${size.width - 2}px`
  const imgH = `${size.height - 2}px`
  const css = {
    top: posY,
    left: posX,
    width: imgW,
    height: imgH,
  }

  const cssBtn = {
    top: `${position.y + size.height - 16}px`,
    left: `${position.x + size.width - 16}px`,
  }

  const cropX = `${position.x + 2}px`
  const cropY = `${position.y + 2}px`
  const cropW = imgLayout ? imgLayout.width : 0
  const cropH = imgLayout ? imgLayout.height : 0
  const cssImg = {
    width: `${cropW}px`,
    height: `${cropH}px`,
    transform: `translateX(-${cropX}) translateY(-${cropY})`,
  }

  return (
    <PageView>
      <ContentImg>
        <Picture ref={onRef} onLoad={onInit} src={img_url} />
        <Crop
          ref={onRefPin}
          style={css}
          onMouseDown={onMoveDown}
          onTouchStart={onTouchStart}
          onTouchEnd={onTouchEnd}
        >
          <CropImg style={cssImg} src={img_url} />
        </Crop>
        <Resize
          style={cssBtn}
          onMouseDown={resizeMoveDown}
          onTouchStart={resizeTouchStart}
          onTouchEnd={resizeTouchEnd}
        />
      </ContentImg>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  border-radius: 4px;
  background-color: ${(p) => p.theme.color_level.grey.light};
  text-align: center;
`

const ContentImg = styled.div`
  position: relative;
  width: fit-content;
  margin: 0 auto;
  overflow: hidden;
`

const Crop = styled.div`
  position: absolute;
  z-index: 5;
  border: 2px solid ${(p) => p.theme.color.blue};
  cursor: move;
  overflow: hidden;
`

const CropImg = styled.img`
  display: block;
  min-width: 0px !important;
  min-height: 0px !important;
  max-width: none !important;
  max-height: none !important;
  z-index: 4;
`

const Resize = styled.span`
  position: absolute;
  z-index: 5;
  width: 20px;
  height: 20px;
  background-color: ${(p) => p.theme.color.yellow};
  border-radius: 20px;
  cursor: nwse-resize;
`

const Picture = styled.img`
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: calc(95vh - 200px);
  object-fit: contain;
  opacity: 0.8;
  filter: brightness(0.5);

  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
`

export default CropLayout
