import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { helper } from 'utils'
import { Meta, BackHeader, Layout } from 'components/display'
import { Btn } from 'components/button'
import { ContentDrawer } from 'drawer'

import ImageList from './ImageList'
import Header from './Header'
import Address from './Address'
import FloorPlan from './FloorPlan'
import Information from './Information'
import MarketMenu from './MarketMenu'
import Announce from './Announce'

const Market = (props) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const { market_id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const onLoad = useCallback(
    async (id) => {
      try {
        setLoading(true)
        await Promise.all([
          props.market.getMarketInfo(id),
          props.market_setting.getAnnounce(id),
          props.market_setting.getSetting(id),
        ])
      } catch (e) {
        console.log('e', e.message)
      }
      setLoading(false)
    },
    [props.market, props.market_setting]
  )

  useEffect(() => {
    onLoad(market_id)
  }, [onLoad, market_id])

  const onBack = () => {
    const path = helper.getBackUrl(location.search)
    navigate(path)
  }

  const { doc = {} } = props.market.toJS()
  const { announce = {}, setting = {} } = props.market_setting.toJS()

  const { name = '', img_list = [] } = doc

  const img_url = helper.getUrl(img_list)

  return (
    <PageView>
      <Meta
        loading={loading}
        title={`ตลาด - ${name || ''}`}
        description={doc.keywords}
        url={img_url}
      />
      <BackHeader color="white" onClick={onBack} />
      <Layout loading={loading} isHeader={true} background="white">
        <ImageList doc={doc} />
        <Announce doc={announce} />
        <Detail>
          <Header doc={doc} />
          <Address doc={doc} />
          <FloorPlan doc={doc} />
          <Information doc={doc} />

          <Btn text="ดูรายละเอียดเพิ่มเติม" onClick={() => setVisible(true)} />
        </Detail>
        <MarketMenu visible={!visible} doc={doc} status={setting.status} />
      </Layout>
      <ContentDrawer
        visible={visible}
        text={doc.detail}
        onClose={() => setVisible(false)}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  min-height: 100vh;
`

const Detail = styled.div`
  padding: 30px 24px 100px 24px;
`

export default inject('market', 'market_setting')(observer(Market))
