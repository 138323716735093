import { useState } from 'react'
import styled from 'styled-components'

import { Pdf } from 'icon'
import { Dot } from 'components/display'
import { format } from 'utils'
import ReceiptDrawer from 'pages/account/booking/receipt'

const PaymentInfo = (props) => {
  const [visible, setVisible] = useState(false)

  const { order = {} } = props
  const { status, support_contact_phone, support_contact_name } = order

  const getText = (label, text) => {
    return (
      <Text>
        {label}
        <Information>{text}</Information>
      </Text>
    )
  }

  const onOpen = () => setVisible(true)
  const onClose = () => setVisible(false)

  const getContact = (label, mobile, name) => {
    const m = format.toMobile(mobile)

    const text = `${m} (${name || ''})`
    return getText(label, text)
  }

  if (status !== 'completed') return <div />

  const contact = getContact(
    'ติดต่อผู้ดูแล:',
    order.contact_phone,
    order.contact_name
  )
  const support =
    support_contact_phone !== ''
      ? getContact(
          'การจอง, ใบเสร็จ, และอื่นๆ:',
          support_contact_phone,
          support_contact_name
        )
      : ''
  return (
    <PageView>
      <Dot />
      <Info>
        <Title>วิธีชำระเงิน</Title>
        โอน/ชำระผ่านบัญชีธนาคาร
      </Info>
      <Dot />
      <Receipt>
        <span onClick={onOpen}>
          <Pdf /> ใบเสร็จรับเงิน (PDF)
        </span>
      </Receipt>
      <Dot />
      <Contact>
        {contact}
        {support}
      </Contact>
      <ReceiptDrawer visible={visible} onClose={onClose} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Title = styled.div`
  width: 100%;
  font-size: 18px;
  color: ${(p) => p.theme.color.blue};
`

const Info = styled.div`
  padding: 8px 0px;
  font-weight: 700;
  font-size: 14px;
`

const Receipt = styled.div`
  padding: 12px 0px;
  font-weight: 400;
  font-size: 14px;
  color: ${(p) => p.theme.color.blue};
  display: flex;
  align-items: center;
  text-decoration-line: underline;

  svg {
    margin-right: 8px;
  }

  span {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
`

const Contact = styled.div`
  padding: 8px 0px;
`

const Text = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 6px;
  span {
    float: right;
    font-weight: normal;
  }
`

const Information = styled.div`
  font-size: 14px;
  font-weight: normal;
`

export default PaymentInfo
