import styled from 'styled-components'
import { Row, Col } from 'antd'

import Information from './Information'
import Menu from './Menu'
import Social from './Social'

const Footer = (props) => {
  const { isPadding } = props
  const css = isPadding ? { paddingBottom: '100px' } : {}
  return (
    <PageView style={css}>
      <Content>
        <Row>
          <Col {...col.info}>
            <Information />
          </Col>
          <Col {...col.menu}>
            <Menu {...props} />
          </Col>
          <Col {...col.social}>
            <Social />
          </Col>
        </Row>
        <Company>Copyright 2021 Marget. All Rights Reserved</Company>
      </Content>
    </PageView>
  )
}

const col = {
  info: {
    xl: { span: 8, order: 1 },
    lg: { span: 8, order: 1 },
    md: { span: 24, order: 3 },
    sm: { span: 24, order: 3 },
    xs: { span: 24, order: 3 },
  },
  menu: {
    xl: { span: 8, order: 2 },
    lg: { span: 6, order: 2 },
    md: { span: 10, order: 2 },
    sm: { span: 24, order: 2 },
    xs: { span: 24, order: 2 },
  },
  social: {
    xl: { span: 8, order: 3 },
    lg: { span: 10, order: 3 },
    md: { span: 14, order: 2 },
    sm: { span: 24, order: 2 },
    xs: { span: 24, order: 2 },
  },
}

const PageView = styled.div`
  color: white;
  background-color: ${(props) => props.theme.color.blue};
  width: 100%;
  border-radius: 2px 2px 0px 0px;
`

const Content = styled.div`
  width: 100%;
  max-width: 1440px;
  padding: 24px;
  margin: 0 auto;
  border-radius: 2px 2px 0px 0px;

  ${(p) => p.theme.media.mobile} {
    padding: 12px;
  }
`

const Company = styled.div`
  text-align: center;
  margin-top: 20px;
`

export default Footer
