import member from './Member'
import menu from './Menu'
import consent from './Consent'
import market from './Market'
import market_area from './MarketArea'
import market_type from './MarketType'
import market_setting from './MarketSetting'
import order from './Order'
import my_order from './myOrder'
import product from './Product'
import province from './Province'
import account from './Account'
import shop from './Shop'
import promotion from './Promotion'

/* eslint-disable */
export default {
  member,
  menu,
  consent,
  market,
  market_area,
  market_type,
  market_setting,
  order,
  my_order,
  product,
  province,
  account,
  shop,
  promotion,
}
// eslint-enable
