import { useState } from 'react'
import styled, { useTheme } from 'styled-components'

import { Find } from 'icon'
import { ImgDialog } from 'dialog'

const FloorPlan = (props) => {
  const [visible, setVisible] = useState(false)
  const theme = useTheme()
  const { doc = {} } = props
  const floor_plan_url = doc.floor_plan_url || ''

  if (floor_plan_url === '') return <div />

  return (
    <PageView>
      <Image src={floor_plan_url} />
      <Btn onClick={() => setVisible(true)}>
        <Find color={theme.color.blue} />
      </Btn>
      <ImgDialog
        visible={visible}
        img={floor_plan_url}
        onClose={() => setVisible(false)}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  position: relative;
  padding-top: 28px;
`

const Image = styled.img`
  width: 100%;
  height: 327px;
  object-fit: scale-down;
  border-radius: 16px;
  background-color: #f5f6f9;
  padding: 5px;
`

const Btn = styled.span`
  width: 56px;
  height: 56px;
  background-color: white;
  position: absolute;
  right: 16px;
  bottom: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
`

export default FloorPlan
