import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row } from 'antd'

import { PrimaryBtn } from 'components/button'
import { Layout, Col } from 'components/display'
import { format } from 'utils'

const BookingMenu = (props) => {
  const { onClick } = props
  const { order } = props.order.toJS()
  const { list, total } = order

  const disabled = list.length === 0

  return (
    <PageView>
      <Layout>
        <ContentView>
          <Row align="middle">
            <Col lg={16} md={16} xs={12} position="left">
              <Label>ราคารวมทั้งสิ้น</Label>
              <Price>
                {format.toDigi(total)}
                <span>บาท</span>
              </Price>
            </Col>

            <Col lg={8} md={8} xs={12} position="right">
              <PrimaryBtn disabled={disabled} text="ถัดไป" onClick={onClick} />
            </Col>
          </Row>
        </ContentView>
      </Layout>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  padding: 12px 24px;
  z-index: 5;
`

const ContentView = styled.div`
  width: 100%;
`

const Label = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.color_level.grey.high};
`

const Price = styled.div`
  font-size: 24px;
  color: ${(p) => p.theme.color.red};
  line-height: 26px;

  span {
    font-size: 14px;
    margin-left: 4px;
  }
`

export default inject('order')(observer(BookingMenu))
