import styled from 'styled-components'

import { config } from 'config'

const Header = (props) => {
  return (
    <PageView>
      <Title>จองล็อกออนไลน์</Title>
      <Description>ตลาดนัด บูธอีเวนท์ พื้นที่เช่า ทำเลขายของ</Description>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  height: 420px;
  text-align: center;
  padding-top: 20px;

  background-image: url(${config.img_url});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: -80px;

  ${(p) => p.theme.media.pc} {
    height: 390px;
    background-position-y: 40px;
  }

  ${(p) => p.theme.media.tablet} {
    height: 320px;
    background-position-y: 40px;
  }

  ${(p) => p.theme.media.mobile} {
    height: 220px;
    background-position-y: 40px;
  }
`

const Title = styled.div`
  color: ${(props) => props.theme.color.blue};
  font-size: 38px;
  font-weight: bold;
  ${(p) => p.theme.media.mobile} {
    font-size: 28px;
  }
`

const Description = styled.div`
  color: ${(props) => props.theme.color.grey};
  font-size: 18px;
  ${(p) => p.theme.media.mobile} {
    font-size: 14px;
  }
`

export default Header
