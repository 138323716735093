import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

import { PrimaryBtn } from 'components/button'
import { ConfirmDialog, MsgDialog } from 'dialog'
import { market, product, format, timer } from 'utils'

import Price from './Price'

const Product = (props) => {
  const [err, setErr] = useState('')
  const [clear, setClear] = useState('')
  const {
    unit = {},
    doc = {},
    order_list = [],
    shop = {},
    filter = {},
    promotion_list,
  } = props

  const {
    product_id,
    booking_type,
    type_clothes,
    type_drink,
    type_food,
    type_services,
    type_things,
    deposit_type,
    deposit,
  } = doc

  const isRound = booking_type === 'round'
  const start_at = isRound ? filter.round_start_at : filter.start_at
  const finish_at = isRound ? filter.round_finish_at : filter.finish_at
  const days_list = filter.days_list || []
  const product_type = shop.product_type || ''

  const round = market.toOpenTimeItem(doc)
  const orderUnit = market.getBookingType(doc)
  const detail = market.toProductType(doc)
  const depositContent =
    deposit_type === 'yes' ? `มี (${format.toDigi(deposit)})` : 'ไม่มี'
  const ban = market.toBanProduct(doc)
  const price = market.toPrice(doc, filter)
  const specificRound = market.isSpecificRound(doc, filter)
  const promotion = product.getPromotion(
    product_id,
    start_at,
    finish_at,
    promotion_list
  )
  console.log('promotion:', promotion)

  const onCloseError = () => setErr('')
  const onCloseClear = () => setClear(false)
  const onClearOrder = () => {
    onCloseClear()
    props.order.clear()
    props.order.addProduct(unit, doc, {
      promotion,
      round,
      price,
      start_at,
      finish_at,
      detail,
      type_clothes,
      type_drink,
      type_food,
      type_services,
      type_things,
      days_list,
    })
  }

  const onAdd = () => {
    const invalid = props.order.isInvalid(doc, start_at, finish_at)
    if (invalid === 'diff_market') {
      setClear('diff_market')
      return
    } else if (invalid === 'diff_booking_type') {
      setClear('diff_booking_type')
      return
    } else if (invalid === 'unavailable') {
      setErr('รอบและวันที่ขายมีอยู่แล้ว')
      return
    }

    props.order.addProduct(unit, doc, {
      promotion,
      round,
      price,
      start_at,
      finish_at,
      detail,
      days_list,
    })
  }

  const canBooking = () => {
    if (product_type === '') {
      return true
    }
    switch (product_type) {
      case 'food':
        return type_food === 1
      case 'drink':
        return type_drink === 1
      case 'clothes':
        return type_clothes === 1
      case 'things':
        return type_things === 1
      case 'services':
        return type_services === 1
      default:
        return false
    }
  }

  const haveBooking = () => {
    const index = order_list.findIndex((it) => it.product_id === product_id)

    if (index !== -1) {
      const item = order_list[index]
      const current_date = product.getDate(start_at, finish_at)
      const product_date = product.getDate(item.start_at, item.finish_at)

      return current_date === product_date
    }

    return false
  }

  const getBtn = () => {
    if (isActive) {
      if (haveBooking()) {
        return <HaveBooking>เลือกแล้ว</HaveBooking>
      } else if (canBooking()) {
        return <PrimaryBtn text="เลือก" onClick={onAdd} />
      } else {
        return <NoBooking>สินค้าไม่ตรง</NoBooking>
      }
    }

    return <Unavailable>ไม่ว่าง</Unavailable>
  }

  const getDialogMsg = () => {
    if (clear === 'diff_market') {
      return {
        title: 'มีตลาดอื่นในรายการจอง',
        message: 'ท่านต้องการที่จะลบรายการเพื่อเพิ่มรายการใหม่หรือไม่',
      }
    }

    return {
      title: 'มีประเภทอื่นในรายการจอง',
      message: 'ท่านต้องการที่จะลบรายการเพื่อเพิ่มรายการใหม่หรือไม่',
    }
  }

  const getPromotion = () => {
    if (!promotion) {
      return
    }
    const text = product.getTextPromotion(promotion)
    return getText('โปรโมชั่น', text)
  }

  const text_ban = ban !== '' ? ban : 'ไม่มี'
  const isActive = doc.status === 'active'

  const css = isActive ? '' : 'inactive'
  const getText = (label, text) => {
    return (
      <Text className={css}>
        <span>{label}</span>
        {text}
      </Text>
    )
  }

  const getPromotionPrice = (value) => {
    const p = value - promotion.value
    const val = p < 0 ? 0 : p
    return format.toDigi(val)
  }

  const getPrice = () => {
    const days =
      start_at && finish_at ? finish_at.diff(start_at, 'days') + 1 : 1
    if (booking_type !== 'daily' || days === 1) {
      return {
        price: format.toDigi(price),
        promotion: promotion ? getPromotionPrice(price) : undefined,
      }
    }

    let min = price
    let max = price
    let min_pro = price
    let max_pro = 0
    let date = timer.get(start_at)
    for (let i = 0; i < days; i++) {
      const num = date.days()
      const isOpen = days_list.includes(num)
      if (isOpen) {
        const date_price = market.getDailyPrice(date, doc)
        if (date_price < min) min = date_price
        if (date_price > max) max = date_price

        if (promotion) {
          const { isPromotion, promotion_price } = product.getPromotionPrice(
            date,
            date_price,
            doc,
            promotion
          )
          console.log(
            'price',
            date.format('DDMMYY'),
            date_price,
            promotion_price
          )
          const priceTmp = isPromotion ? promotion_price : date_price
          if (priceTmp < min_pro) min_pro = priceTmp
          if (priceTmp > max_pro) max_pro = priceTmp
        }
      }

      date.add(1, 'days')
    }

    const p =
      min === max
        ? format.toDigi(min)
        : `${format.toDigi(min)}-${format.toDigi(max)}`

    const pro =
      min_pro === max_pro
        ? format.toDigi(min_pro)
        : `${format.toDigi(min_pro)}-${format.toDigi(max_pro)}`

    return {
      price: p,
      promotion: promotion ? pro : undefined,
    }
  }

  const note = specificRound ? specificRound.note || '' : ''
  const roundContent = note !== '' ? getText('หมายเหตุ', note) : undefined
  const priceContent = getPrice()
  const dialog = getDialogMsg()
  const btn = getBtn()

  return (
    <PageView className={css}>
      <Label className={css}>รอบ</Label>
      <Round className={css}>{round}</Round>
      <Detail>
        {getText('โซน', detail)}
        {getText('มัดจำ/ประกัน', depositContent)}
        {getText('ห้ามนำเข้า', text_ban)}
        {roundContent}
        {getPromotion()}
      </Detail>
      <Row align="middle">
        <Col lg={18} md={16} xs={14}>
          <Price
            css={css}
            price={priceContent.price}
            promotion={priceContent.promotion}
            unit={orderUnit}
          />
        </Col>
        <Col lg={6} md={8} xs={10}>
          {btn}
        </Col>
      </Row>
      <MsgDialog message={err} onClose={onCloseError} />
      <ConfirmDialog
        visible={clear}
        title={dialog.title}
        message={dialog.message}
        onClose={onCloseClear}
        onConfirm={onClearOrder}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 20px;
  margin-bottom: 16px;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 16px;

  &.inactive {
    color: ${(p) => p.theme.color_level.grey.low};
    background-color: ${(p) => p.theme.color.disable};
  }
`

const Label = styled.div`
  width: 100%;
  font-size: 18px;
  padding-bottom: 5px;
  color: ${(p) => p.theme.color_level.grey.high};

  &.inactive {
    color: ${(p) => p.theme.color_level.grey.low};
  }
`

const Round = styled.div`
  width: 100%;
  font-weight: bold;
  font-size: 32px;
  line-height: 16px;
  padding-bottom: 25px;
  color: ${(p) => p.theme.color.green};
  border: 0px dashed ${(p) => p.theme.color_level.grey.low};
  border-bottom-width: 1px;

  &.inactive {
    color: ${(p) => p.theme.color_level.grey.low};
  }
`

const Detail = styled.div`
  width: 100%;
  padding: 24px 0px;
`

const Text = styled.div`
  width: 100%;
  font-size: 18px;
  color: ${(p) => p.theme.color.grey};

  span {
    color: ${(p) => p.theme.color_level.grey.high};
    font-weight: bold;
    margin-right: 8px;
  }

  &.inactive,
  &.inactive span {
    color: ${(p) => p.theme.color_level.grey.low};
  }
`

const Unavailable = styled.div`
  width: 100%;
  height: 48px;
  font-size: 16px;
  color: ${(p) => p.theme.color_level.grey.light};
  background-color: ${(p) => p.theme.color_level.grey.low};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const NoBooking = styled(Unavailable)`
  color: ${(p) => p.theme.color_level.grey.medium};
  background-color: ${(p) => p.theme.color_level.yellow.low};
`

const HaveBooking = styled(Unavailable)`
  color: ${(p) => p.theme.color_level.grey.medium};
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
`

export default inject('order')(observer(Product))
