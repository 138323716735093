import { useState, useEffect } from 'react'
import styled from 'styled-components'

import { ErrorDialog } from 'dialog'
import { Check, Next } from 'icon'
import { BaseDrawer } from 'drawer'
import { validator } from 'utils'

import Information from './Information'
import { getRule } from './rules'

const TaxInvoice = (props) => {
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState({})
  const [validate, setValidate] = useState({})
  const [err, setErr] = useState('')

  const { loading, account = {}, market_info = {}, onAccount } = props
  const isTaxRequired = account.tax_required || false

  useEffect(() => {
    if (!visible) {
      return
    }

    const { delivery_market, delivery_post_office } = market_info
    const { receive_type } = account
    if (
      delivery_market === 'yes' &&
      delivery_post_office === 'no' &&
      receive_type !== 'onsite'
    ) {
      account.receive_type = 'onsite'
      onAccount(account)
    } else if (
      delivery_market === 'no' &&
      delivery_post_office === 'yes' &&
      receive_type !== 'address'
    ) {
      account.receive_type = 'address'
      onAccount(account)
    }
  }, [visible])
  const onCloseError = () => setErr('')
  const onOpen = () => {
    setData(account)
    setValidate({})
    setVisible(true)
  }

  const onCheck = () => {
    account.tax_required = !isTaxRequired
    if (account.tax_required) {
      setData(account)
      setValidate({})
      setVisible(true)
    } else {
      onAccount(account)
    }
  }

  const checkInvoice = () => {
    const disabled = data.receive_type === 'onsite'
    const rule = getRule(!disabled)
    const checker = validator.process(rule, data)
    return checker
  }

  const onClose = () => {
    setVisible(false)
    if (data.tax_required) {
      const checker = checkInvoice()
      if (checker.invalid) {
        data.tax_required = false
        onAccount(account)
      }
    }
  }

  const onSave = async () => {
    try {
      const checker = checkInvoice()
      if (checker.invalid) {
        setValidate(checker.errors)
        return
      }

      data.tax_required = true
      await onAccount(data, true)
      setVisible(false)
    } catch (e) {
      setErr(e.message)
    }
  }

  const onChange = (val, valid) => {
    setData({ ...val })
    setValidate({ ...valid })
  }

  const color = isTaxRequired ? undefined : 'white'
  return (
    <>
      <PageView>
        <Btn onClick={onCheck}>
          <Check color={color} />
        </Btn>
        <Text onClick={onOpen}>
          ใบกำกับภาษี
          <Next />
        </Text>
      </PageView>
      <BaseDrawer visible={visible} title="ใบกำกับภาษี" onClose={onClose}>
        <Information
          visible={visible}
          loading={loading}
          account={data}
          market_info={market_info}
          validate={validate}
          onSave={onSave}
          onAccount={onChange}
        />
      </BaseDrawer>
      <ErrorDialog error={err} onClose={onCloseError} />
    </>
  )
}

const PageView = styled.div`
  width: 100%;
  margin: 16px 0px;
  color: ${(p) => p.theme.color_level.grey.high};
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
`

const Text = styled.div`
  font-size: 14px;
  font-weight: 400;
  height: 60px;
  width: calc(100% - 25px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

const Btn = styled.div`
  width: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  }
`

export default TaxInvoice
