import ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import locale from 'antd/lib/locale/th_TH'

import theme from 'theme'
import store from 'stores'
import ScrollToTop from 'components/ScrollToTop'

import './assets'
import App from './App'

ReactDOM.render(
  <Provider {...store}>
    <ThemeProvider theme={theme}>
      <ConfigProvider locale={locale}>
        <BrowserRouter>
          <ScrollToTop>
            <Routes>
              <Route path="*" element={<App />} />
            </Routes>
          </ScrollToTop>
        </BrowserRouter>
      </ConfigProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)
