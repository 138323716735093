import styled from 'styled-components'

import { Dot } from 'components/display'
import { PrimaryBtn } from 'components/button'
import { format } from 'utils'

const Transfer = (props) => {
  const { item = {}, onNext } = props
  const { code, status, due_payment_at } = item

  const onClick = () => onNext(`/account/booking/${code}/payment`)

  if (status !== 'payment') {
    return <div />
  }

  const date = format.toThaiDatetime(due_payment_at)
  return (
    <PageView>
      <Dot />
      <Text>{`ชำระเงินภายใน ${date}`}</Text>
      <Button>
        <PrimaryBtn text="โอนเงิน" onClick={onClick} />
      </Button>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 16px;
`

const Text = styled.div`
  color: ${(p) => p.theme.color_level.grey.medium};
  font-size: 14px;
  font-weight: 400;
  padding-top: 16px;
`

const Button = styled.div`
  width: 214px;
  margin: 0 auto;
  padding-top: 16px;
`

export default Transfer
