import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { useParams, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Meta, BackHeader, Layout } from 'components/display'
import Loading from 'components/loading'
import { ErrorDialog, ConfirmDialog } from 'dialog'
import { helper, timer, market } from 'utils'

import Header from './Header'
import Filter from './Filter'
import Floorplan from './Floorplan'
import ProductList from './ProductList'
import CloseBooking from './CloseBooking'
import MarketMenu from './Menu'
import ProductMenu from './ProductMenu'

const ProductPicker = (props) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [isConfirm, setIsConfirm] = useState(undefined)
  const [unitLoading, setUnitLoading] = useState(false)
  const [bookingMenu, setBookingMenu] = useState([])
  const [filter, setFilter] = useState({})
  const [err, setErr] = useState('')

  const { market_id } = useParams()
  const navigate = useNavigate()

  const shop = props.order.toJS().shop || {}
  const doc = props.market.toJS().short_info || {}
  const { floorplan, display } = props.product.toJS()
  const { setting } = props.market_setting.toJS()
  const isLogin = props.member.isLogin()

  const { name, img_list = [] } = doc
  const { selected } = floorplan

  const onLoad = useCallback(
    async (id, isMember) => {
      try {
        setLoading(true)

        await Promise.all([
          props.product.getFloorplanList({ market_id: id }),
          props.market_setting.getSetting(id),
          props.promotion.getPromotionList({ market_id: id }),
        ])

        if (isMember) {
          const shopList = await props.shop.getMyShopList()
          const isShopVisible = shopList.length > 0 && !shop.shop_id
          setVisible(isShopVisible)
        }
      } catch (e) {
        setErr(e.message)
      }
      setLoading(false)
    },
    [props.shop, props.product, props.market_setting]
  )

  const getUnitList = useCallback(
    async (params) => {
      try {
        setUnitLoading(true)

        await props.product.getUnitList(params)
      } catch (e) {
        setErr(e.message)
      }
      setUnitLoading(false)
    },
    [props.product]
  )

  useEffect(() => {
    onLoad(market_id, isLogin)
  }, [onLoad, market_id, isLogin])

  useEffect(() => {
    const menu = [
      { name: 'รายวัน', value: 'daily', tag: 'booking_daily' },
      { name: 'รายเดือน', value: 'monthly', tag: 'booking_monthly' },
      { name: 'บูธอีเวนท์', value: 'round', tag: 'booking_round' },
    ]

    const list = menu.filter((it) => {
      return doc[it.tag] === 'yes'
    })

    const booking = list.length > 0 ? list[0].value : undefined
    props.product.setDisplay(booking)
    setBookingMenu(list)
  }, [doc.market_id, props.product])

  useEffect(() => {
    if (!doc.market_id || !setting.market_id) {
      return
    }
    // init select date
    const { open_status } = doc
    let current = setting.start_at
    const open_list = doc.open_list || []
    const days_list = helper.getDateList(open_list)
    if (open_status === 'specific' && open_list.length > 0) {
      current = market.getOpenDate(current, days_list)
    }

    const limit = market.getDateLimitation({
      setting,
      days_list,
    })

    const newFilter = {
      start_at: current,
      finish_at: current,
      limit,
      days_list,
    }

    const round_list = setting.round_list || []
    if (round_list.length > 0) {
      const menuItem = round_list[0]
      newFilter.round_start_at = menuItem.tag.start_at
      newFilter.round_finish_at = menuItem.tag.finish_at
      newFilter.round = menuItem.value
    }

    setFilter(newFilter)
  }, [doc.market_id, setting.market_id, market])

  const getMessageStatus = () => {
    if (!selected.floorplan_id) {
      return 'ยังไม่มีแผนผังการจอง'
    }

    if (setting.status !== 'active') {
      return 'ยังไม่เปิดการจอง'
    }

    return undefined
  }

  useEffect(() => {
    let start_at = filter.start_at
    let finish_at = filter.finish_at
    switch (display.booking_type) {
      case 'daily':
        break
      case 'round':
        start_at = filter.round_start_at
        finish_at = filter.round_finish_at
        break
      default:
        finish_at = timer.getFinishMonth(start_at)
    }

    getUnitList({
      market_id,
      floorplan_id: selected.floorplan_id,
      start_at,
      finish_at,
    })
  }, [
    getUnitList,
    market_id,
    selected.floorplan_id,
    display.booking_type,
    filter,
  ])

  const onCloseVisible = () => setVisible(false)
  const onCloseError = () => setErr('')
  const onBack = () => navigate(`/market/${market_id}`)
  const onBookingType = (val) => {
    props.product.setBookingType(val)
    if (val === 'monthly') {
      filter.finish_at = timer
        .get(filter.start_at)
        .add(1, 'months')
        .subtract(1, 'days')
      setFilter({ ...filter })
    }
  }
  const onFilter = (val) => setFilter({ ...val })
  const onSelectShop = (shop) => {
    const invalid = shop.shop_id ? props.order.isShopInvalid(shop) : false
    if (invalid) {
      setIsConfirm(shop)
    } else {
      props.order.setShop(shop)
    }

    onCloseVisible()
  }

  const onCloseConfirm = () => setIsConfirm(undefined)
  const onConfirm = () => {
    props.order.setShop(isConfirm)
    props.order.clear()
    onCloseConfirm()
  }

  const img_url = helper.getUrl(img_list)
  const msg = getMessageStatus()

  return (
    <PageView>
      <Meta
        loading={loading}
        title={`แผนผังพื้นที่ - ${name || ''}`}
        description={doc.keywords}
        url={img_url}
      />
      <BackHeader color="white" onClick={onBack} />
      <Layout loading={loading} isHeader={true}>
        <BodyContent>
          <CloseBooking message={msg}>
            <Header doc={doc} available={display.available} />
            <Filter
              filter={filter}
              bookingType={display.booking_type}
              bookingMenu={bookingMenu}
              roundMenu={setting.round_list}
              onFilter={onFilter}
              onBookingType={onBookingType}
            />
            <Floorplan
              shop={shop}
              isProductType={isLogin}
              onProductType={() => setVisible(true)}
            />

            <Loading loading={unitLoading}>
              <ProductList filter={filter} shop={shop} />
            </Loading>
          </CloseBooking>
        </BodyContent>
      </Layout>
      <MarketMenu market_id={market_id} doc={doc} />
      <ProductMenu
        visible={visible}
        value={shop.shop_id}
        onClose={onCloseVisible}
        onSelect={onSelectShop}
      />
      <ErrorDialog error={err} onClose={onCloseError} />
      <ConfirmDialog
        visible={isConfirm !== undefined}
        title="สินค้าไม่ตรงกับร้านค้า"
        message="ต้องการลบกรายการจองเพื่อเปลี่ยนร้านค้าหรือไม่?"
        onClose={onCloseConfirm}
        onConfirm={onConfirm}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const BodyContent = styled.div`
  width: 100%;
  padding: 24px 24px 100px 24px;
  min-height: 90vh;
`

export default inject(
  'member',
  'order',
  'market',
  'product',
  'shop',
  'market_setting',
  'promotion'
)(observer(ProductPicker))
