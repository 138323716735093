import { firebase } from '../utils'

export const checkEmail = async ({ email = '', mode }) => {
  const auth = firebase.auth()
  email = email.trim()
  const resp = await auth.fetchSignInMethodsForEmail(email)

  const have = resp.length > 0
  return have
}

export const checkFacebook = async () => {
  const auth = firebase.auth()
  // Start a sign in process for an unauthenticated user.
  const provider = new firebase.firebase_.auth.FacebookAuthProvider()
  //provider.addScope('profile')
  //provider.addScope('email')
  const result = await auth.signInWithPopup(provider)
  const { user = {}, additionalUserInfo = {} } = result
  const { profile = {} } = additionalUserInfo

  const data = {
    uid: user.uid,
    facebook_id: profile.id,
    name: profile.first_name || user.displayName,
    surname: profile.last_name,
    email: profile.email || user.email,
    img_url: user.photoURL,
  }

  const token = await user.getIdToken()
  return { token, user, data }
}

export const checkGoogle = async ({ code } = {}) => {
  const auth = firebase.auth()
  // Start a sign in process for an unauthenticated user.
  const provider = new firebase.firebase_.auth.GoogleAuthProvider()
  provider.addScope('profile')
  provider.addScope('email')

  const result = await auth.signInWithPopup(provider)
  const { user } = result ? result : {}

  const data = {
    uid: user.uid,
    name: user.displayName,
    email: user.email,
    img_url: user.photoURL,
  }

  const token = user ? await user.getIdToken() : null
  return { token, user, data }
}
