import styled from 'styled-components'
import { Row, Col } from 'antd'

import { Dot } from 'components/display'
import { format, product } from 'utils'

const Product = (props) => {
  const { item = {} } = props
  const { start_at, finish_at, total_price, deposit_type, deposit } = item

  const getText = (label, text) => {
    return (
      <Text>
        <span>{label}</span>
        {text}
      </Text>
    )
  }

  const bookingType = tag[item.booking_type] || tag.daily
  const date = product.getDate(start_at, finish_at)

  let priceContent
  let depositContent
  if (deposit_type === 'yes') {
    priceContent = getText('ค่าเช่า:', format.toDigi(total_price) + ' บาท')
    depositContent = getText('มัดจำ/ประกัน:', format.toDigi(deposit) + ' บาท')
  }
  return (
    <PageView>
      <Info>
        <Row>
          <Col span={16}>{getText('ล็อก:', item.name)}</Col>

          <Col span={8}>
            <Price>
              {format.toDigi(total_price + deposit)}
              <span>บาท</span>
            </Price>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            {getText('ประเภทการจอง:', bookingType.text)}
            {getText('วันที่ขาย:', date)}
            {getText('รอบ:', item.round)}
            {priceContent}
            {depositContent}
          </Col>
        </Row>
      </Info>
      <Dot />
    </PageView>
  )
}

const tag = {
  daily: { text: 'รายวัน' },
  monthly: { text: 'รายเดือน' },
  round: { text: 'บูธอีเวนท์' },
}

const PageView = styled.div`
  width: 100%;
  margin-bottom: 16px;
`

const Price = styled.div`
  font-size: 16px;
  text-align: right;

  span {
    margin-left: 6px;
  }
`

const Info = styled.div`
  width: 100%;
  margin-bottom: 16px;
`

const Text = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.color_level.grey.high};

  span {
    font-weight: bold;
    margin-right: 8px;
  }
`

export default Product
