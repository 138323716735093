import styled from 'styled-components'
import { Row, Col } from 'antd'

import { TurnRight } from 'icon'
import map from 'assets/img/maps.png'

const MarketInfo = (props) => {
  const { doc = {} } = props
  const { address, sub_district, district, province, postcode, map_link } = doc

  const getBkkPrefix = () => {
    const tumbon = 'แขวง'
    const prefix = 'เขต'

    return { tumbon, prefix }
  }

  const getPrefix = () => {
    const tumbon = 'ตำบล'
    const prefix = 'อำเภอ'

    return { tumbon, prefix }
  }

  const { tumbon, prefix } =
    province === 'กรุงเทพมหานคร' ? getBkkPrefix() : getPrefix()

  return (
    <PageView>
      <Row>
        <Col span={20}>
          <Text>
            {address} {tumbon} {sub_district} {prefix} {district} {province}{' '}
            {postcode}
          </Text>
        </Col>
        <Col span={4}>
          <a href={map_link} target="_blank" rel="noreferrer">
            <Map src={map} />
          </a>
        </Col>
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Text = styled.div`
  font-size: 18px;
  line-height: 120%;
  color: ${(p) => p.theme.color_level.grey.high};
`

const Map = styled.img`
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
`

export default MarketInfo
