import styled from 'styled-components'

import { Link } from 'components/link'
import { Next } from 'icon'
import { product, helper } from 'utils'

const Card = (props) => {
  const { doc = {} } = props

  let shop_name = doc.shop_name || ''
  shop_name = shop_name !== '' ? shop_name : 'ไม่ระบุ'

  const img_url = helper.getUrl(doc.img_list || [])
  return (
    <Link to={`/account/shop/${doc.shop_id}`}>
      <PageView>
        <Image src={img_url} />
        <Info>
          <Title>{doc.name}</Title>
          <Label>
            หมวดหมู่
            <span>{product.toProductType(doc)}</span>
          </Label>
          <Label>
            ร้าน
            <span>{shop_name}</span>
          </Label>
        </Info>
        <Next />
      </PageView>
    </Link>
  )
}

const PageView = styled.div`
  width: 100%;
  color: ${(p) => p.theme.color_level.grey.high};
  background-color: #ffffff;

  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Image = styled.img`
  width: 88px;
  height: 88px;
  border-radius: 4px;
  object-fit: cover;
`

const Info = styled.div`
  flex: auto;
  padding: 0px 16px;
`

const Title = styled.div`
  font-size: 24px;
`

const Label = styled.div`
  font-size: 16px;
  font-weight: bold;

  span {
    font-weight: normal;
    margin-left: 4px;
  }
`

export default Card
