import styled from 'styled-components'

import { Link } from 'components/link'
import { Next } from 'icon'

const Menu = (props) => {
  const { title = '', menu = [] } = props

  const content = menu.map((it, i) => {
    const borderTopWidth = i !== 0 ? '1px' : undefined
    return (
      <Link key={i} to={it.link}>
        <Line style={{ borderTopWidth }}>
          {it.label}
          <Next />
        </Line>
      </Link>
    )
  })
  return (
    <PageView>
      <Title>{title}</Title>
      <Body>{content}</Body>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  color: ${(p) => p.theme.color_level.grey.high};
`

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${(p) => p.theme.color_level.grey.high};
`

const Body = styled.div`
  margin-top: 17px;
  margin-bottom: 32px;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 4px 20px;
`

const Line = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 16px 0px;
  border: 0px dashed #bbbbbb;

  svg {
    margin-left: auto;
    order: 2;
  }
`

export default Menu
