import styled from 'styled-components'

import { PrimaryBtn } from 'components/button'

import BaseDialog from './BaseDialog'

const ErrorDialog = (props) => {
  const {
    title = 'เกิดข้อผิดพลาด',
    message_list = ['กรุณาลองใหม่อีกครั้ง'],
    error,
    onClose,
  } = props

  const content = message_list.map((m, i) => {
    return <Detail key={i}>{m}</Detail>
  })

  const note = error ? <Note>({error})</Note> : undefined
  return (
    <BaseDialog isHeader={false} visible={error !== ''} onClose={onClose}>
      <Content>
        <Body>
          <Title>{title}</Title>
          {content}
          {note}
        </Body>
        <PrimaryBtn text="ตกลง" onClick={onClose} />
      </Content>
    </BaseDialog>
  )
}

const Content = styled.div`
  width: 330px;
  padding: 20px;
`

const Body = styled.div`
  width: 90%;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 24px;
`

const Title = styled.div`
  color: ${(p) => p.theme.color.error};
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
`

const Detail = styled.div`
  color: ${(p) => p.theme.color_level.grey.high};
  font-size: 18px;
`

const Note = styled.div`
  color: ${(p) => p.theme.color_level.grey.medium};
  font-size: 14px;
  margin-top: 8px;
`

export default ErrorDialog
