import styled from 'styled-components'

import { format } from 'utils'
import { Location } from 'icon'

const MarketArea = (props) => {
  const { item = {} } = props
  const { district } = item

  const text = format.replaceAll(district || '', 'เขต')
  return (
    <Area>
      <Label>{text}</Label>
      <Location />
    </Area>
  )
}

const Area = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  width: 100%;
  padding-right: 16px;
  svg {
    position: absolute;
    right: 0px;
  }
`

const Label = styled.span`
  font-size: 14px;
  top: -2px;
  position: relative;
`

export default MarketArea
