import styled from 'styled-components'

import { Marget } from 'icon'
import FullDialog from 'dialog/FullDialog'
import { LineBtn } from 'components/button'

const LoginDialog = (props) => {
  const { visible, loading, onLogin, onClose } = props

  return (
    <FullDialog visible={visible} onClose={onClose}>
      <Content id="login">
        <Body>
          <Marget size={1.6} />
          <Title>ระบบจัดการตลาด</Title>
          <Description>เข้าสู่ระบบ /สมัครสมาชิก</Description>
          <Button>
            <LineBtn
              text="ดำเนินการต่อด้วย LINE"
              loading={loading}
              onClick={onLogin}
            />
          </Button>
        </Body>
      </Content>
    </FullDialog>
  )
}

const Content = styled.div`
  width: 100%;
  height: 85vh;

  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.color.backgroundColor};
`

const Body = styled.div`
  width: 100%;
  text-align: center;
`

const Title = styled.div`
  color: ${(props) => props.theme.color.blue};
  font-size: 28px;
  font-weight: bold;
  margin-top: 20px;
`

const Description = styled.div`
  color: ${(props) => props.theme.color.grey};
  font-size: 18px;
  margin-top: 5px;
`

const Button = styled.div`
  width: 220px;
  margin: 0 auto;
  margin-top: 23px;
`

export default LoginDialog
