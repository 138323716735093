import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row } from 'antd'

import { BaseDrawer } from 'drawer'
import { Col } from 'components/display'
import { PrimaryBtn } from 'components/button'
import { format } from 'utils'

import Product from './Product'

const OfferDrawer = (props) => {
  const [selected, setSelected] = useState({})

  const { visible, onClose, onSave = () => {} } = props

  const { order } = props.my_order.toJS()
  const list = order.offer_list || []
  const { market_name, offer_setting } = order
  const { force_selected } = offer_setting || {}

  const isForceSelected = force_selected === 'yes'

  useEffect(() => {
    let total = 0
    let index = 0
    const obj = {}
    for (const it of list) {
      const { total_price, deposit_type, deposit } = it
      const val = deposit_type === 'yes' ? deposit : 0
      total += total_price + val

      obj[`${index++}`] = true
    }

    obj.total = total
    setSelected(obj)
  }, [order.order_id])

  const onChange = (val, i, item) => {
    if (isForceSelected) {
      return
    }

    selected[`${i}`] = val
    const { total_price, deposit_type, deposit } = item
    const value = deposit_type === 'yes' ? deposit : 0
    if (val) {
      selected.total += total_price + value
    } else {
      selected.total -= total_price + value
    }

    setSelected({ ...selected })
  }

  const onClick = () => {
    const filter_list = list.filter((it, i) => {
      return selected[`${i}`]
    })
    onSave({ list: filter_list, total: selected.total })
  }

  if (!visible) return <div />

  let enable = false
  const content = list.map((it, i) => {
    enable = selected[`${i}`] || enable
    return (
      <Product
        key={i}
        index={i}
        item={it}
        isCheck={selected[`${i}`]}
        onChange={onChange}
      />
    )
  })

  const comment = isForceSelected ? <Note>ต้องเลือกทุกรายการ</Note> : undefined
  return (
    <BaseDrawer
      visible={visible}
      title="ข้อเสนอใหม่"
      height="90%"
      onClose={onClose}
    >
      <PageView>
        <MarketName>{market_name}</MarketName>
        {comment}
        {content}

        <Button>
          <Row align="middle">
            <Col lg={16} md={16} xs={12} position="left">
              <Label>ราคารวมทั้งหมด</Label>
              <Price>
                {format.toDigi(selected.total || 0)}
                <span>บาท</span>
              </Price>
            </Col>

            <Col lg={8} md={8} xs={12} position="right">
              <PrimaryBtn text="จองเลย" disabled={!enable} onClick={onClick} />
            </Col>
          </Row>
        </Button>
      </PageView>
    </BaseDrawer>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 20px 0px 100px 0px;
`

const MarketName = styled.div`
  width: 100%;
  font-size: 24px;
  line-height: 26px;
  font-weight: bold;
  color: ${(p) => p.theme.color.blue};
  margin-bottom: 16px;
`

const Label = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.color_level.grey.high};
`

const Price = styled.div`
  font-size: 24px;
  color: ${(p) => p.theme.color.red};
  line-height: 26px;

  span {
    font-size: 14px;
    margin-left: 4px;
  }
`

const Note = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.color_level.grey.high};
  margin-bottom: 16px;
`

const Button = styled.div`
  width: 100%;
  max-width: ${(p) => p.theme.max};
  padding: 18px 24px;
  background-color: white;
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 10;
`

export default inject('my_order')(observer(OfferDrawer))
