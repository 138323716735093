import styled from 'styled-components'

import { market } from 'utils'

const MarketOpen = (props) => {
  const { item = {} } = props
  const { open_time_list = [] } = item

  const list = open_time_list.map((it) => market.toOpenTimeItem(it))
  const text = list.join(', ')
  return <OpenTime>{text}</OpenTime>
}

const OpenTime = styled.div`
  font-size: 14px;
`

export default MarketOpen
