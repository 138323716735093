import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

let state
export class Province extends BaseStore {
  constructor() {
    super()
    this.observable({
      province_list: [],
    })
    state = this
  }

  convertProvince(list = []) {
    const province_list = list.map((province) => {
      const d_list = province.district_list || []

      const district_list = d_list.map((district) => {
        const t_list = district.tumbon_list || []
        const tumbon_list = t_list.map((tumbon) => {
          return {
            name: tumbon.tumbon_name,
            value: tumbon.tumbon_name,
            tag: tumbon,
          }
        })
        return {
          name: district.district_name,
          value: district.district_name,
          tag: { tumbon_list },
        }
      })

      const postcode_list = province.postcode_list || []

      return {
        name: province.province_name,
        value: province.province_name,
        tag: { district_list, postcode_list },
      }
    })

    return province_list
  }

  async getProvinceList() {
    const current_list = this.toJS().province_list
    if (current_list.length > 0) {
      return
    }

    const url = `${config.api}/v1/public/province`
    const resp = await http.get(url)

    const list = resp.body || []

    const province_list = this.convertProvince(list)
    runInAction(() => {
      state.province_list = province_list
    })
  }
}

export default new Province()
