import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

let state
export class MarketType extends BaseStore {
  constructor() {
    super()
    this.observable({
      select_list: [],
      market_type_list: [],
    })
    state = this
  }

  async getMarketType() {
    const current_list = this.toJS().select_list
    if (current_list.length !== 0) return

    const url = `${config.api}/v1/public/market/market-type`
    const res = await http.get(url)
    const list = res.body || []

    const select_list = list.map((it) => {
      return {
        name: it.name,
        value: it.market_type_id,
        tag: it,
      }
    })

    runInAction(() => {
      state.select_list = select_list
      state.market_type_list = list
    })
  }
}

export default new MarketType()
