import styled from 'styled-components'

import { Meta } from 'components/display'
import HeaderMenu from 'menu/HeaderMenu'
import UserMenu from 'menu/UserMenu'
import Footer from 'footer'

import Header from './Header'
import HotMarket from './HotMarket'
import MarketType from './MarketType'
import MarketArea from './MarketArea'
import FindMarket from './FindMarket'

const Home = (props) => {
  return (
    <PageView>
      <Meta
        title="Marget - จองล็อกตลาดนัดพื้นที่เช่าทำเลขายของบูธอีเวนท์"
        description="จองล็อกตลาดนัดพื้นที่เช่าทำเลขายของบูธอีเวนท์"
      />
      <HeaderMenu />
      <Header />

      <FindMarket />
      <HotMarket />
      <MarketType />
      <MarketArea />
      <Footer isPadding={true} />
      <UserMenu selected="market" />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding-bottom: 40px;
`

export default Home
