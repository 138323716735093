import styled from 'styled-components'

const Button = (props) => {
  const { children } = props

  return <ButtonSection>{children}</ButtonSection>
}

const ButtonSection = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0px;
  left: 0px;
  background-color: ${(p) => p.theme.color.backgroundColor};
  padding: 16px;
  z-index: 2;
`

export default Button
