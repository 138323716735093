import styled from 'styled-components'

const TypeItem = (props) => {
  const { item = {} } = props
  const { name, img_url } = item
  const name_list = name.trim().split(' ')
  const content = name_list.map((it, i) => {
    return <Name key={i}>{it}</Name>
  })
  return (
    <Card>
      <Img src={img_url} />
      <Text>{content}</Text>
    </Card>
  )
}

const Card = styled.div`
  width: 80px;
  text-align: center;
`

const Img = styled.img`
  width: 64px;
`

const Text = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  margin-top: 8px;

  color: ${(p) => p.theme.color_level.grey.high};
`

const Name = styled.div`
  width: 100%;
  line-height: 1.4;
`

export default TypeItem
