import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { ErrorDialog } from 'dialog'
import { Market, Booking, Profile, Login } from 'icon'

import LoginDialog from './LoginDialog'

const UserMenu = (props) => {
  const [loading, setLoading] = useState(false)
  const [isLogin, setIsLogin] = useState(false)
  const [err, setErr] = useState('')
  const { visible, selected } = props
  const navigate = useNavigate()

  if (visible === false) {
    return <span />
  }

  const isUserLogin = props.member.isLogin()
  const getColor = (txt) => {
    return selected === txt ? '#305FED' : '#494949'
  }

  const getLink = (txt, link) => {
    return selected === txt ? undefined : () => navigate(link)
  }

  const onCloseError = () => setErr('')
  const onClose = () => setIsLogin(false)

  const onLogin = async () => {
    try {
      setLoading(true)
      setErr('')
      const pathname = window.location.pathname
      const search = window.location.search || ''
      const redirect = `${pathname}${search}`
      console.log('user redirect:', redirect)
      await props.member.lineLogin({ redirect, navigate })
    } catch (e) {
      setErr(e.message)
    }
    onClose()
    setLoading(false)
  }

  const getMemberMenu = () => {
    const bookingColor = getColor('booking')
    const profileColor = getColor('account')

    return (
      <>
        <Btn onClick={getLink('booking', '/account/booking')}>
          <Booking color={bookingColor} />
          <Label style={{ color: bookingColor }}>การจอง</Label>
        </Btn>
        <Btn onClick={getLink('account', '/account')}>
          <Profile color={profileColor} />
          <Label style={{ color: profileColor }}>บัญชีของฉัน</Label>
        </Btn>
      </>
    )
  }

  const getMenu = () => {
    const loginColor = getColor('login')
    return (
      <Btn onClick={() => setIsLogin(true)}>
        <Login color={loginColor} />
        <Label style={{ color: loginColor }}>เข้าสู่ระบบ</Label>
      </Btn>
    )
  }

  const marketColor = getColor('market')
  const menu = isUserLogin ? getMemberMenu() : getMenu()

  return (
    <Wrapper>
      <ContentView>
        <Btn onClick={getLink('market', '/')}>
          <Market color={marketColor} />
          <Label style={{ color: marketColor }}>ตลาด</Label>
        </Btn>
        {menu}
      </ContentView>
      <LoginDialog
        visible={isLogin}
        loading={loading}
        onClose={onClose}
        onLogin={onLogin}
      />
      <ErrorDialog
        message_list={['ไม่สามารถเข้าสู่ระบบได้', 'กรุณาลองใหม่อีกครั้ง']}
        error={err}
        onClose={onCloseError}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  background-color: white;
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 10;
`

const ContentView = styled.div`
  width: 100%;
  max-width: 800px;
  height: 90px;
  margin: 0 auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-around;
`

const Btn = styled.div`
  text-align: center;
  width: 100px;
  cursor: pointer;
`

const Label = styled.div`
  font-size: 12px;
`

export default inject('member')(observer(UserMenu))
