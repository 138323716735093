import styled from 'styled-components'
import { Button } from 'antd'

const Btn = (props) => {
  const onClick = () => {
    let { name, onClick } = props
    if (onClick) onClick(name)
  }

  let { disabled, children, text, loading } = props

  let click
  if (!loading && !disabled) {
    click = onClick
  }
  return (
    <PageView>
      <Button disabled={disabled} loading={loading} onClick={click}>
        {children || text}
      </Button>
    </PageView>
  )
}

const PageView = styled.div`
  button {
    color: ${(p) => p.theme.color_level.grey.dark};
    background-color: white;
    width: 100%;
    height: 48px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: 0px;
  }

  button:hover,
  button:focus {
    color: ${(p) => p.theme.color_level.grey.dark};
  }
`

export default Btn
