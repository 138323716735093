import styled from 'styled-components'

const Title = (props) => {
  const { text } = props

  return <Text>{text}</Text>
}

const Text = styled.div`
  width: 100%;
  font-size: 18px;
  margin-bottom: 18px;
  color: ${(p) => p.theme.color.blue};
`

export default Title
