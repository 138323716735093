import styled from 'styled-components'

const Content = (props) => {
  const { children } = props
  return <PageView>{children}</PageView>
}

const PageView = styled.div`
  width: 100%;
  max-width: 1440px;
`

export default Content
