import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import queryString from 'query-string'

import Footer from 'footer'
import { Meta, BackHeader, Title, Content } from 'components/display'

const AboutUs = (props) => {
  const navigate = useNavigate()
  const location = useLocation()

  const onBack = () => {
    const { source } = queryString.parse(location.search)
    const path = source ? source : '/'
    navigate(path)
  }

  return (
    <PageView>
      <Meta title="คำถามที่พบบ่อย" />
      <BackHeader onClick={onBack} isShort={true} />
      <Content isHeader={true}>
        <ContentView>
          <Title text="คำถามที่พบบ่อย" />
          <Label>ค้นหาตลาดไม่เจอ</Label>
          <Text>
            หลักการค้นหาตลาดให้ท่านใส่ชื่อบางส่วน
            หรือทั้งหมดของตลาดที่ท่านต้องการหาเข้าไป
          </Text>

          <Label>จองอย่างไร</Label>
          <Text>ง่ายๆได้ 3 ขั้นตอน</Text>
          <List>
            <li>1.ค้นหาตลาดที่ถูกใจ</li>
            <li>2.เลือกจองล็อกผ่านเว็บไซต์ หรือผ่านไลน์ OA</li>
            <li>3.โอนเงินและแนบสลิปในระบบ</li>
          </List>
        </ContentView>
      </Content>
      <Footer />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  max-width: ${(p) => p.theme.max};
  margin: 0 auto;
  color: ${(p) => p.theme.color_level.grey.high};
  background-color: white;
`

const ContentView = styled.div`
  width: 100%;
  min-height: 80vh;
`

const Label = styled.div`
  font-weight: bold;
  margin-top: 20px;
`

const Text = styled.div`
  a {
    color: ${(p) => p.theme.color.blue};
  }
`

const List = styled.ul``

export default AboutUs
