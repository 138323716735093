import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  name: '',
  img_list: [],
}

let state
export class Market extends BaseStore {
  constructor() {
    super()
    this.observable({
      hot_market_list: [],
      market_list: [],
      doc: cloneDeep(original),
      short_info: cloneDeep(original),
      market_bank: {},
    })
    state = this
  }

  async getHotMarket() {
    const url = `${config.api}/v1/public/market/market-info/hot-market`
    const res = await http.get(url)

    const list = res.body || []
    runInAction(() => {
      state.hot_market_list = list
      state.market_list = list
    })
  }

  async getMarketList(query = {}) {
    const { keyword } = query

    let q = ''
    if (keyword) q += `keyword=${keyword}`

    const url = `${config.api}/v1/public/market/market-info?${q}`
    const res = await http.get(url)

    const list = res.body || []
    runInAction(() => {
      state.market_list = list
    })
  }

  async getMarketArea(id) {
    const params = id ? `/market-area/${id}` : ''
    const url = `${config.api}/v1/public/market/market-info${params}`
    const res = await http.get(url)

    const list = res.body || []
    runInAction(() => {
      state.market_list = list
    })
  }

  async getMarketType(id) {
    const url = `${config.api}/v1/public/market/market-info/market-type/${id}`
    const res = await http.get(url)

    const list = res.body || []
    runInAction(() => {
      state.market_list = list
    })
  }

  async getMarketInfo(id) {
    const { doc = {} } = this.toJS()
    if (doc.market_id === +id) {
      return
    }

    this.setMarketInfo(cloneDeep(original))

    const url = `${config.api}/v1/public/market/market-info/detail/${id}`
    const res = await http.get(url)

    const data = res.body || {}
    this.setMarketInfo(data)
    return data
  }

  setMarketInfo(data = {}) {
    runInAction(() => {
      state.doc = data
      state.short_info = data
    })
  }

  async getMarketShortInfo(id) {
    const { short_info = {} } = this.toJS()
    if (short_info.market_id === +id || !id) {
      return short_info
    }

    this.setMarketShortInfo(cloneDeep(original))

    const url = `${config.api}/v1/public/market/market-info/detail/${id}/short`
    const res = await http.get(url)

    const data = res.body || {}
    this.setMarketShortInfo(data)

    return data
  }

  setMarketShortInfo(data = {}) {
    runInAction(() => {
      state.short_info = data
    })
  }

  async getMarketBank(id) {
    const { market_bank = {} } = this.toJS()
    if (market_bank.market_id === +id) {
      return
    }

    const url = `${config.api}/v1/public/market/market-info/detail/${id}/bank`
    const res = await http.get(url)

    const data = res.body || {}
    runInAction(() => {
      state.market_bank = data
    })
  }
}

export default new Market()
