import styled from 'styled-components'

import { Marget } from 'icon'
import { Link } from 'components/link'
import { helper } from 'utils'

import BookingType from './BookingType'

const MarketImage = (props) => {
  const { item = {}, link } = props
  const img_url = helper.getUrl(item.img_list)

  const img =
    img_url === undefined ? (
      <Coming>
        <Marget size={0.6} />
      </Coming>
    ) : (
      <Image src={img_url} />
    )
  return (
    <PageView>
      <BookingType item={item} />
      <Link to={link}>{img}</Link>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  position: relative;
`

const Image = styled.img`
  width: 100%;
  height: auto;
  height: 136px;
  border-radius: 12px;
  object-fit: cover;
`

const Coming = styled.div`
  width: 100%;
  height: 136px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 1px solid ${(p) => p.theme.color_level.grey.light};
`

export default MarketImage
