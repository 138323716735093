/* eslint-disable */

const Logo = (props) => {
  const { size = 1, color = '#ED3034' } = props

  const width = `${Math.ceil(20 * size)}`
  const height = `${Math.ceil(20 * size)}`
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill={color} />
      <path
        d="M6.42385 14.3842L9.99996 10.8081L13.5761 14.3842C13.7992 14.6074 14.161 14.6074 14.3842 14.3842C14.6073 14.161 14.6073 13.7993 14.3842 13.5761L10.8081 10L14.3842 6.42389C14.6074 6.20073 14.6074 5.83894 14.3842 5.61577C14.161 5.3926 13.7992 5.3926 13.5761 5.61577L9.99996 9.19188L6.42385 5.61577C6.20068 5.3926 5.83889 5.3926 5.61572 5.61577C5.5042 5.72729 5.44836 5.87399 5.44836 6.01983C5.44836 6.16572 5.50423 6.31234 5.61567 6.42384L5.61572 6.42389L9.19184 10L5.61572 13.5761C5.5042 13.6876 5.44836 13.8343 5.44836 13.9802C5.44836 14.1261 5.50423 14.2727 5.61567 14.3842L5.61572 14.3842C5.83889 14.6074 6.20068 14.6074 6.42385 14.3842Z"
        fill="white"
        stroke="white"
        strokeWidth="0.5"
      />
    </svg>
  )
}
/* eslint-enable */
export default Logo
