import { runInAction } from 'mobx'

import {
  http,
  timer,
  helper,
  error,
  product as utilsProduct,
  storage,
} from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

let state
const key = {
  order: 'order',
  shop: 'shop',
}
export class Order extends BaseStore {
  constructor() {
    super()
    this.observable({
      new_shop: {
        name: '',
        product_type: undefined,
        shop_name: '',
        img_list: [],
      },
      account: {
        name: '',
        mobile: '',
        tax_required: false,
        tax_id: '',
        tax_name: '',
        branch_code: '',
        company_mobile: '',
        tax_address: '',
        receive_type: 'onsite',
        receive_name: '',
        receive_mobile: '',
        receive_address: '',
      },
      shop: {
        shop_id: undefined,
        product_type: undefined,
        name: '',
        shop_name: '',
        detail: '',
        img_list: [],
      },
      order: {
        market_id: undefined,
        list: [],
        total: 0,
        note: '',
      },
    })
    state = this
    this.loadShop()
    this.loadOrder()
  }

  setNewShop(data) {
    runInAction(() => {
      state.new_shop = data
    })
  }

  setAccount(data) {
    runInAction(() => {
      state.account = data
    })
  }

  setShop(data) {
    const created_at = timer.get()
    storage.save(key.shop, { created_at, shop: data })

    runInAction(() => {
      state.shop = data
    })
  }

  setOrder(list, note = '') {
    const product = list.length > 0 ? list[0] : {}

    let total = 0
    for (const item of list) {
      const { total_price, promotion_price, deposit_type, deposit } = item
      const cost = deposit_type === 'yes' ? deposit || 0 : 0

      const price = promotion_price === 0 ? total_price : promotion_price

      total += price + cost
    }

    const order = {
      market_id: product.market_id,
      booking_type: product.booking_type,
      note,
      list,
      total,
    }

    const created_at = timer.get()
    storage.save(key.order, { created_at, order })
    runInAction(() => {
      state.order = order
    })
  }

  loadShop() {
    const data = storage.load(key.shop)
    if (!data) return

    const yesterday = timer.get().subtract(1, 'days')
    const created_at = timer.get(data.created_at)

    if (created_at < yesterday) {
      return
    }
    const shop = data.shop
    runInAction(() => {
      state.shop = shop
    })
  }

  loadOrder() {
    const data = storage.load(key.order)
    if (!data) return

    const yesterday = timer.get().subtract(1, 'days')
    const created_at = timer.get(data.created_at)

    if (created_at < yesterday) {
      return
    }
    const order = data.order
    order.note = order.note || ''
    const list = order.list || []
    order.list = list.map((it) => {
      it.start_at = timer.get(it.start_at)
      it.finish_at = timer.get(it.finish_at)
      return it
    })

    runInAction(() => {
      state.order = order
    })
  }

  clear() {
    this.setOrder([])
  }

  removeProduct(index, list) {
    list.splice(index, 1)

    this.setOrder(list)
  }

  toPromotion(promotion) {
    if (promotion) {
      const { promotion_id, name, value, start_at, finish_at } = promotion

      return { promotion_id, name, value, start_at, finish_at }
    }
  }

  addProduct(unit, product, option = {}) {
    const { promotion, round, price, start_at, finish_at, detail, days_list } =
      option
    const { market_id, floorplan_id, unit_id, name } = unit
    const {
      product_id,
      market_open_id,
      booking_type,
      type_clothes,
      type_drink,
      type_food,
      type_services,
      type_things,
      deposit_type,
      deposit,
    } = product

    const { total_price, promotion_price, price_list, days } =
      utilsProduct.calcPrice(
        product,
        start_at,
        finish_at,
        price,
        days_list,
        promotion
      )

    const data = {
      market_id,
      floorplan_id,
      unit_id,
      product_id,
      market_open_id,
      name,
      price,
      days,
      total_price,
      promotion_price,
      price_list,
      detail,
      round,
      booking_type,
      type_clothes,
      type_drink,
      type_food,
      type_services,
      type_things,
      start_at,
      finish_at,
      deposit_type,
      deposit,
      promotion: this.toPromotion(promotion),
    }

    const { list } = this.toJS().order

    list.push(data)
    this.setOrder(list)
  }

  async updateImg({ image, index }) {
    const url = `${config.api}/v1/user/market/shop/image`
    const json = { image, index }

    const res = await http.post(url, { json, check: false })

    const isError = res.status !== 200
    const { img_url, message } = res.body
    return { isError, img_url, index, message }
  }

  async updateImgList(doc) {
    const img_list = doc.img_list || []
    const upload_list = []
    let index = 0
    for (const image of img_list) {
      if (helper.isUrl(image) === false) {
        upload_list.push(this.updateImg({ image, index }))
      }

      index++
    }

    const list = await Promise.all(upload_list)

    let errorMessage = null
    for (const item of list) {
      const { isError, img_url, index, message } = item
      if (isError) {
        errorMessage = message
      } else {
        img_list[index] = img_url
      }
    }

    doc.img_list = img_list
    error.isError(errorMessage !== null, errorMessage)
    return { ...doc }
  }

  async uploadShopImage(data = {}) {
    data.shop = await this.updateImgList(data.shop)

    return data
  }

  async createOrder(input) {
    const { is_create_shop } = input
    const json = is_create_shop ? await this.uploadShopImage(input) : input

    const url = `${config.api}/v1/user/market/order`
    const res = await http.post(url, { json })
    const data = res.body

    this.clear()
    return data.code
  }

  isInvalid(product, start_at, finish_at) {
    const start = start_at.startOf('day')
    const finish = finish_at.startOf('day')
    const { market_id, booking_type, unit_id, market_open_id } = product
    const { list } = this.toJS().order
    for (const item of list) {
      if (item.market_id !== market_id) {
        return 'diff_market'
      }

      if (item.booking_type !== booking_type) {
        return 'diff_booking_type'
      }

      if (item.unit_id === unit_id && item.market_open_id === market_open_id) {
        const s = item.start_at.startOf('day')
        const f = item.finish_at.startOf('day')
        const inStart = timer.isBetween(start, s, f)
        const inFinish = timer.isBetween(finish, s, f)
        if (inStart || inFinish) {
          return 'unavailable'
        }
      }
    }

    return undefined
  }

  isShopInvalid(shop) {
    const { list } = this.toJS().order
    for (const item of list) {
      const valid = utilsProduct.matchProductType(shop.product_type, item)
      if (valid === false) return true
    }

    return false
  }

  async getShopInfo(shop_id) {
    const { shop = {} } = this.toJS()
    if (shop.shop_id === shop_id && shop.product_type !== '') {
      return
    }

    const url = `${config.api}/v1/user/market/shop/${shop_id}`
    const resp = await http.get(url)
    const doc = resp.body || {}

    runInAction(() => {
      state.shop = doc
    })
  }

  async getUserAddress({ user = {} } = {}) {
    const url = `${config.api}/v1/user/market/user-address`
    const res = await http.get(url)
    const data = res.body
    data.tax_required = false

    data.name = data.name !== '' ? data.name : user.name
    data.mobile = data.mobile !== '' ? data.mobile : user.mobile
    runInAction(() => {
      state.account = data
    })

    return data
  }

  async updateUserAddress(json = {}) {
    const url = `${config.api}/v1/user/market/user-address`
    await http.put(url, { json })

    runInAction(() => {
      state.account = json
    })
  }

  checkUserShop({ user_id } = {}) {
    const { shop } = this.toJS()
    const { created_user_id } = shop

    if (user_id !== created_user_id) {
      runInAction(() => {
        state.shop = {
          shop_id: undefined,
          product_type: undefined,
          name: '',
          shop_name: '',
          detail: '',
          img_list: [],
        }
      })
    }
  }

  updateShop(shop_id, info) {
    const shop = this.toJS().shop
    if (shop.shop_id === shop_id) {
      info.shop_id = shop_id
      this.setShop(info)
    }
  }

  deleteShop({ shop_id }) {
    const shop = this.toJS().shop
    if (shop.shop_id === shop_id) {
      storage.remove(key.order)

      runInAction(() => {
        state.shop = {
          shop_id: undefined,
          product_type: undefined,
          name: '',
          shop_name: '',
          detail: '',
          img_list: [],
        }
      })
    }
  }
}

export default new Order()
