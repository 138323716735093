import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row } from 'antd'

import { Card, Col, Dot } from 'components/display'
import { PrimaryBtn, Btn } from 'components/button'

import Header from './Header'
import Product from './Product'
import Contact from './Contact'
import Total from './Total'
import PaymentInfo from './PaymentInfo'
import NewOffer from './NewOffer'
import QrCodeBot from './QrCodeBot'
import Button from './Button'

const OrderInfo = (props) => {
  const { cancelling, onOffer, onNext, onCancel } = props

  const { order } = props.my_order.toJS()

  const { total } = order
  const list = order.list || []
  const len = list.length - 1
  const content = list.map((it, i) => {
    const isDot = i !== len
    return <Product key={i} isDot={isDot} item={it} />
  })

  const getButton = () => {
    switch (order.status) {
      case 'create':
      case 'offer':
        return (
          <Button>
            <Center>
              <Btn
                loading={cancelling}
                text="ยกเลิกการจอง"
                onClick={onCancel}
              />
            </Center>
          </Button>
        )
      case 'payment':
        return (
          <Button>
            <PaymentBtn>
              <Row>
                <Col lg={12} md={12} xs={24} position="left-responsive">
                  <Btn
                    loading={cancelling}
                    text="ยกเลิกการจอง"
                    onClick={onCancel}
                  />
                </Col>
                <Col lg={12} md={12} xs={24} position="right-responsive">
                  <PrimaryBtn
                    disabled={cancelling}
                    text="โอนเงิน"
                    onClick={onNext}
                  />
                </Col>
              </Row>
            </PaymentBtn>
          </Button>
        )
      default:
        return undefined
    }
  }

  const btn = getButton()

  return (
    <PageView>
      <Header order={order} />
      <NewOffer order={order} onClick={onOffer} />
      <Card>
        <MarketName>{order.market_name}</MarketName>
        {content}
        <Dot />
        <Contact order={order} />
        <Dot />
        <Total total={total} />
        <PaymentInfo order={order} />
      </Card>

      <Space />
      <Card>
        <QrCodeBot />
      </Card>
      {btn}
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 0px 16px;
  margin-bottom: 80px;
`

const MarketName = styled.div`
  width: 100%;
  font-size: 24px;
  line-height: 26px;
  font-weight: bold;
  color: ${(p) => p.theme.color.blue};
`

const Center = styled.div`
  width: 214px;
  margin: 0 auto;
`

const PaymentBtn = styled.div`
  width: 100%;
  max-width: 314px;
  margin: 0 auto;
`

const Space = styled.div`
  padding: 8px 0px;
`

export default inject('my_order')(observer(OrderInfo))
