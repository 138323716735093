import styled from 'styled-components'
import { Row, Col } from 'antd'
import { useLocation } from 'react-router-dom'

import { config } from 'config'
import { Link } from 'components/link'

const Menu = (props) => {
  const location = useLocation()
  const pathname = location.pathname

  const getLink = (name, text) => {
    return (
      <Link to={pathname === name ? name : `${name}?source=${pathname}`}>
        {text}
      </Link>
    )
  }

  return (
    <PageView>
      <Row>
        <Col lg={24} md={24} xs={6}>
          <Line>{getLink('/about-us', 'เกี่ยวกับเรา')}</Line>
        </Col>
        <Col lg={24} md={24} xs={6}>
          <LineCenter>{getLink('/contact-us', 'ติดต่อเรา')}</LineCenter>
        </Col>
        <Col lg={24} md={24} xs={6}>
          <LineCenter>{getLink('/faq', 'คำถามที่พบบ่อย')}</LineCenter>
        </Col>
        <Col lg={24} md={24} xs={6}>
          <LineRight>
            <a href={config.market_url} target="_blank" rel="noreferrer">
              จัดการตลาด
            </a>
          </LineRight>
        </Col>
      </Row>
    </PageView>
  )
}

const PageView = styled.div``

const Line = styled.div`
  padding: 2px;
`

const LineCenter = styled.div`
  padding: 2px;

  ${(p) => p.theme.media.mobile} {
    text-align: center;
  }
`

const LineRight = styled.div`
  padding: 2px;

  a {
    color: white;
  }

  ${(p) => p.theme.media.mobile} {
    text-align: right;
  }
`

export default Menu
