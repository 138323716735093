import styled from 'styled-components'

import { InputArea } from 'components/input'

import Label from './Label'
import Transfer from './Transfer'
import TaxInvoice from './TaxInvoice'

const def = () => {}
const Payment = (props) => {
  const {
    loading,
    account = {},
    order = {},
    market_info = {},
    onOrder = def,
    onAccount,
  } = props

  const onCh = (val) => {
    order.note = val
    onOrder(order)
  }

  const onAccountChange = async (data, isSave) => {
    await onAccount(data, isSave)
  }

  return (
    <PageView>
      <Label text="วิธีชำระเงิน" />
      <Info>
        <Transfer />
        <TextContent>
          <Title>โอน/ชำระผ่านบัญชีธนาคาร</Title>
          <Text>รอยืนยันการจองจากเจ้าหน้าที่ ก่อนชำระเงิน</Text>
        </TextContent>
      </Info>

      <TaxInvoice
        loading={loading}
        account={account}
        market_info={market_info}
        onAccount={onAccountChange}
      />
      <InputArea
        label="ข้อความถึงเจ้าหน้าที่ (ถ้ามี)"
        rows={2}
        value={order.note}
        onChange={onCh}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Info = styled.div`
  width: 100%;
  margin-top: 16px;
  background-color: white;
  padding: 16px 24px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  column-gap: 20px;
`

const TextContent = styled.div`
  width: 100%;
`

const Title = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
`

const Text = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 14px;
`

export default Payment
