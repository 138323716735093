import styled from 'styled-components'

import { product } from 'utils'

const Information = (props) => {
  const { doc = {} } = props

  let shop_name = doc.shop_name || ''
  shop_name = shop_name !== '' ? shop_name : 'ไม่ระบุ'
  return (
    <PageView>
      <Info>
        <Title>{doc.name}</Title>
        <Label>
          หมวดหมู่ :<span>{product.toProductType(doc)}</span>
        </Label>
        <Label>
          ร้าน :<span>{shop_name}</span>
        </Label>
      </Info>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  color: ${(p) => p.theme.color_level.grey.high};
`

const Info = styled.div`
  flex: auto;
  padding: 0px 16px;
`

const Title = styled.div`
  font-size: 24px;
  color: ${(p) => p.theme.color.blue};
`

const Label = styled.div`
  font-size: 16px;
  font-weight: bold;

  span {
    font-weight: normal;
    margin-left: 4px;
  }
`

export default Information
