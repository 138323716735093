import { useState, useEffect, useCallback, useRef } from 'react'
import { inject, observer } from 'mobx-react'

import Loading from 'components/loading'
import { Market } from 'components/market'

import Title from './Title'
import Content from './Content'
import Carousel from './Carousel'
import Card from './Card'

const HotMarket = (props) => {
  const [loading, setLoading] = useState(false)
  const ref = useRef(true)
  const onLoad = useCallback(async () => {
    try {
      setLoading(true)
      await props.market.getHotMarket()
    } catch (e) {
      console.log('e', e.message)
    }
    if (ref.current) {
      setLoading(false)
    }
  }, [props.market])

  useEffect(() => {
    onLoad()
    return () => {
      ref.current = false
    }
  }, [onLoad])

  const market = props.market.toJS()
  const hot_market_list = market.hot_market_list || []
  const content = hot_market_list.slice(0, 10).map((it, i) => {
    return (
      <Card key={i}>
        <Market item={it} />
      </Card>
    )
  })
  return (
    <Content>
      <Title title="ตลาดยอดนิยม" to="/hot-market" />
      <Loading loading={loading}>
        <Carousel>{content}</Carousel>
      </Loading>
    </Content>
  )
}

export default inject('market')(observer(HotMarket))
