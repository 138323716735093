import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { BaseDrawer } from 'drawer'
import { PrimaryBtn } from 'components/button'

import Information from './Information'

const OfferDrawer = (props) => {
  const { visible, onClose } = props

  const { order } = props.my_order.toJS()

  const onPrint = () => window.print()

  if (!visible) return <div />

  return (
    <BaseDrawer
      visible={visible}
      title="ใบเสร็จรับเงิน"
      height="90%"
      onClose={onClose}
    >
      <PageView>
        <Title>ใบเสร็จรับเงิน</Title>
        <Information order={order} />

        <Button>
          <Btn>
            <PrimaryBtn text="Print" onClick={onPrint} />
          </Btn>
        </Button>
      </PageView>
    </BaseDrawer>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 20px 0px 40px 0px;
`

const Title = styled.div`
  width: 100%;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
  margin-bottom: 16px;
  text-align: center;
  display: none;

  @media print {
    display: block;
  }
`

const Button = styled.div`
  width: 100%;
  padding: 18px 24px;
  background-color: white;
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 10;
`

const Btn = styled.div`
  width: 100%;
  max-width: 314px;
  margin: 0 auto;
`

export default inject('my_order')(observer(OfferDrawer))
