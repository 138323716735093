import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http, error, helper } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  name: '',
}

let state
export class Shop extends BaseStore {
  constructor() {
    super()
    this.observable({
      shop_list: [],
      doc: cloneDeep(original),
    })
    state = this
  }

  async getMyShopList() {
    const shop_list = this.toJS().shop_list
    if (shop_list.length > 0) {
      return shop_list
    }
    const url = `${config.api}/v1/user/market/shop`
    const resp = await http.get(url)
    const list = resp.body || []

    runInAction(() => {
      state.shop_list = list
    })

    return list
  }

  async getShopInfo(id) {
    if (!id) {
      runInAction(() => {
        state.doc = cloneDeep(original)
      })
      return
    }

    const url = `${config.api}/v1/user/market/shop/${id}`
    const resp = await http.get(url)
    const doc = resp.body || cloneDeep(original)

    runInAction(() => {
      state.doc = doc
    })
  }

  async updateImg({ shop_id, image, index }) {
    const url = `${config.api}/v1/user/market/shop/${shop_id}/image`
    const json = { image, index }

    const res = await http.put(url, { json, check: false })

    const isError = res.status !== 200
    const { img_url, message } = res.body
    return { isError, img_url, index, message }
  }

  async updateImgList(shop_id, doc) {
    const img_list = doc.img_list || []
    const upload_list = []
    let index = 0
    for (const image of img_list) {
      if (helper.isUrl(image) === false) {
        upload_list.push(this.updateImg({ shop_id, image, index }))
      }

      index++
    }

    const list = await Promise.all(upload_list)

    let errorMessage = null
    for (const item of list) {
      const { isError, img_url, index, message } = item
      if (isError) {
        errorMessage = message
      } else {
        img_list[index] = img_url
      }
    }

    doc.img_list = img_list
    error.isError(errorMessage !== null, errorMessage)
    return { ...doc }
  }

  async createShop(json = {}) {
    json = await this.updateImgList(0, json)

    const url = `${config.api}/v1/user/market/shop`
    const res = await http.post(url, { json })
    const data = res.body

    const shop_list = this.toJS().shop_list
    shop_list.push(data)

    runInAction(() => {
      state.shop_list = shop_list
    })
  }

  async updateShop(shop_id, json = {}) {
    json = await this.updateImgList(shop_id, json)

    const url = `${config.api}/v1/user/market/shop/${shop_id}`
    const res = await http.put(url, { json })
    const data = res.body

    const shop_list = this.toJS().shop_list
    const i = shop_list.findIndex((it) => it.shop_id === shop_id)
    if (i !== -1) {
      shop_list[i] = data
      runInAction(() => {
        state.shop_list = shop_list
        state.doc = data
      })
    } else {
      runInAction(() => {
        state.doc = data
      })
    }
  }

  async deleteShop({ shop_id } = {}) {
    const url = `${config.api}/v1/user/market/shop/${shop_id}/delete`
    await http.put(url)
    const shop_list = this.toJS().shop_list
    const i = shop_list.findIndex((it) => it.shop_id === +shop_id)
    if (i !== -1) {
      shop_list.splice(i, 1)
      runInAction(() => {
        state.shop_list = shop_list
      })
    }
  }

  clearShopList() {
    runInAction(() => {
      state.shop_list = []
    })
  }
}

export default new Shop()
