import React from 'react'
import styled from 'styled-components'

const ContentView = (props) => {
  const { children } = props

  return <PageView>{children}</PageView>
}

const PageView = styled.div`
  width: 100%;
  margin-top: 20px;
`

export default ContentView
