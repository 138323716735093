import ReactLoading from 'react-loading'
import styled from 'styled-components'

const Loading = (props) => {
  const { loading = false, size = 1, top, children } = props

  if (loading === false) return children || <div />

  const height = size * 50
  const width = size * 100
  return (
    <Display style={{ paddingTop: top }}>
      <ReactLoading
        type={'bubbles'}
        color={'#305FED'}
        height={height}
        width={width}
      />
    </Display>
  )
}

const Display = styled.div`
  z-index: 100;
  div {
    margin: 0 auto;
  }
`

export default Loading
