import styled from 'styled-components'

import { Row, Col } from 'components/display'
import { Input, SelectList, Radio } from 'components/input'
import { DatePicker } from 'components/date'

const AccountInfo = (props) => {
  const { user = {}, validate, province_list, onChange } = props
  const getRow = (c1, c2) => {
    return (
      <Row align="middle">
        <Col {...col} position="left-responsive">
          {c1}
        </Col>

        <Col {...col} position="right-responsive">
          {c2}
        </Col>
      </Row>
    )
  }

  return (
    <Form>
      {getRow(
        <Input
          label="ชื่อ"
          name="name"
          value={user.name}
          onChange={onChange}
          invalid={validate['name']}
        />,
        <Input
          label="นามสกุล"
          name="surname"
          value={user.surname}
          onChange={onChange}
          invalid={validate['surname']}
        />
      )}
      {getRow(
        <Radio
          label="เพศ"
          menu={gender_menu}
          name="gender"
          value={user.gender}
          onChange={onChange}
          invalid={validate['gender']}
        />,
        <Input
          label="เบอร์มือถือ 10 หลัก"
          name="mobile"
          value={user.mobile}
          onChange={onChange}
          invalid={validate['mobile']}
          isNumber={true}
          pattern="xxx-xxx-xxxx"
        />
      )}
      {getRow(
        <Input
          label="เลขบัตรประชาชน"
          name="citizen_id"
          value={user.citizen_id}
          onChange={onChange}
          invalid={validate['citizen_id']}
          isNumber={true}
          pattern="x-xxxx-xxxxx-xx-x"
        />,
        <DatePicker
          placeholder="วันเดือนปีเกิด"
          name="birthday_at"
          value={user.birthday_at}
          onChange={onChange}
          invalid={validate['birthday_at']}
          isSelector={true}
        />
      )}
      {getRow(
        <SelectList
          placeholder="จังหวัดที่อาศัย"
          menu={province_list}
          name="province_name"
          value={user.province_name}
          onChange={onChange}
          filter={true}
          invalid={validate['province_name']}
        />
      )}
    </Form>
  )
}

const col = {
  lg: 12,
  md: 12,
  xs: 24,
}

const gender_menu = [
  { name: 'ชาย', value: 'male' },
  { name: 'หญิง', value: 'female' },
]

const Form = styled.div`
  width: 550px;
  margin: 0 auto;

  ${(p) => p.theme.media.mobile} {
    width: 100%;
  }
`

export default AccountInfo
