import styled from 'styled-components'

import { Next } from 'icon'
import { helper, product } from 'utils'

const Shop = (props) => {
  const { inactive, doc = {}, onClick } = props

  let shop_name = doc.shop_name || ''
  shop_name = shop_name !== '' ? shop_name : 'ไม่ระบุ'

  const img_list = doc.img_list || []
  const img_url = helper.getUrl(doc.img_list)
  const css = inactive ? 'inactive' : undefined
  const click = inactive ? undefined : onClick

  return (
    <PageView className={css} onClick={click}>
      <ProductImg src={img_url} />
      <Info>
        <Title>{doc.name}</Title>
        <Label>
          หมวดหมู่ :<span>{product.toProductType(doc)}</span>
        </Label>
        <Label>
          ร้าน :<span>{shop_name}</span>
        </Label>
      </Info>
      <Next />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  color: ${(p) => p.theme.color_level.grey.high};
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: center;
  column-gap: 16px;
  cursor: pointer;

  &.inactive {
    background-color: ${(p) => p.theme.color.disable};
    cursor: default;
  }
`

const ProductImg = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 4px;
`

const Info = styled.div`
  flex: auto;
`

const Title = styled.div`
  font-size: 24px;
  color: ${(p) => p.theme.color.blue};
`

const Label = styled.div`
  font-size: 14px;
  font-weight: bold;

  span {
    font-weight: normal;
    margin-left: 4px;
  }
`

export default Shop
