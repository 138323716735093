/* eslint-disable */

const Logo = (props) => {
  const { size = 1, color = '#494949' } = props

  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_591_1887)">
        <path
          d="M19.2703 1H4.72973C2.12175 1 0 3.12175 0 5.72973C0 8.33772 2.12175 10.4595 4.72973 10.4595H9.17358V14.2807L9.00544 14.1396C8.17425 13.4421 6.87358 13.4988 6.10627 14.2662C5.30217 15.0703 5.35482 16.3483 6.22922 17.0819L9.17354 19.5526V21.9336C9.17354 22.3057 9.47523 22.6074 9.84736 22.6074H17.9333C18.3054 22.6074 18.6071 22.3057 18.6071 21.9336V14.3617C18.6071 13.344 17.8455 12.4816 16.8355 12.3558L13.2165 11.9049V10.4594H19.2703C21.8783 10.4594 24 8.33763 24 5.72964C24 3.12166 21.8783 1 19.2703 1ZM10.5212 21.2598V19.9121H17.2595V21.2598H10.5212ZM16.669 13.6931C17.0056 13.735 17.2595 14.0225 17.2595 14.3617V18.5645H10.0926L7.09545 16.0495C6.82942 15.8263 6.81329 15.465 7.0592 15.2191C7.33538 14.9429 7.84003 14.9209 8.13921 15.172L9.41427 16.2419C9.85163 16.6088 10.5212 16.2975 10.5212 15.7257V7.10938C10.5212 6.73783 10.8235 6.43555 11.1951 6.43555C11.5666 6.43555 11.8689 6.73783 11.8689 7.10938V12.5C11.8689 12.8399 12.1221 13.1266 12.4594 13.1687L16.669 13.6931ZM19.2703 9.11177H13.2165V7.10938C13.2165 5.99473 12.3097 5.08789 11.195 5.08789C10.0804 5.08789 9.17354 5.99473 9.17354 7.10938V9.11177H4.72969C2.86485 9.11177 1.34766 7.59458 1.34766 5.72973C1.34766 3.86489 2.86485 2.34766 4.72973 2.34766H19.2703C21.1352 2.34766 22.6523 3.86485 22.6523 5.72973C22.6523 7.59462 21.1352 9.11177 19.2703 9.11177Z"
          fill={color}
          stroke={color}
          strokeWidth="0.25"
        />
        <path
          d="M18.7573 3.89271L16.5384 6.11154L15.6672 5.24037C15.4041 4.97721 14.9774 4.97721 14.7143 5.24037C14.4511 5.50352 14.4511 5.93014 14.7143 6.19329L16.0619 7.54095C16.325 7.8041 16.7517 7.80415 17.0149 7.54095L19.7102 4.84564C19.9733 4.58249 19.9733 4.15586 19.7102 3.89271C19.4471 3.62951 19.0204 3.62951 18.7573 3.89271Z"
          fill={color}
          stroke={color}
          strokeWidth="0.25"
        />
      </g>
      <defs>
        <clipPath id="clip0_591_1887">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
/* eslint-enable */
export default Logo
