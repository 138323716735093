const config = {
  mode: 'localhost',
  firebase: {
    apiKey: 'AIzaSyAaQogn3tmBR8Gn2TcsTaU2BLMLh2yD1tE',
    authDomain: 'marget-app.firebaseapp.com',
    projectId: 'marget-app',
    storageBucket: 'marget-app.appspot.com',
    messagingSenderId: '466684017658',
    appId: '1:466684017658:web:f965fcb36b0a51b3ce0243',
    measurementId: 'G-4ERJK4EJPW',
  },
  market_url: 'https://market.marget.app',
  line: {
    liffId: '1657172454-9AgE7Kry',
  },
  limit: {
    file: 1600000,
  },
  account: {
    logo_url:
      'https://hlo-drive.sgp1.digitaloceanspaces.com/marget/web/Krungsri%20Logo.png',
    name: 'นายธนโชติ เกียรติบรรจง',
    no: '0019627789',
    img_url: undefined,
  },
  img_url: 'https://hlo-drive.sgp1.digitaloceanspaces.com/marget/web/Frame.svg',
  api: 'https://api.marget.app/api',
  version: '0.1',
}

module.exports = config
