import styled from 'styled-components'
import { Row } from 'antd'

import { Col } from 'components/display'
import { PrimaryBtn, Btn } from 'components/button'

const SaveBtn = (props) => {
  const {
    loading,
    disabled,
    onClick = () => {},
    deleting,
    text = {},
    onDelete,
  } = props

  if (onDelete) {
    return (
      <PageView>
        <BothButton>
          <Row>
            <Col span={12} position="left">
              <Btn
                loading={deleting}
                text={text.delete || 'ลบ'}
                onClick={onDelete}
              />
            </Col>

            <Col span={12} position="right">
              <PrimaryBtn
                loading={loading}
                disabled={disabled}
                text={text.save || 'บันทึก'}
                onClick={onClick}
              />
            </Col>
          </Row>
        </BothButton>
      </PageView>
    )
  }

  return (
    <PageView>
      <Button>
        <PrimaryBtn
          loading={loading}
          disabled={disabled}
          text={text.save || 'บันทึก'}
          onClick={onClick}
        />
      </Button>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 16px 0px;
  background-color: white;
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 10;
`

const Button = styled.div`
  width: 314px;
  margin: 0 auto;

  ${(p) => p.theme.media.mobile} {
    width: 90%;
  }
`

const BothButton = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  ${(p) => p.theme.media.mobile} {
    max-width: 90%;
  }
`

export default SaveBtn
