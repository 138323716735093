import styled from 'styled-components'
import { Row, Col } from 'antd'

import { Remove } from 'icon'
import { format, product } from 'utils'

const Product = (props) => {
  const { item = {}, index, onRemove } = props
  const {
    name,
    booking_type,
    detail,
    total_price,
    promotion_price,
    start_at,
    finish_at,
    round,
    deposit_type,
    deposit,
    promotion,
  } = item

  const price = promotion_price === 0 ? total_price : promotion_price

  const onClick = () => onRemove(index)
  const getDate = () => {
    const date = product.getDate(start_at, finish_at)

    return (
      <Text>
        วันที่ขาย:<span>{date}</span>
      </Text>
    )
  }

  const getDeposit = () => {
    if (deposit_type !== 'yes') return

    return (
      <>
        <Text>
          ค่าเช่า: <span>{format.toDigi(price)} บาท</span>
        </Text>
        <Text>
          มัดจำ/ประกัน: <span>{format.toDigi(deposit)} บาท</span>
        </Text>
      </>
    )
  }

  const getPromotion = () => {
    if (!promotion) {
      return
    }
    const text = product.getTextPromotion(promotion)
    return (
      <Text>
        โปรโมชั่น:
        <span>{text}</span>
      </Text>
    )
  }

  const bookingType = tag[booking_type] || tag.daily
  return (
    <PageView>
      <HeaderView>
        <Name>{name}</Name>
        <BookingType style={{ backgroundColor: bookingType.color }}>
          {bookingType.text}
        </BookingType>
        <Button onClick={onClick}>
          <Remove color={'#494949'} />
        </Button>
      </HeaderView>
      <ContentView>
        <Row>
          <Col span={16}>
            <Info>
              {getDate()}
              <Text>
                รอบ:<span>{round}</span>
              </Text>
              {getDeposit()}
              {getPromotion()}
              <Text>
                โซน:<span>{detail}</span>
              </Text>
            </Info>
          </Col>
          <Col span={8}>
            <Price>
              {format.toDigi(price + deposit)}
              <span>บาท</span>
            </Price>
          </Col>
        </Row>
      </ContentView>
    </PageView>
  )
}

const tag = {
  daily: { text: 'รายวัน', color: '#F55759' },
  monthly: { text: 'รายเดือน', color: '#577AF5' },
  round: { text: 'บูธอีเวนท์', color: '#00C767' },
}

const PageView = styled.div`
  width: 100%;
  padding: 20px;
  margin-bottom: 8px;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`

const HeaderView = styled.div`
  width: 100%;
  display: flex;
  column-gap: 8px;
  margin-bottom: 16px;
`

const BookingType = styled.div`
  font-size: 14px;
  padding: 2px 10px;
  color: white;
  background-color: ${(p) => p.theme.color.red};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ContentView = styled.div`
  width: 100%;
`

const Name = styled.div`
  font-size: 18px;
  font-weight: bold;
  width: fit-content;
  color: white;
  background-color: ${(p) => p.theme.color.green};
  border-radius: 4px;
  padding: 0px 8px;
`

const Info = styled.div`
  width: 100%;
`

const Text = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  color: ${(p) => p.theme.color_level.grey.high};
  span {
    font-weight: 400;
    margin-left: 4px;
  }
`

const Price = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 18px;
  color: ${(p) => p.theme.color.red};
  width: 100%;
  text-align: right;
  span {
    font-weight: 400;
    font-size: 14px;
    margin-left: 8px;
  }
`

const Button = styled.div`
  width: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
`

export default Product
