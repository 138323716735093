import styled from 'styled-components'

import { Line, Row, Col } from 'components/display'
import { Input, Radio } from 'components/input'

import Label from './Label'

const Information = (props) => {
  const {
    account,
    validate = {},
    onChange = () => {},
    onValidate = () => {},
  } = props
  const onCh = (val, name) => {
    account[name] = val
    onChange(account)

    delete validate[name]
    onValidate(validate)
  }

  return (
    <PageView>
      <Label text="ข้อมูลผู้เช่า" />
      <Info>
        <Row>
          <Col lg={12} md={12} xs={24} position="left-responsive">
            <Input
              label="ชื่อผู้เช่า"
              name="name"
              value={account.name}
              onChange={onCh}
              invalid={validate.name}
            />
          </Col>
          <Col lg={12} md={12} xs={24} position="right-responsive">
            <Input
              label="เบอร์ติดต่อ"
              name="mobile"
              isNumber={true}
              value={account.mobile}
              onChange={onCh}
              invalid={validate.mobile}
              pattern="xxx-xxx-xxxx"
            />
          </Col>
        </Row>
      </Info>
    </PageView>
  )
}
/*
<Line>
  <Radio menu={menu} />
</Line>

const menu = [
  { name: 'บุคคลธรรมดา', value: 'personal' },
  { name: 'นิติบุคคล', value: 'company' },
]
*/

const PageView = styled.div`
  width: 100%;
`

const Info = styled.div`
  width: 100%;
  margin-top: 16px;
`

export default Information
