import styled from 'styled-components'
import { Tooltip, Row } from 'antd'

import { Card, Col } from 'components/display'
import { ImgPicker } from 'components/picker'
import { format } from 'utils'

const Bank = (props) => {
  const {
    order = {},
    logo_url,
    bank_name,
    account_name,
    account_number,
    pattern,
    onSelect,
  } = props

  const getTitle = (no, text) => {
    return (
      <Title>
        <No>{no}</No>
        <div>{text}</div>
      </Title>
    )
  }

  const getText = (label, text) => {
    return (
      <Text>
        {label}
        <span>{text}</span>
      </Text>
    )
  }

  const onCopy = () => {
    const copyText = document.getElementById('account_number')

    copyText.setSelectionRange(0, 99999) // For mobile devices
    copyText.select()

    navigator.clipboard.writeText(copyText.value)
    //document.execCommand('copy')
  }

  const date = format.toThaiDatetime(order.due_payment_at)
  const accountNo = format.toPattern(account_number, pattern)
  const payment_url = order.payment_url || ''
  const content =
    payment_url !== '' ? (
      <Card>
        <Slip src={payment_url} />
      </Card>
    ) : undefined

  const qr = ''
  /*
  const qr = account.img_url ? (
    <QRCode>
      <img src={account.img_url} alt="" />
    </QRCode>
  ) : undefined
  */
  const logo = logo_url ? <Logo src={logo_url} /> : undefined
  const bank = bank_name ? (
    <Row align="middle">
      <Col span={24}>{getText('ขื่อบัญชี:', bank_name)}</Col>
    </Row>
  ) : undefined
  return (
    <PageView>
      {getTitle(
        '1',
        'ชำระเงินผ่าน Internet/Mobile Banking มายังบัญชีธนาคารของ Marget'
      )}
      <Card>
        {logo}
        {bank}

        <Row align="middle">
          <Col span={24}>{getText('ขื่อบัญชี:', account_name)}</Col>
        </Row>

        <Row align="middle">
          <Col span={18}>{getText('เลขที่บัญชี:', accountNo)}</Col>

          <Col span={6}>
            <Tooltip
              placement="top"
              color="#577AF5"
              trigger="click"
              title="copy เรียบร้อย"
            >
              <Copy onClick={onCopy}>COPY</Copy>
            </Tooltip>
          </Col>
        </Row>

        {qr}
      </Card>
      <AccountInput id="account_number" value={account_number} />

      {getTitle(
        '2',
        `อัพโหลดหลักฐานการโอนเงินเพื่อยืนยันการชำระเงิน ภายใน ${date}`
      )}

      <SlipTitle>อัพโหลดหลักฐานการชำระ</SlipTitle>

      <ButtonSection>
        <Row>
          <Col lg={10} md={10} xs={12} position="left">
            <ImgPicker text="เลือกรูปภาพ" onSelect={onSelect} />
          </Col>

          <Col lg={14} md={14} xs={12} position="right">
            ต้อง เป็นไฟล์ .jpg, .jpeg, .gif, .png เท่านั้น
          </Col>
        </Row>
      </ButtonSection>

      {content}
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding-bottom: 60px;
  color: ${(p) => p.theme.color_level.grey.high};
`

const Logo = styled.img`
  width: 50px;
  margin-bottom: 24px;
`

const Title = styled.div`
  width: 100%;
  font-size: 14px;
  display: flex;
  margin: 20px 0px 18px 0px;
`

const No = styled.div`
  width: 28px;
  height: 24px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  background-color: ${(p) => p.theme.color.green};
  margin-right: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Text = styled.div`
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  span {
    font-weight: normal;
    margin-left: 4px;
  }
`

const Copy = styled.div`
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: white;
  background-color: ${(p) => p.theme.color.blue};
  border-radius: 12px;
  cursor: pointer;
  padding: 2px 0px;
`

const QRCode = styled.div`
  text-align: center;
  padding: 35px 0px 30px 0px;
  img {
    width: 200px;
    height: 200px;
  }
`

const SlipTitle = styled.div`
  width: 100%;
  font-size: 18px;
  color: ${(p) => p.theme.color.blue};
  margin-bottom: 25px;
`

const ButtonSection = styled.div`
  margin-bottom: 16px;
`

const Slip = styled.img`
  width: 100%;
  min-height: 240px;
  height: auto;
  border-radius: 4px;
  background-color: ${(p) => p.theme.color.disable};
`

const AccountInput = styled.input`
  display: none;
`

export default Bank
