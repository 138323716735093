import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Row, Col } from 'antd'

import { PrimaryBtn } from 'components/button'
import { Layout, Price } from 'components/display'
import { Line } from 'icon'
import { helper } from 'utils'

const MarketMenu = (props) => {
  const navigate = useNavigate()
  const { visible, doc = {}, status } = props
  const { market_id, price } = doc

  const onClick = () => navigate(`/market/${market_id}/product`)

  if (!visible || !market_id) return <div />

  const unit = helper.getPriceUnit(doc)
  const btn =
    status === 'active' ? (
      <PrimaryBtn text="ดูล็อกว่าง" onClick={onClick} />
    ) : (
      <a href="http://line.me/ti/p/@marget" target="_blank" rel="noreferrer">
        <LineBtn>
          <Line size={0.8} /> สอบถาม
        </LineBtn>
      </a>
    )
  return (
    <PageView>
      <Layout>
        <Row>
          <Col lg={18} md={16} xs={14}>
            <Price price={price} label="ราคาเริ่มต้น" unit={`บาท/${unit}`} />
          </Col>

          <Col lg={6} md={8} xs={10}>
            {btn}
          </Col>
        </Row>
      </Layout>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #ffffff;
  padding: 12px 24px;
  z-index: 5;
`

const LineBtn = styled.div`
  width: 100%;
  border-radius: 8px;
  text-align: center;
  padding: 8px;
  color: white;
  background-color: ${(p) => p.theme.color.green};

  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 6px;
  }
`

export default MarketMenu
