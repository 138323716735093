import { useState, useEffect, useCallback, useRef } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import Loading from 'components/loading'
import { Link } from 'components/link'

import Content from '../Content'
import Carousel from '../Carousel'
import TypeItem from './TypeItem'

const MarketType = (props) => {
  const [loading, setLoading] = useState(false)
  const ref = useRef(true)

  const onLoad = useCallback(async () => {
    try {
      setLoading(true)
      await props.market_type.getMarketType()
    } catch (e) {
      console.log('e', e.message)
    }
    if (ref.current) {
      setLoading(false)
    }
  }, [props.market_type])

  useEffect(() => {
    onLoad()
    return () => {
      ref.current = false
    }
  }, [onLoad])

  const market_type = props.market_type.toJS()
  const market_type_list = market_type.market_type_list || []
  const content = market_type_list.map((it, i) => {
    const link = `/market-type/${it.market_type_id}`
    return (
      <Card key={i}>
        <Link to={link}>
          <TypeItem item={it} />
        </Link>
      </Card>
    )
  })

  return (
    <Content>
      <Loading loading={loading}>
        <Carousel>{content}</Carousel>
      </Loading>
    </Content>
  )
}

const Card = styled.div`
  padding-right: 42px;
`

export default inject('market_type')(observer(MarketType))
