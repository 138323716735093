import { format } from './format'
import { timer } from './timer'
import { market } from './market'

export class Product {
  toProductType(doc = {}) {
    switch (doc.product_type) {
      case 'type_food':
      case 'food':
        return 'อาหาร'
      case 'type_drink':
      case 'drink':
        return 'เครื่องดื่ม'
      case 'type_clothes':
      case 'clothes':
        return 'เสื้อผ้า'
      case 'type_things':
      case 'things':
        return 'ของใช้'
      default:
        return 'บริการ'
    }
  }

  toShopType(doc = {}) {
    const list = [
      { name: 'เตาไฟฟ้า', value: 'electric_stove_type' },
      { name: 'เตาแก๊ส', value: 'gas_stove_type' },
      { name: 'อาหารหนัก (ข้าว, เส้น)', value: 'food_type' },
      { name: 'น้ำชงทุกชนิด', value: 'make_drink_type' },
      { name: 'น้ำเปล่า น้ำอัดลม', value: 'drink_type' },
      { name: 'มูลนิธิ', value: 'foundation_type' },
      { name: 'บูธบัตรเครดิต / เน็ตบ้าน', value: 'direct_sale_type' },
    ]

    const content_list = list
      .filter((it) => doc[it.value] === 1)
      .map((it) => it.name)

    const text = content_list.join(', ')
    return text
  }

  getStatus({ status, market_note, due_payment_at }) {
    let text = ''
    let note = ''
    let comment = ''
    let color = ''
    const date = format.toThaiDatetime(due_payment_at)
    switch (status) {
      case 'create':
        text = 'รอการยืนยัน'
        note = 'กรุณารอการยืนยันการจองจากระบบ ก่อนชำระเงิน'
        color = '#F5BF57'
        break
      case 'payment':
        text = 'รอชำระเงิน'
        note = `กรุณาชำระเงินภายใน ${date}`
        comment = market_note
        color = '#D78F00'
        break
      case 'offer':
        text = 'เสนอใหม่'
        note = market_note
        color = '#577AF5'
        break
      case 'completed':
        text = 'การจองสำเร็จแล้ว'
        note = market_note
        color = '#00C767'
        break
      default:
        text = 'การจองถูกยกเลิก'
        note = market_note
        color = '#ED3034'
    }

    return { text, note, comment, color }
  }

  getDate(start_at, finish_at) {
    const start = format.toThaiDate(start_at)
    const finish = format.toThaiDate(finish_at)
    const date = start === finish ? start : `${start} - ${finish}`
    return date
  }

  matchProductType(product_type, product = {}) {
    if (product_type === '') {
      return true
    }

    const { type_clothes, type_drink, type_food, type_services, type_things } =
      product

    switch (product_type) {
      case 'food':
        return type_food === 1
      case 'drink':
        return type_drink === 1
      case 'clothes':
        return type_clothes === 1
      case 'things':
        return type_things === 1
      case 'services':
        return type_services === 1
      default:
        return false
    }
  }

  isBetween(val, start, finish) {
    return val.isSameOrAfter(start) && val.isSameOrBefore(finish)
  }

  getPromotion(product_id, start_at, finish_at, promotion_list) {
    if (!start_at || !finish_at) {
      return
    }

    for (const item of promotion_list) {
      const inStart = this.isBetween(start_at, item.start_at, item.finish_at)
      const inFinish = this.isBetween(finish_at, item.start_at, item.finish_at)

      let promotion
      if (inStart || inFinish) {
        promotion = item
      } else {
        const inProStart = this.isBetween(item.start_at, start_at, finish_at)
        const inProFinish = this.isBetween(item.finish_at, start_at, finish_at)

        if (inProStart || inProFinish) {
          promotion = item
        }
      }

      if (promotion) {
        const { is_all } = promotion

        if (is_all) {
          return promotion
        }

        const product_list = promotion.product_list || []
        const index = product_list.findIndex(
          (it) => it.product_id === product_id
        )
        if (index !== -1) {
          return promotion
        }
      }
    }
  }

  toPrice(price, discount) {
    const p = price - discount
    const val = p < 0 ? 0 : p
    return val
  }

  isProductInPromotion(product_id, promotion) {
    const product_list = promotion.product_list || []
    const index = product_list.findIndex((it) => it.product_id === product_id)

    return index !== -1
  }

  getPromotionPrice(date, price, product, promotion) {
    const isValid = this.isBetween(
      date,
      promotion.start_at,
      promotion.finish_at
    )

    const { is_all } = promotion
    const isPromotion = isValid
      ? is_all
        ? true
        : this.isProductInPromotion(product.product_id, promotion)
      : false

    const promotion_price = isPromotion
      ? this.toPrice(price, promotion.value)
      : 0

    return { isPromotion, promotion_price }
  }

  calcPrice(product, start_at, finish_at, price, days_list, promotion) {
    const { booking_type } = product
    const price_list = []
    const days = finish_at.diff(start_at, 'days') + 1
    if (booking_type !== 'daily' || days === 1) {
      const total_price = +price
      const promotion_price = promotion
        ? this.toPrice(+price, promotion.value)
        : 0
      return {
        total_price,
        promotion_price,
        price_list,
        days,
      }
    }

    let counter = 0
    let total_price = 0
    let total_promotion = 0
    let date = timer.get(start_at)

    for (let i = 0; i < days; i++) {
      const num = date.days()
      const isOpen = days_list.includes(num)
      if (isOpen) {
        const date_price = market.getDailyPrice(date, product)
        total_price += date_price

        if (promotion) {
          const { isPromotion, promotion_price } = this.getPromotionPrice(
            date,
            date_price,
            product,
            promotion
          )

          total_promotion += isPromotion ? promotion_price : total_price
          price_list.push({ date, price: date_price, promotion_price })
        } else {
          price_list.push({ date, price: date_price })
        }

        counter++
      }

      date.add(1, 'days')
    }

    return {
      total_price,
      promotion_price: total_promotion,
      price_list,
      days: counter,
    }
  }

  getTextPromotion(promotion) {
    const val = format.toDigi(promotion.value)
    const date = this.getDate(promotion.start_at, promotion.finish_at)

    return `ส่วนลด ${val} บาท วันที่ ${date}`
  }
}

export const product = new Product()
export default product
