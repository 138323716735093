import { useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { FindInput } from 'components/input'

const FindMarket = (props) => {
  const navigate = useNavigate()

  const { init } = props
  const [keyword, setKeyword] = useState(init || '')

  const onPressEnter = () => {
    if (keyword === '') return

    navigate(`/market-search?keyword=${keyword}`)
  }

  return (
    <PageView>
      <FindInput
        placeholder="ค้นหาตลาด"
        value={keyword}
        onChange={(val) => setKeyword(val)}
        onPressEnter={onPressEnter}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

export default FindMarket
