import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import Loading from 'components/loading'
import Menu from 'components/menu'
import UserMenu from 'menu/UserMenu'
import { Meta, Layout } from 'components/display'
import { PrimaryBtn } from 'components/button'

import Header from './Header'

const Profile = (props) => {
  const navigate = useNavigate()

  const onLogout = async () => {
    await props.member.logout()
    navigate('/')
  }

  return (
    <PageView>
      <Meta title="บัญชีของฉัน" description="เมนู บัญชีของฉัน" />
      <Layout>
        <Loading>
          <Header />

          <Menu title="บัญชีของฉัน" menu={account_menu} />

          <Menu title="ข้อมูลทั่วไป" menu={general_menu} />

          <Button>
            <PrimaryBtn text="ออกจากระบบ" onClick={onLogout} />
          </Button>
        </Loading>
      </Layout>
      <UserMenu selected="account" />
    </PageView>
  )
}

const account_menu = [
  { label: 'ข้อมูลส่วนตัว', link: '/account/personal' },
  { label: 'ข้อมูลสินค้า', link: '/account/shop' },
  { label: 'รายการจองของฉัน', link: '/account/booking' },
]

const general_menu = [
  { label: 'เกี่ยวกับเรา', link: '/about-us?source=/account' },
  { label: 'ติดต่อเรา', link: '/contact-us?source=/account' },
  { label: 'เงื่อนไขการให้บริการ', link: '/account/consent/terms' },
  { label: 'นโยบายความเป็นส่วนตัว', link: '/account/consent/privacy' },
  { label: 'Cookie policy', link: '/account/consent/cookie' },
]

const PageView = styled.div`
  width: 100%;
  padding: 30px 24px;
  min-height: 80vh;
  padding-bottom: 120px;
`

const Button = styled.div`
  width: 214px;
  margin: 0 auto;
`

export default inject('member')(observer(Profile))
