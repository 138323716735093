import { useState } from 'react'
import { Carousel } from 'antd'
import styled from 'styled-components'

import { Marget } from 'icon'
import { BookingType } from 'components/market'

const ImageList = (props) => {
  const [index, setIndex] = useState(0)
  let carousel
  const { doc = {} } = props
  const { img_list = [] } = doc

  const onSelect = (i) => {
    if (carousel) {
      carousel.goTo(i)
    }
  }

  const onChange = (i) => {
    setIndex(i)
  }

  const btn_list = []
  const len = img_list.length
  for (let i = 0; i < len; i++) {
    const position = i === 0 ? 24 : 8
    const marginLeft = `${position}px`
    const btn =
      i === index ? (
        <ActiveBtn key={i} style={{ marginLeft }} />
      ) : (
        <Btn key={i} style={{ marginLeft }} onClick={() => onSelect(i)} />
      )

    btn_list.push(btn)
  }

  const content = img_list.map((it, i) => {
    return <Img src={it} key={i} />
  })

  let img
  if (img_list.length === 0) {
    img = (
      <Coming>
        <Marget size={0.8} />
      </Coming>
    )
  } else {
    img = (
      <>
        <Carousel
          ref={(ref) => {
            carousel = ref
          }}
          dots={false}
          afterChange={onChange}
        >
          {content}
        </Carousel>
        {btn_list}
        <Total>
          {index + 1}/{img_list.length}
        </Total>
      </>
    )
  }

  return (
    <PageView>
      <BookingType item={doc} />
      {img}
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  display: block;
  position: relative;

  .rec-slider-container {
    margin: 0px;
  }
`

const Btn = styled.span`
  padding: 5px;
  border-radius: 12px;
  background-color: ${(p) => p.theme.color.grey};
  position: relative;
  bottom: 35px;
  float: left;
  cursor: pointer;
`

const ActiveBtn = styled(Btn)`
  background-color: ${(p) => p.theme.color.green};
`

const Total = styled.span`
  font-size: 14px;
  padding: 2px 22px;
  border-radius: 12px;
  color: white;
  background-color: ${(p) => p.theme.color.blue};
  position: relative;
  bottom: 45px;
  float: right;
  margin-right: 24px;
`

const Img = styled.img`
  width: 100%;
  height: 375px;
  object-fit: cover;
`

const Coming = styled.div`
  width: 100%;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default ImageList
