import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import Loading from 'components/loading'
import { ErrorDialog } from 'dialog'
import { BackHeader, Content, Title } from 'components/display'
import { AccountInfo } from 'components/account'
import Button from 'pages/account/Button'
import { validator, message } from 'utils'

import rules from './rules'

const Profile = (props) => {
  const [loading, setLoading] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [validate, setValidate] = useState({})
  const [err, setErr] = useState('')

  const navigate = useNavigate()

  const { user } = props.account.toJS()
  const { province_list } = props.province.toJS()

  const onInit = useCallback(async () => {
    try {
      setLoading(true)
      await Promise.all([
        props.account.getUserProfile(),
        props.province.getProvinceList(),
      ])
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }, [props.account, props.province])

  useEffect(() => {
    onInit()
  }, [onInit])

  const onCloseError = () => setErr('')
  const onBack = () => navigate('/account')
  const onSave = async () => {
    try {
      const checker = validator.process(rules, user)
      if (checker.invalid) {
        setValidate(checker.errors)
        return
      }

      setProcessing(true)

      await props.account.updateProfile(user)
      message.success()
    } catch (e) {
      setErr(e.message)
    }

    setProcessing(false)
  }

  const onChange = (val, name) => {
    user[name] = val
    delete validate[name]

    props.account.setProfile(user)
    setValidate({ ...validate })
  }

  return (
    <PageView>
      <BackHeader onClick={onBack} isShort={true} />
      <Content isHeader={true}>
        <Loading loading={loading}>
          <BodyView>
            <Title text="ข้อมูลส่วนตัว" />

            <AccountInfo
              user={user}
              validate={validate}
              province_list={province_list}
              onChange={onChange}
            />

            <Button loading={processing} text="บันทึก" onClick={onSave} />
          </BodyView>
        </Loading>
      </Content>
      <ErrorDialog error={err} onClose={onCloseError} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  min-height: 100vh;
`

const BodyView = styled.div`
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
  padding-bottom: 100px;
`

export default inject('account', 'province')(observer(Profile))
