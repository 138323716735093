import styled from 'styled-components'

import { SelectList, Input } from 'components/input'

const Information = (props) => {
  const {
    doc = {},
    validate = {},
    onChange = () => {},
    product_type_menu = [],
  } = props

  const onCh = (val, name) => {
    doc[name] = val
    delete validate[name]
    onChange(doc, validate)
  }

  const menu =
    product_type_menu.length > 0 ? product_type_menu : ProductTypeMenu
  return (
    <PageView>
      <Line>
        <SelectList
          placeholder="หมวดหมู่สินค้า"
          menu={menu}
          name="product_type"
          value={doc.product_type}
          onChange={onCh}
          invalid={validate.product_type}
        />
      </Line>
      <Line>
        <Input
          label="ชื่อสินค้า"
          name="name"
          value={doc.name}
          onChange={onCh}
          invalid={validate.name}
        />
      </Line>

      <Input
        label="ชื่อร้านค้า (ถ้ามี)"
        name="shop_name"
        value={doc.shop_name}
        onChange={onCh}
      />
    </PageView>
  )
}

const ProductTypeMenu = [
  { name: 'อาหาร', value: 'food' },
  { name: 'เครื่องดื่ม', value: 'drink' },
  { name: 'เสื้อผ้า', value: 'clothes' },
  { name: 'ของใช้', value: 'things' },
  { name: 'บริการ', value: 'services' },
]

const PageView = styled.div`
  width: 100%;
  padding-bottom: 16px;
`

const Line = styled.div`
  width: 100%;
  padding-bottom: 16px;
`

export default Information
