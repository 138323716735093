import React from 'react'
/* eslint-disable */

const Logo = (props) => {
  const { size = 1, color = '#494949' } = props

  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 9.75C11.2583 9.75 10.5333 9.53007 9.91661 9.11801C9.29993 8.70596 8.81928 8.12029 8.53545 7.43506C8.25162 6.74984 8.17736 5.99584 8.32206 5.26841C8.46675 4.54098 8.8239 3.8728 9.34835 3.34835C9.8728 2.8239 10.541 2.46675 11.2684 2.32206C11.9958 2.17736 12.7498 2.25162 13.4351 2.53545C14.1203 2.81928 14.7059 3.29993 15.118 3.91661C15.5301 4.5333 15.75 5.25832 15.75 6C15.7488 6.9942 15.3533 7.94733 14.6503 8.65034C13.9473 9.35334 12.9942 9.74881 12 9.75ZM12 3.75C11.555 3.75 11.12 3.88196 10.75 4.1292C10.38 4.37643 10.0916 4.72783 9.92127 5.13896C9.75097 5.5501 9.70642 6.0025 9.79323 6.43896C9.88005 6.87541 10.0943 7.27632 10.409 7.59099C10.7237 7.90566 11.1246 8.11995 11.561 8.20677C11.9975 8.29359 12.4499 8.24903 12.861 8.07873C13.2722 7.90843 13.6236 7.62005 13.8708 7.25004C14.118 6.88002 14.25 6.44501 14.25 6C14.25 5.40326 14.0129 4.83097 13.591 4.40901C13.169 3.98705 12.5967 3.75 12 3.75Z"
        fill="#494949"
      />
      <path
        d="M17.25 18H6.75002C6.63705 18 6.52553 17.9745 6.4238 17.9254C6.32207 17.8763 6.23275 17.8048 6.16252 17.7163C6.09228 17.6278 6.04294 17.5246 6.01818 17.4144C5.99341 17.3042 5.99387 17.1898 6.01952 17.0798L7.14902 12.2385C7.26299 11.7431 7.54199 11.3012 7.94021 10.9853C8.33843 10.6694 8.83221 10.4983 9.34052 10.5H14.6595C15.1678 10.4983 15.6616 10.6694 16.0598 10.9853C16.458 11.3012 16.737 11.7431 16.851 12.2385L17.9805 17.0798C18.0062 17.1898 18.0066 17.3042 17.9819 17.4144C17.9571 17.5246 17.9078 17.6278 17.8375 17.7163C17.7673 17.8048 17.678 17.8763 17.5762 17.9254C17.4745 17.9745 17.363 18 17.25 18ZM7.69502 16.5H16.305L15.3908 12.579C15.3522 12.4141 15.2589 12.2671 15.1261 12.162C14.9933 12.0569 14.8289 11.9998 14.6595 12H9.34052C9.17116 11.9998 9.00672 12.0569 8.87394 12.162C8.74117 12.2671 8.64788 12.4141 8.60927 12.579L7.69502 16.5Z"
        fill="#494949"
      />
      <path
        d="M12 21.7501C8.50575 21.7501 5.2815 20.8501 3.58425 19.3951C3.1893 19.0999 2.86476 18.7208 2.63404 18.285C2.40331 17.8492 2.27215 17.3677 2.25 16.8751C2.25 15.6001 3.15 14.4159 4.791 13.5369C4.96634 13.4429 5.17184 13.4224 5.36228 13.4799C5.55272 13.5374 5.71251 13.6683 5.8065 13.8436C5.90049 14.019 5.92097 14.2245 5.86344 14.4149C5.80592 14.6053 5.67509 14.7651 5.49975 14.8591C4.38825 15.4546 3.75 16.1896 3.75 16.8751C3.78088 17.1482 3.86857 17.4118 4.00743 17.6489C4.14628 17.8861 4.33323 18.0915 4.55625 18.2521C5.97525 19.4671 8.895 20.2501 12 20.2501C15.105 20.2501 18.0247 19.4671 19.44 18.2551C19.6639 18.0944 19.8517 17.8886 19.9912 17.6509C20.1307 17.4132 20.2188 17.1489 20.25 16.8751C20.25 16.1896 19.6117 15.4546 18.5002 14.8591C18.3249 14.7651 18.1941 14.6053 18.1366 14.4149C18.079 14.2245 18.0995 14.019 18.1935 13.8436C18.2875 13.6683 18.4473 13.5374 18.6377 13.4799C18.8282 13.4224 19.0337 13.4429 19.209 13.5369C20.85 14.4159 21.75 15.6001 21.75 16.8751C21.7276 17.3685 21.596 17.8507 21.3646 18.287C21.1332 18.7233 20.8079 19.1028 20.412 19.3981C18.7185 20.8501 15.4943 21.7501 12 21.7501Z"
        fill={color}
      />
    </svg>
  )
}
// eslint-enable
export default Logo
