import * as admin from 'firebase/app'
import { initializeAnalytics, logEvent } from 'firebase/analytics'
import { getAuth, signInWithCustomToken } from 'firebase/auth'

import { config } from 'config'

const app = admin.initializeApp(config.firebase, 'app')

const analytics = initializeAnalytics(app)

const auth = () => {
  return getAuth(app)
}

const onAuthStateChanged = () => {
  return new Promise((resolve) => {
    auth().onAuthStateChanged((user) => {
      resolve(user)
    })
  })
}

const signInWithToken = (token) => {
  return new Promise((resolve) => {
    signInWithCustomToken(auth(), token)
      .then((credential) => {
        const { user } = credential
        resolve({ user })
      })
      .catch((error) => {
        resolve({ error })
      })
  })
}

const logout = async () => {
  await auth().signOut()
}

const firebase = {
  auth,
  onAuthStateChanged,
  signInWithToken,
  logout,
}

const click = (name, options = {}) => {
  logEvent(analytics, name, options)
}

const exception = (options = {}) => {
  analytics.logEvent('exception', { ...options, fatal: true })
}

const ga = {
  click,
  exception,
}

export { firebase, ga }
