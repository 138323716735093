import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http, storage, timer } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  consent_id: null,
  ver: '',
  status: 'active',
  short_content: '',
  content: '',
}

let state
export class Consent extends BaseStore {
  constructor() {
    super()
    this.observable({
      privacy: cloneDeep(original),
      terms: cloneDeep(original),
      cookie: cloneDeep(original),
      my_privacy: cloneDeep(original),
      my_terms: cloneDeep(original),
      current: {
        cookie: {},
      },
    })
    state = this
  }

  async getPrivacy() {
    const url = `${config.api}/v1/public/consent/privacy`
    const resp = await http.get(url)

    const data = resp.body || {}

    const tmp = data.consent_id ? data : cloneDeep(original)
    runInAction(() => {
      state.privacy = tmp
    })
  }

  async savePrivacy(json = {}) {
    const url = `${config.api}/v1/admin/consent/privacy`
    await http.put(url, { json })
  }

  async getTerms() {
    const url = `${config.api}/v1/public/consent/terms`
    const resp = await http.get(url)

    const data = resp.body || {}
    const tmp = data.consent_id ? data : cloneDeep(original)

    runInAction(() => {
      state.terms = tmp
    })
  }

  async saveTerms(json = {}) {
    const url = `${config.api}/v1/admin/consent/terms`
    await http.put(url, { json })
  }

  async getCookie() {
    const url = `${config.api}/v1/public/consent/cookie`
    const resp = await http.get(url)

    const data = resp.body || {}
    const tmp = data.consent_id ? data : cloneDeep(original)

    runInAction(() => {
      state.cookie = tmp
    })
  }

  async loadCookie() {
    const data = storage.load('cookie') || {}
    const { consent_id, status } = data

    let updated_at
    if (status === 'close' && data.updated_at) {
      const now = timer.get()
      updated_at = timer.get(data.updated_at)

      const days = now.diff(updated_at, 'days')
      if (days >= 1) {
        return
      }
    }

    runInAction(() => {
      state.current.cookie = { consent_id, status }
    })
  }

  async acceptCookie(consent_id = 0, status) {
    const updated_at = timer.get().toISOString()
    const data = { consent_id, status, updated_at }
    await storage.save('cookie', data)

    runInAction(() => {
      state.current.cookie = data
    })
  }

  async getMyPrivacy() {
    const doc = this.toJS().my_privacy || {}
    if (doc.consent_id) {
      return
    }

    const url = `${config.api}/v1/user/consent/privacy`
    const resp = await http.get(url)

    const data = resp.body || {}

    const tmp = data.consent_id ? data : cloneDeep(original)
    runInAction(() => {
      state.my_privacy = tmp
    })
  }

  async getMyTerms() {
    const doc = this.toJS().my_terms || {}
    if (doc.consent_id) {
      return
    }

    const url = `${config.api}/v1/user/consent/terms`
    const resp = await http.get(url)

    const data = resp.body || {}
    const tmp = data.consent_id ? data : cloneDeep(original)

    runInAction(() => {
      state.my_terms = tmp
    })
  }
}

export default new Consent()
