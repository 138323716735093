import styled from 'styled-components'

import { LinkToAll } from 'components/link'

const Title = (props) => {
  const { title, to } = props

  return (
    <Label>
      {title}
      <LinkToAll to={to} />
    </Label>
  )
}

const Label = styled.div`
  width: 100%;
  padding: 0px 24px;
  font-size: 24px;
  color: ${(p) => p.theme.color.grey};

  span {
    float: right;
    margin-top: 8px;
  }
`

export default Title
