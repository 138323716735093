import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import Footer from 'footer'
import { Meta } from 'components/display'
import MarketList from 'pages/market/MarketList'

const SearchMarket = (props) => {
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const { keyword } = queryString.parse(location.search)

  const onLoad = useCallback(
    async (k) => {
      try {
        setLoading(true)
        await props.market.getMarketList({ keyword: k })
      } catch (e) {
        console.log('e', e.message)
      }
      setLoading(false)
    },
    [props.market]
  )

  useEffect(() => {
    onLoad(keyword)
  }, [onLoad, keyword])

  const { market_list = [] } = props.market.toJS()

  const source = `/market-search?keyword=${keyword}`
  return (
    <PageView>
      <Meta
        loading={loading}
        title={`ค้นหาตลาด - ${keyword}`}
        description={keyword}
      />
      <MarketList
        loading={loading}
        source={source}
        keyword={keyword}
        market_list={market_list}
      />
      <Footer />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding-bottom: 40px;
`

export default inject('market')(observer(SearchMarket))
