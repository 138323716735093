import React from 'react'
/* eslint-disable */

const Logo = (props) => {
  const { size = 1, color = '#305FED' } = props

  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20.9972 11.2736H20.9058V5.72608C20.9058 5.01897 20.6305 4.35424 20.1305 3.85426L17.0516 0.775362C16.5516 0.275345 15.8868 0 15.1798 0H5.74145C4.28176 0 3.09427 1.18749 3.09427 2.64718V11.2736H3.00286C2.02354 11.2736 1.22681 12.0704 1.22681 13.0497V18.9494C1.22681 19.9287 2.02354 20.7254 3.00286 20.7254H3.09422V21.3528C3.09422 22.8124 4.28171 23.9999 5.74141 23.9999H18.2587C19.7183 23.9999 20.9058 22.8124 20.9058 21.3528V20.7254H20.9972C21.9765 20.7254 22.7733 19.9287 22.7733 18.9494V13.0498C22.7733 12.0704 21.9765 11.2736 20.9972 11.2736ZM16.6412 2.35323L18.6932 4.40528H16.7984C16.7117 4.40528 16.6412 4.33474 16.6412 4.24811V2.35323ZM4.50015 2.64718C4.50015 1.96271 5.05693 1.40593 5.74141 1.40593H15.1797C15.1983 1.40593 15.2168 1.40649 15.2352 1.40729V4.24806C15.2352 5.11 15.9365 5.81116 16.7984 5.81116H19.4999V11.2736H4.50015V2.64718ZM19.4999 21.3529C19.4999 22.0373 18.9431 22.5941 18.2587 22.5941H5.74141C5.05698 22.5941 4.50015 22.0373 4.50015 21.3529V20.7256H19.4998V21.3529H19.4999ZM21.3674 18.9495C21.3673 19.1536 21.2013 19.3196 20.9972 19.3196H20.2033C20.2032 19.3196 20.2031 19.3196 20.2029 19.3196C20.2027 19.3196 20.2026 19.3196 20.2024 19.3196H3.79721H3.79674H3.00286H3.00281C2.79872 19.3196 2.63269 19.1536 2.63269 18.9495V13.0498C2.63269 12.8457 2.79872 12.6796 3.00281 12.6796H20.9972C21.2013 12.6796 21.3673 12.8457 21.3673 13.0498V18.9495H21.3674Z" fill={color}/>
      <path d="M9.52196 13.7784C9.21141 13.5111 8.79834 13.3774 8.28314 13.3774H6.78787C6.66262 13.3774 6.56146 13.4112 6.48444 13.4786C6.40733 13.5461 6.3689 13.6256 6.3689 13.717V18.3111C6.3689 18.422 6.42421 18.5074 6.53502 18.5676C6.64569 18.6279 6.77816 18.6579 6.93234 18.6579C7.08637 18.6579 7.21879 18.6279 7.3296 18.5676C7.44032 18.5074 7.49573 18.422 7.49573 18.3111V16.7509H8.21086C8.74537 16.7509 9.17517 16.6149 9.50025 16.3428C9.82533 16.0708 9.98785 15.6482 9.98785 15.0751V15.0389C9.9879 14.4659 9.83255 14.0456 9.52196 13.7784ZM8.86102 15.1617C8.86102 15.6433 8.64426 15.884 8.21086 15.884H7.49578V14.3599H8.2109C8.64431 14.3599 8.86106 14.6008 8.86106 15.0822V15.1617H8.86102Z" fill={color}/>
      <path d="M13.5418 13.7892C13.2191 13.5147 12.7881 13.3774 12.2488 13.3774H10.9702C10.8257 13.3774 10.7077 13.4112 10.6163 13.4786C10.5247 13.5461 10.479 13.6255 10.479 13.7169V18.3183C10.479 18.4099 10.5248 18.4893 10.6163 18.5567C10.7077 18.6242 10.8257 18.6578 10.9702 18.6578H12.2488C12.788 18.6578 13.2191 18.5206 13.5418 18.2461C13.8643 17.9716 14.0258 17.5407 14.0258 16.9531V15.0822C14.0257 14.4948 13.8643 14.0637 13.5418 13.7892ZM12.8988 16.9531C12.8988 17.4347 12.6821 17.6755 12.2487 17.6755H11.6058V14.3599H12.2487C12.6821 14.3599 12.8988 14.6008 12.8988 15.0823V16.9531Z" fill={color}/>
      <path d="M17.9914 13.522C17.9336 13.4257 17.8493 13.3776 17.7386 13.3776H15.232C15.0971 13.3776 14.9815 13.4077 14.8853 13.4679C14.7889 13.5282 14.7408 13.6136 14.7408 13.7243V18.3113C14.7408 18.4221 14.7962 18.5075 14.907 18.5677C15.0176 18.628 15.1501 18.6581 15.3043 18.6581C15.4583 18.6581 15.5907 18.628 15.7016 18.5677C15.8123 18.5075 15.8677 18.4221 15.8677 18.3113V16.4476H16.9584C17.0691 16.4476 17.1535 16.4042 17.2113 16.3176C17.2691 16.2309 17.298 16.1322 17.298 16.0214C17.298 15.9011 17.2678 15.7974 17.2077 15.7107C17.1474 15.624 17.0643 15.5807 16.9584 15.5807H15.8677V14.36H17.7386C17.8444 14.36 17.9275 14.3119 17.9877 14.2155C18.0479 14.1192 18.0781 14.0013 18.0781 13.8616C18.0781 13.7315 18.0492 13.6184 17.9914 13.522Z" fill={color}/>
    </svg>

  )
}
/* eslint-enable */
export default Logo
