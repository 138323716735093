import styled from 'styled-components'

const QrCodeBot = (props) => {
  return (
    <PageView>
      <Text>เพิ่ม Line เพื่อรับการแจ้งเตือนข้อมูลการจอง</Text>
      <img
        src="https://hlo-drive.sgp1.digitaloceanspaces.com/marget/web/qccode-bot.png"
        alt="QR Code"
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  text-align: center;

  img {
    width: 100%;
    max-width: 214px;
  }
`

const Text = styled.div`
  width: 100%;
  text-align: center;
`

export default QrCodeBot
