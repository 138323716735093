import styled from 'styled-components'
import { Row, Col } from 'antd'
import { useNavigate } from 'react-router-dom'

import FindMarket from 'components/FindMarket'
import { Content, BackHeader, NotFound } from 'components/display'
import { FullMarket } from 'components/market'
import Loading from 'components/loading'

import MarketHeader from './MarketHeader'

const MarketList = (props) => {
  const navigate = useNavigate()
  const {
    loading,
    title,
    source,
    keyword,
    market_list = [],
    placeholder,
    selected,
    clear,
    select_list = [],
    onChange,
  } = props

  const onBack = () => navigate('/')

  const rows = market_list.map((item, i) => {
    return (
      <Col key={i} lg={6} md={8} xs={24}>
        <Card>
          <FullMarket item={item} source={source} />
        </Card>
      </Col>
    )
  })

  const content =
    loading || market_list.length > 0 ? (
      <Row>{rows}</Row>
    ) : (
      <NotFound text="ไม่พบตลาดที่คุณค้นหา" />
    )

  return (
    <PageView>
      <BackHeader onClick={onBack}>
        <FindMarket init={keyword} />
      </BackHeader>

      <Content>
        <MarketHeader
          title={title}
          placeholder={placeholder}
          selected={selected}
          clear={clear}
          select_list={select_list}
          onChange={onChange}
        />
        <Loading loading={loading}>
          <ContentView>{content}</ContentView>
        </Loading>
      </Content>
    </PageView>
  )
}

const PageView = styled.div``

const ContentView = styled.div`
  margin-top: 24px;
`

const Card = styled.div`
  padding-right: 16px;
  margin-bottom: 16px;

  ${(p) => p.theme.media.mobile} {
    padding-right: 0px;
  }
`

export default MarketList
