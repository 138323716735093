import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import Loading from 'components/loading'
import { ErrorDialog } from 'dialog'
import { BackHeader, Content, Title } from 'components/display'

import ShopEditor from '../editor'
import Card from './Card'
import Button from './Button'

const ShopList = (props) => {
  const [loading, setLoading] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [err, setErr] = useState('')
  const [doc, setDoc] = useState(undefined)
  const navigate = useNavigate()

  const { shop_list = [] } = props.shop.toJS()
  const { order = {} } = props.order.toJS()

  const onLoad = useCallback(async () => {
    try {
      setLoading(true)
      await props.shop.getMyShopList()
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }, [props.shop])

  useEffect(() => {
    onLoad()
  }, [onLoad])

  const onCloseError = () => setErr('')
  const onBack = () => navigate('/account')
  const onClose = () => setDoc(undefined)
  const onAdd = () => setDoc({})

  const onChange = (val, valid) => {
    setDoc({ ...val })
  }

  const onSave = async () => {
    try {
      setProcessing(true)
      await props.shop.createShop(doc)
      onClose()
    } catch (e) {
      setErr(e.message)
    }
    setProcessing(false)
  }

  const content = shop_list.map((it, i) => {
    return <Card key={i} doc={it} />
  })

  const list = order.list || []
  const hasOrder = list.length > 0
  const onGoto = hasOrder
    ? () => navigate('/account/order?source=/account/shop')
    : undefined
  return (
    <PageView>
      <BackHeader onClick={onBack} isShort={true} />
      <Content isHeader={true}>
        <Loading loading={loading}>
          <ContentView>
            <Title text="ข้อมูลร้านค้า" />
            {content}
            <Button text="เพิ่มร้านค้า" onClick={onAdd} onGoto={onGoto} />
          </ContentView>
        </Loading>
      </Content>
      <ShopEditor
        title="เพิ่มร้านค้า"
        visible={doc !== undefined}
        loading={processing}
        doc={doc}
        onChange={onChange}
        onClose={onClose}
        onSave={onSave}
      />
      <ErrorDialog error={err} onClose={onCloseError} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  max-width: ${(p) => p.theme.max};
  min-height: 85vh;
  margin: 0 auto;
  min-height: 90vh;
`

const ContentView = styled.div`
  width: 100%;
`

export default inject('shop', 'order')(observer(ShopList))
