import styled from 'styled-components'

import ShopInfo from 'pages/account/shop/editor/ShopInfo'
import ShopType from 'pages/account/shop/editor/ShopType'
import ShopImage from 'pages/account/shop/editor/ShopImage'
import { product } from 'utils'

const ShopInformation = (props) => {
  const { order = {}, doc = {}, validate = {}, onChange = () => {} } = props

  const list = order.list || []
  const product_type_menu = ProductTypeMenu.map((it) => {
    const i = list.findIndex((pro) => {
      const valid = product.matchProductType(it.value, pro)
      return !valid
    })

    it.disabled = i !== -1
    return it
  })

  return (
    <PageView>
      <ShopInfo
        doc={doc}
        validate={validate}
        onChange={onChange}
        product_type_menu={product_type_menu}
      />
      <ShopType doc={doc} validate={validate} onChange={onChange} />
      <ShopImage doc={doc} validate={validate} onChange={onChange} />
    </PageView>
  )
}

const ProductTypeMenu = [
  { name: 'อาหาร', value: 'food' },
  { name: 'เครื่องดื่ม', value: 'drink' },
  { name: 'เสื้อผ้า', value: 'clothes' },
  { name: 'ของใช้', value: 'things' },
  { name: 'บริการ', value: 'services' },
]

const PageView = styled.div`
  width: 100%;
  margin-top: 16px;
`

export default ShopInformation
