import styled from 'styled-components'

import { Dot } from 'components/display'
import { format, product } from 'utils'

import Title from './Title'

const Card = (props) => {
  const { order = {} } = props
  const getText = (label, text) => {
    return (
      <Text>
        {label}
        <span>{text}</span>
      </Text>
    )
  }

  const { name, mobile } = order
  const shopInfo = order.shop_info || {}
  const productType = product.toProductType(shopInfo)
  const shopName = shopInfo.shop_name === '' ? 'ไม่ระบุ' : shopInfo.shop_name
  return (
    <PageView>
      <Title text="ข้อมูลผู้เช่า" />
      {getText('ผู้เช่า:', name)}
      {getText('เบอร์ติดต่อ:', format.toMobile(mobile))}
      <Dot />
      <Space />
      <Title text="ข้อมูลร้านค้า" />
      {getText('สินค้า:', shopInfo.name)}
      {getText('หมวดหมู่:', productType)}
      {getText('ร้าน:', shopName)}
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 16px 0px 10px 0px;
`

const Text = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 6px;
  span {
    float: right;
    font-weight: normal;
  }
`

const Space = styled.div`
  margin-bottom: 10px;
`

export default Card
