import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import Footer from 'footer'
import { helper } from 'utils'
import { Meta } from 'components/display'
import MarketList from 'pages/market/MarketList'

const MarketAreaList = (props) => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const { market_area_id = '' } = useParams()

  const onLoad = useCallback(
    async (id) => {
      try {
        setLoading(true)
        await Promise.all([
          props.market.getMarketArea(id),
          props.market_area.getMarketArea(),
        ])
      } catch (e) {
        console.log('e', e.message)
      }
      setLoading(false)
    },
    [props.market, props.market_area]
  )

  useEffect(() => {
    onLoad(market_area_id)
  }, [onLoad, market_area_id])

  useEffect(() => {
    helper.gotoCode(location.search)
  }, [])

  const onChange = (id) => {
    const val = id || ''
    const link = val !== '' ? `/market-area/${id}` : '/market-area'
    navigate(link)
  }

  const { market_list = [] } = props.market.toJS()
  const { market_area_list = [], select_list = [] } = props.market_area.toJS()

  const getMarketAreaName = () => {
    const id = +market_area_id
    const market_area = id
      ? market_area_list.find((it) => it.market_area_id === id)
      : null
    const name = market_area ? market_area.name : 'ทุกพื้นที่'

    return name
  }

  const source = market_area_id
    ? `/market-area/${market_area_id}`
    : '/market-area'
  return (
    <PageView>
      <Meta
        loading={loading}
        title={`ทำเลมาแรง - ${getMarketAreaName()}`}
        description="ทำเลมาแรง"
      />
      <MarketList
        loading={loading}
        title="ทำเลมาแรง"
        market_list={market_list}
        placeholder="ทุกพื้นที่"
        source={source}
        selected={market_area_id}
        select_list={select_list}
        onChange={onChange}
      />
      <Footer />
    </PageView>
  )
}

const PageView = styled.div``

export default inject('market', 'market_area')(observer(MarketAreaList))
