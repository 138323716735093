import styled from 'styled-components'

import { Checker } from 'components/input'

const ShopType = (props) => {
  const { doc = {}, validate = {}, onChange = () => {} } = props

  const onCh = (val, name) => {
    doc[name] = val ? 'yes' : 'no'
    onChange(doc, validate)
  }

  const content = list.map((it, i) => {
    const { name, value } = it
    return (
      <Line key={i}>
        <Checker
          label={name}
          name={value}
          value={doc[value] === 'yes'}
          onChange={onCh}
        />
      </Line>
    )
  })

  return (
    <PageView>
      <Title>อุปกรณ์หรือสิ่งของภายในร้าน</Title>
      <Des>(หากไม่มีในรายการด้านล่าง สามารถข้ามได้)</Des>
      {content}
    </PageView>
  )
}

const list = [
  { name: 'เตาไฟฟ้า', value: 'electric_stove_type' },
  { name: 'เตาแก๊ส', value: 'gas_stove_type' },
  { name: 'อาหารหนัก (ข้าว, เส้น)', value: 'food_type' },
  { name: 'น้ำชงทุกชนิด', value: 'make_drink_type' },
  { name: 'น้ำเปล่า น้ำอัดลม', value: 'drink_type' },
  { name: 'มูลนิธิ', value: 'foundation_type' },
  { name: 'บูธบัตรเครดิต / เน็ตบ้าน', value: 'direct_sale_type' },
]

const PageView = styled.div`
  width: 100%;
`

const Title = styled.div`
  width: 100%;
  font-size: 18px;
  color: ${(p) => p.theme.color.blue};
`

const Des = styled.div`
  width: 100%;
  font-size: 14px;
  padding-bottom: 16px;
`

const Line = styled.div`
  width: 100%;
  padding-bottom: 16px;
`

export default ShopType
