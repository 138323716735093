import React from 'react'
import styled from 'styled-components'

import Name from './Name'
import Image from './Image'
import Detail from './Detail'
import Price from './Price'

const Market = (props) => {
  const { item = {}, source } = props

  const q = source ? `?source=${source}` : ''
  const link = `/market/${item.market_id}${q}`

  return (
    <Card>
      <Image item={item} link={link} />
      <Info>
        <Name item={item} link={link} />
        <Detail item={item} />
        <Price item={item} display_type={false} />
      </Info>
    </Card>
  )
}

const Card = styled.div`
  width: 229px;
  background-color: white;
  border-radius: 24px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  text-align: center;
  padding: 16px;
`

const Info = styled.div`
  text-align: left;
  margin: 16px 0px 8px 0px;
  color: ${(p) => p.theme.color_level.grey.high};
`

export default Market
