import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import UnitInfo from './UnitInfo'

const ProductList = (props) => {
  const { shop, filter } = props

  const { order } = props.order.toJS()
  const { display } = props.product.toJS()
  const promotion_list = props.promotion.toJS().promotion.list || []
  const { booking_type, unit } = display || {}

  const content = unit ? (
    <UnitInfo
      bookingType={booking_type}
      doc={unit}
      shop={shop}
      order={order}
      filter={filter}
      promotion_list={promotion_list}
    />
  ) : undefined

  return <PageView>{content}</PageView>
}

const PageView = styled.div`
  width: 100%;
`

export default inject('product', 'promotion', 'order')(observer(ProductList))
