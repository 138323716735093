import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import Footer from 'footer'
import { Meta, BackHeader, HtmlContent, Content } from 'components/display'
import Loading from 'components/loading/dialog'

const Cookie = (props) => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  const onLoad = useCallback(async () => {
    try {
      setLoading(true)
      await props.consent.getCookie()
    } catch (e) {
      console.log('e', e.message)
    }
    setLoading(false)
  }, [props.consent])

  useEffect(() => {
    onLoad()
  }, [onLoad])

  const onBack = () => {
    const { source } = queryString.parse(location.search)
    const path = source ? source : '/'
    navigate(path)
  }

  const cookie = props.consent.toJS().cookie

  return (
    <PageView>
      <Meta
        loading={loading}
        title={cookie.title}
        description="นโยบายการใช้คุกกี้ Cookie"
      />
      <BackHeader title={cookie.title} onClick={onBack} />
      <Content isHeader={true}>
        <Loading loading={loading} />
        <Body>
          <HtmlContent content={cookie.content} />
        </Body>
      </Content>
      <Footer />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  min-height: 90vh;
  background-color: white;
`

const Body = styled.div`
  min-height: 60vh;
  padding: 0px 24px;
`

export default inject('consent')(observer(Cookie))
