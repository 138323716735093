import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

import { Link } from 'components/link'
import { Marget } from 'icon'

const HeaderMenu = (props) => {
  return (
    <TopBar>
      <InnerTopBar>
        <Row>
          <Col {...col.logo}>
            <Link to="/">
              <LogoSection>
                <Marget />
              </LogoSection>
            </Link>
          </Col>
          <Col {...col.menu} />
        </Row>
      </InnerTopBar>
    </TopBar>
  )
}

const LogoSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  svg {
    width: 124px;
    height: 65px;
  }

  ${(p) => p.theme.media.tablet} {
    justify-content: center;
  }
`

const InnerTopBar = styled.div`
  display: block;
  height: 65px;
  width: 100%;
  max-width: 1440px;
`

const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  width: 100%;

  padding: 0 60px;
  background-color: ${(p) => p.theme.color.backgroundColor};
`

const col = {
  logo: {
    xl: { span: 6, order: 1 },
    lg: { span: 6, order: 1 },
    md: { span: 24, order: 1 },
    sm: { span: 24, order: 1 },
    xs: { span: 24, order: 1 },
  },
  menu: {
    xl: { span: 16, order: 2 },
    lg: { span: 16, order: 2 },
    md: { span: 0, order: 2 },
    sm: { span: 0, order: 2 },
    xs: { span: 0, order: 2 },
  },
}

export default inject('member')(observer(HeaderMenu))
