import styled from 'styled-components'

import { Remove } from 'icon'
import { format, product } from 'utils'

const Product = (props) => {
  const { item = {}, index, onRemove } = props
  const {
    name,
    booking_type,
    detail,
    total_price,
    promotion_price,
    start_at,
    finish_at,
    round,
    deposit_type,
    deposit,
    promotion,
  } = item

  const onClick = () => onRemove(index)
  const getDate = () => {
    const start = format.toThaiDate(start_at)
    const finish = format.toThaiDate(finish_at)
    const date = product.getDate(start_at, finish_at)
    if (start === finish) {
      return (
        <Text>
          {start}
          <span>{round}</span>
        </Text>
      )
    } else {
      return (
        <>
          <Text>{date}</Text>
          <Text>{round}</Text>
        </>
      )
    }
  }

  const getLabel = () => {
    const p = format.toDigi(total_price)
    const bookingType = format.toBookingType(booking_type)
    if (promotion_price === 0) {
      return (
        <Label>
          {bookingType} ({p} บาท)
        </Label>
      )
    }

    const pro = format.toDigi(promotion_price)
    return (
      <Label>
        {bookingType} ({pro} บาท)
        <PromotionPrice>{p} บาท</PromotionPrice>
      </Label>
    )
  }

  const getPromotion = () => {
    if (!promotion) {
      return
    }
    const text = product.getTextPromotion(promotion)
    return <Text>โปรโมชั่น {text}</Text>
  }

  const depositContent =
    deposit_type === 'yes' ? (
      <Text>มัดจำ/ประกัน {format.toDigi(deposit)} บาท</Text>
    ) : undefined

  return (
    <PageView>
      <Name>{name}</Name>
      <Info>
        {getLabel()}

        {getDate()}
        {depositContent}
        {getPromotion()}
        <Text>โซน {detail}</Text>
      </Info>
      <Button onClick={onClick}>
        <Remove size={1.2} color={'#494949'} />
      </Button>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 10px 10px 10px 0px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
`

const Name = styled.div`
  width: 90px;
  height: 64px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  background-color: ${(p) => p.theme.color.green};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Info = styled.div`
  width: calc(100% - 140px);
`

const Label = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  color: ${(p) => p.theme.color_level.grey.high};
`

const Text = styled.div`
  width: 100%;
  font-size: 14px;
  color: ${(p) => p.theme.color_level.grey.high};
  span {
    margin-left: 4px;
  }
`

const Button = styled.div`
  width: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const PromotionPrice = styled.span`
  font-weight: 400;
  text-decoration: line-through;
  margin: 0px 8px;
`

export default Product
