/* eslint-disable */

const Logo = (props) => {
  const { size = 1 } = props

  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6012 7L9.78763 13.321L6.90614 10.2459L5 12.0321L9.65521 17L18.3726 8.92022L16.6012 7Z"
        fill="white"
      />
    </svg>
  )
}
/* eslint-enable */
export default Logo
