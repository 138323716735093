import styled from 'styled-components'
import { Row, Col } from 'antd'

import { Card } from 'components/display'
import { format } from 'utils'

const Header = (props) => {
  const { order = {}, total } = props
  const { status } = order

  const value = status === 'offer' ? total : order.total
  return (
    <PageView>
      <Card>
        <Row align="middle">
          <Col span={12}>
            <Title>ยอดชำระทั้งหมด</Title>
          </Col>

          <Col span={12}>
            <Price>
              {format.toDigi(value)}
              <span>บาท</span>
            </Price>
          </Col>
        </Row>
      </Card>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  margin-bottom: 8px;
`

const Title = styled.div`
  width: 100%;
  font-size: 18px;
  color: ${(p) => p.theme.color_level.grey.high};
`

const Price = styled.div`
  width: 100%;
  font-size: 24px;
  font-weight: 400;
  text-align: right;
  color: ${(p) => p.theme.color.red};

  span {
    margin-left: 2px;
    font-size: 14px;
  }
`

export default Header
