export const bank_list = [
  { name: 'กรุงศรีอยุธยา', value: '25', limit: 10, pattern: 'xxx-x-xxxxx-x' },
  { name: 'กรุงเทพ', value: '2', limit: 10, pattern: 'xxx-x-xxxxx-x' },
  { name: 'กสิกรไทย', value: '4', limit: 10, pattern: 'xxx-x-xxxxx-x' },
  { name: 'ทีทีบี', value: '11', limit: 10, pattern: 'xxx-x-xxxxx-x' },
  { name: 'ไทยพาณิชย์', value: '14', limit: 10, pattern: 'xxx-x-xxxxx-x' },

  { name: 'กรุงไทย', value: '6', limit: 10, pattern: 'xxx-x-xxxxx-x' },
  { name: 'ซิตี้แบงก์', value: '17', limit: 10, pattern: 'xxx-x-xxxxx-x' },
  {
    name: 'สแตนดาร์ดชาร์เตอร์ด (ไทย)',
    value: '20',
    limit: 10,
    pattern: 'xxx-x-xxxxx-x',
  },
  { name: 'ซีไอเอ็มบีไทย', value: '22', limit: 10, pattern: 'xxx-x-xxxxx-x' },
  { name: 'ยูโอบี', value: '24', limit: 10, pattern: 'xxx-x-xxxxx-x' },

  { name: 'ออมสิน', value: '30', limit: 15, pattern: 'xxx-x-xxxxx-x-xxxxx' },
  {
    name: 'อาคารสงเคราะห์',
    value: '33',
    limit: 12,
    pattern: 'xxx-x-xxxxx-xxx',
  },
  { name: 'ธ.ก.ส.', value: '34', limit: 12, pattern: 'xxx-x-xxxxx-xxx' },
  { name: 'ทิสโก้', value: '67', limit: 14, pattern: 'xxx-x-xxxxx-x' },
  {
    name: 'เกียรตินาคินภัทร',
    value: '69',
    limit: 14,
    pattern: 'xxx-x-xxxxx-x',
  },
]

/*
{ name: 'แลนด์แอนด์เฮ้าส์', value: '73', limit: 10, pattern: 'xxx-x-xxxxx-x' },
{ name: 'ธนาคารแห่งประเทศจีน (ไทย)', value: '52', limit: 10, pattern: 'xxx-x-xxxxx-x' },
{ name: 'อิสลามแห่งประเทศไทย', value: '66', limit: 10, pattern: 'xxx-x-xxxxx-x' },
{ name: 'ไอซีบีซี (ไทย)', value: '70', limit: 10, pattern: 'xxx-x-xxxxx-x' },
{ name: 'ไทยเครดิตเพื่อรายย่อย', value: '71', limit: 10, pattern: 'xxx-x-xxxxx-x' },

{ name: 'เอเอ็นแซด (ไทย)', value: '79', limit: 10, pattern: 'xxx-x-xxxxx-x' },
{ name: 'ซูมิโตโม มิตซุย', value: '80', limit: 10, pattern: 'xxx-x-xxxxx-x' },
*/
