import styled from 'styled-components'

const ImgPicker = (props) => {
  const {
    name = 'get file',
    readonly = false,
    disabled = false,
    text = 'Import',
    onSelect = () => {},
    extAccept = '.jpg, .jpeg, .png',
  } = props

  const onClick = () => {
    const input = document.getElementById(name)
    if (input) input.value = null
  }

  const onChange = async (evt) => {
    const files = evt.target.files
    if (files.length === 0) return

    const file = files[0]

    const reader = new FileReader()
    reader.addEventListener('load', () => {
      onSelect(reader.result, name, file)
    })
    reader.readAsDataURL(file)
  }

  const disable = readonly || disabled
  const css = disable ? 'disable' : ''
  return (
    <Section className={css}>
      <input
        id={name}
        type="file"
        accept={extAccept}
        style={{ display: 'none' }}
        onChange={onChange}
        onClick={onClick}
      />

      <label readOnly={disable} htmlFor={disable ? '' : name}>
        <ActionBtn>{text}</ActionBtn>
      </label>
    </Section>
  )
}

const Section = styled.div`
  font-size: 14px;

  width: 100%;
  height: 48px;

  color: white;
  background-color: ${(p) => p.theme.color.blue};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
  border-radius: 8px;

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
  }

  &.disable {
    background-color: ${(p) => p.theme.color.disable};
  }
`

const ActionBtn = styled.div`
  cursor: pointer;
`

export default ImgPicker
