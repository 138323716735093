/* eslint-disable */

const Logo = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_499_4399)">
        <path
          d="M23.7493 8.82246L17.7492 2.82235C17.4144 2.48765 16.8717 2.48775 16.537 2.82255C16.3763 2.98326 16.2861 3.20113 16.286 3.4284V5.99987H10.2859C9.81249 5.99987 9.42873 6.38363 9.42873 6.85704C9.42873 7.33045 9.81249 7.71421 10.2859 7.71421H17.1432C17.6166 7.71421 18.0004 7.33045 18.0004 6.85704V5.49753L21.9313 9.42846L18.0004 13.3594V11.9999C18.0004 11.5265 17.6166 11.1428 17.1432 11.1428H7.71443V8.57129C7.71433 8.09788 7.33047 7.71421 6.85711 7.71432C6.6299 7.71437 6.41198 7.80462 6.25126 7.96528L0.251196 13.9654C-0.083447 14.3001 -0.083447 14.8427 0.251196 15.1774L6.25131 21.1775C6.41203 21.3383 6.63 21.4286 6.85731 21.4287C6.96996 21.429 7.08161 21.4069 7.18558 21.3635C7.5058 21.2308 7.71453 20.9182 7.71443 20.5715V18H13.7145C14.188 18 14.5717 17.6163 14.5717 17.1429C14.5717 16.6695 14.188 16.2857 13.7145 16.2857H6.85731C6.3839 16.2857 6.00014 16.6695 6.00014 17.1429V18.5023L2.0692 14.5714L6.00014 10.6405V11.9999C6.00014 12.4733 6.3839 12.8571 6.85731 12.8571H16.286V15.4286C16.2861 15.902 16.67 16.2856 17.1433 16.2855C17.3706 16.2855 17.5885 16.1952 17.7492 16.0346L23.7493 10.0345C24.0839 9.69972 24.0839 9.15715 23.7493 8.82246Z"
          fill="#305FED"
        />
      </g>
      <defs>
        <clipPath id="clip0_499_4399">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
// eslint-enable
export default Logo
