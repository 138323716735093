import styled from 'styled-components'

const Line = (props) => {
  const { style, children } = props
  return <PageView style={style}>{children}</PageView>
}

const PageView = styled.div`
  padding-bottom: 16px;
`

export default Line
