export const toPosition = (x1, y1) => {
  const x = x1 < 0 ? 0 : x1
  const y = y1 < 0 ? 0 : y1

  return {
    x: toNumber(x),
    y: toNumber(y),
  }
}

export const toLocalPosition = (x1, y1) => {
  const x = x1 < 0 ? 0 : x1
  const y = y1 < 0 ? 0 : y1

  return {
    x: toNumber(x),
    y: toNumber(y),
  }
}

export const toNumber = (val) => {
  return Math.floor(val * 1000) / 1000
}
