import styled from 'styled-components'

import { format } from 'utils'

import Label from './Label'

const Personal = (props) => {
  const { account = {} } = props

  return (
    <PageView>
      <Label text="ข้อมูลผู้เช่า" />
      <Card>
        <Text>
          ผู้เช่า:<span>{account.name}</span>
        </Text>
        <Text>
          เบอร์ติดต่อ:
          <span>{format.toMobile(account.mobile)}</span>
        </Text>
      </Card>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  margin-top: 16px;
`

const Card = styled.div`
  width: 100%;
  color: ${(p) => p.theme.color_level.grey.high};
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 20px;
  margin-top: 16px;
`

const Text = styled.div`
  font-size: 14px;
  font-weight: bold;

  span {
    font-weight: normal;
    margin-left: 4px;
    float: right;
  }
`

export default Personal
